import { FC } from "react";
import Styles from "./RDNotification.module.css";
import React from "react";
import Boschicon from "../boschIcon/Boschicon";

interface RDNotificationProps {
  message: string;
  onClose: Function;
  type: string;
  isOpen: boolean
}

const RDNotification: FC<RDNotificationProps> = ({ message, onClose, type, isOpen }) => {
  return (
    <>
      {
        isOpen && (<div className={`${Styles.notification_bar} ${type === "warning" && Styles.warning} ${type === "success" && Styles.success} `}  >
          <div className={Styles.icon_noti}>
            <Boschicon name={"bosch-ic-notification-active"} />
          </div>

          <div className={Styles.notification_message}>
            <p>{message}</p>
          </div>
          <div className={Styles.icon_close}>
            <Boschicon name={"bosch-close"} onClick={() => onClose()} />
          </div>

        </div>)
      };
    </>
  )


};

export default RDNotification;
