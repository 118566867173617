import httpPCS from '../services/HttpServicePCS'

export const BASE_URL = 'https://localhost:9000/'

export type MeetingFeatures = {
  Audio: { [key: string]: string }
}

export type CreateMeetingResponse = {
  MeetingFeatures: MeetingFeatures
  MediaRegion: string
}

export type JoinMeetingInfo = {
  Meeting: CreateMeetingResponse
  Attendee: string
}

interface MeetingResponse {
  JoinInfo: JoinMeetingInfo
}

interface GetAttendeeResponse {
  name: string
}

// export async function fetchMeeting(
//     meetingId: string,
//     name: string,
//     region: string,
//     echoReductionCapability = false
// ) {
//     // const params = {
//     //     title: encodeURIComponent(meetingId),
//     //     name: encodeURIComponent(name),
//     //     region: encodeURIComponent(region),
//     //     ns_es: String(echoReductionCapability),
//     // };

//     // const res = await fetch(BASE_URL + 'join?' + new URLSearchParams(params), {
//     //     method: 'POST',
//     // });

//     const data = {
//         "Title": "teetststst",
//         "Meeting": {
//             "MeetingId": "c17875f5-503a-4711-899a-255f03a37979",
//             "MeetingHostId": null,
//             "ExternalMeetingId": "teetststst",
//             "MediaRegion": "us-east-1",
//             "MediaPlacement": {
//                 "AudioHostUrl": "fdc9302e6dd3e72d01366e640a3384e0.k.m3.ue1.app.chime.aws:3478",
//                 "AudioFallbackUrl": "wss://haxrp.m3.ue1.app.chime.aws:443/calls/c17875f5-503a-4711-899a-255f03a37979",
//                 "SignalingUrl": "wss://signal.m3.ue1.app.chime.aws/control/c17875f5-503a-4711-899a-255f03a37979",
//                 "TurnControlUrl": "https://7979.cell.eu-central-1.meetings.chime.aws/v2/turn_sessions",
//                 "ScreenDataUrl": "wss://bitpw.m3.ue1.app.chime.aws:443/v2/screen/c17875f5-503a-4711-899a-255f03a37979",
//                 "ScreenViewingUrl": "wss://bitpw.m3.ue1.app.chime.aws:443/ws/connect?passcode=null&viewer_uuid=null&X-BitHub-Call-Id=c17875f5-503a-4711-899a-255f03a37979",
//                 "ScreenSharingUrl": "wss://bitpw.m3.ue1.app.chime.aws:443/v2/screen/c17875f5-503a-4711-899a-255f03a37979",
//                 "EventIngestionUrl": "https://data.svc.ue1.ingest.chime.aws/v1/client-events"
//             },
//             "MeetingFeatures": {
//                 "Audio": {
//                     "EchoReduction": "AVAILABLE"
//                 }
//             },
//             "PrimaryMeetingId": null
//         },
//         "Attendee": {
//             "ExternalUserId": "b73b6948-be29-47ce-91f1-1499c68cc61b",
//             "AttendeeId": "707f7937-dc9d-88d3-fddc-1698d2eb547d",
//             "JoinToken": "NzA3Zjc5MzctZGM5ZC04OGQzLWZkZGMtMTY5OGQyZWI1NDdkOmJjNWNkOTQ3LTBlYjYtNDY5ZC1hZWNjLWZiOWJmMTc3YzYyMg"
//         }
//     }//await res.json();

//     // if (data.error) {
//     //     throw new Error(`Server error: ${data.error}`);
//     // }

//     return data;
// }

export async function getAttendee(
  ciamId: string,
): Promise<GetAttendeeResponse> {
  const headers = {
    ApiKey: process.env.REACT_APP_PCS_KEY || '',
  }
  const res = await httpPCS.get(
    `/api/Chime/GetNameChimeUser?ciamId=${ciamId}`,
    { headers },
  )

  return {
    name: res.data.name,
  }
}

export const createGetAttendeeCallback = (meetingId: string) => (
  ciamId: string,
): Promise<GetAttendeeResponse> => getAttendee(ciamId)

export const liveTranslateMessage = async (
  textContent: string,
  sourceLanguageCode: string,
  targetLanguageCode: string,
) => {
  if (
    sourceLanguageCode === '' ||
    targetLanguageCode === '' ||
    sourceLanguageCode === targetLanguageCode
  ) {
    return textContent
  }
  const data = {
    text: textContent,
    sourceLanguageCode: sourceLanguageCode,
    targetLanguageCode: targetLanguageCode,
  }
  const headers = {
    ApiKey: process.env.REACT_APP_PCS_KEY || '',
  }
  const res = await httpPCS.post(`/api/Chime/TranslateMessage`, data, {
    headers,
  })
  return res.data.translatedText
}
