import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistReducer,
} from 'redux-persist';
import authReducer from './auth';
import sessionStorage from 'redux-persist/lib/storage/session'
import accountReducer from '../reducers/AccountReducer';
import { useDispatch } from 'react-redux';
import accountRediReducer from './account';
import channelReducer from '../redux/chimeSlide';
import ticketReducer from '../redux/ticket';
import meetingReducer from '../redux/meeting';
import channelListReducer from '../redux/channel';
import visualConnectListReducer from '../redux/visualConnect';
import assistantReducer from '../redux/assistantBot';

const rootReducer = combineReducers({
    auth: authReducer,
    account: accountReducer,
    accountRedi: accountRediReducer,
    channel: channelReducer,
    ticket: ticketReducer,
    meeting: meetingReducer,
    channelList: channelListReducer,
    visualConnectList: visualConnectListReducer,
    assistant: assistantReducer
});

const persistConfig = {
    key: 'rootPrivateChannel',
    version: 1,
    storage: sessionStorage,
    blacklist: ['channel']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootState = ReturnType<typeof rootReducer>;
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export default store;