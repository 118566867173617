import React, { useState } from "react";
import "./PrivateChatAnonymous.css";
import { WebChatComponentContextProvider } from "../../../context/WebChatComponentContext";
import { Chat } from "./ChatComponent/Chat";
import { useTranslation } from "react-i18next";

const PrivateChatAnonymous = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = window.location.search !== "" ? urlParams.get("sessionId") : "";
  const { t } = useTranslation();
  const [isActiveChannel, setIsActiveChannel] = useState(false);

  const onActiveChannel = () => {
    setIsActiveChannel(true)
  }

  return (
    <div className="private-chat-responsive-width">
      <div
        className="private_anonymous_chat"
      >
        {/* <div className={isActiveChannel ? "session-title--active" : "session-title"}>
          <p>{t("Session_title")}: {sessionId}</p>
        </div> */}
        <WebChatComponentContextProvider>
          <Chat sessionId={sessionId || ""} hanldeOnActiveChannel={onActiveChannel}/>
        </WebChatComponentContextProvider>
      </div>
    </div>
  );
};

export default PrivateChatAnonymous;

