export const TRANSLATIONS_SW = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Ny Kanal",
	Start_New_Question: "Hur vill du starta en ny kanal?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Kod",
	Channel_List: "Kanallista",
	Chat_Label: "Chat",
	Call_Label: "Samtal",
	Video_Label: "Video",
	Share_Label: "Dela",
	Stop_Channel_Label: "Stoppa Kanal",
	Contact_Details_Label: "Kontaktdetaljer",
	Request_Details_Label: "Förfrågningsdetaljer",
	Attendees_Label: "Deltagare",
	Customer_Label: "Kunder",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Skriv ditt meddelande",
	Stop_Channel_Question: "Vill du stänga kanalen?",
	Yes_Label: "Ja",
	Cancel_Label: "Avbryt",
	Start_New_Video_Session: "Starta nytt videosamtal",
	Without_SMS_Label: "Utan SMS",
	Send_SMS_Label: "Skicka SMS",
	Send_Invite_Label: "Skicka inbjudan",
	Log_Out_Question: "Vill du logga ut?",
	Welcome_Label: "Välkommen tilll Private Channel Solution",
	Login_Label: "Logga in",
	Login_Is_Required_Label: "För att starta en kanal krävs att du loggar in",
	Account_Permission_Label: "Detta konto har inte rättigheter att starta ny kanal",
	Start_Label: "Start",
	Noted_Recommend: "På grund av Boschs riktlinjer för användning av Firefox rekommenderar vi att du använder en annan webbläsare för att få den bästa användarupplevelsen av applikationen Private Channel Solution. Rekommenderade webbläsare: Microsoft Edge och Google Chrome",
	Available_Languages_Label: "Tillgängliga språk",
	Can_Not_Create_Meeting_Title: "Det gick inte att skapa en ny Private Channel",
	Can_Not_Create_Meeting_Content: "Du är fortfarande i en aktiv Private Channel. Avsluta den aktuella kanalen innan du startar en ny.",
	Leave_meeting_Warning: "Stäng Private Channel kanalen eller så kan du avsluta kanalen för alla. Kanalen kan inte användas när den är avslutad.",
	Leave_Lable: "Lämna",
	End_Lable: "Avsluta",
	Meeting_Ended: "Mötet är avslutat",
	Start_Channel_Session: "Starta ny kanal session",
	Start_Channel_Session_Content: "Teknikern arbetar för närvarande med Ticket ID:",
	Join_Lable: "Delta",
	Join_Meeting_Label: "Delta i kanalen",
	Meeting_Information_Label: "Kanal information",
	Meeting_ID_Label: "Kanal ID",
	Translate_Option_Title: "Översättningsalternativ",
	Translate_Mode_Label: "Översättning",
	From_Label: "Från:",
	To_Label: "Till:",
	Chat_Room_Label: "Privat Kanal (Private Channel)",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Hur vill du dela Private Channel inbjudan",
	Invite_To_Visual_Question: "Hur vill du dela Visual Connect inbjudan",
	Invite_Sms_Question: "Skicka inbjudan per SMS",
	Invite_To_Chat_By_Ticket_ID: "Skicka inbjudan med Ticket ID",
	Invite_Email_Question: "Skicka inbjudan per Email",
	Link_label: "Länk",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Applikationer",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Gå in på webbplatsen",
	WSA_Slogan_label: "Uppfunnet för livet",
	WSA_contact_info_label: "Allmän kontaktinformation",
	WSA_PSIRT_label: "Produktsäkerhet (PSIRT)",
	WSA_licences_label: "Innovationer, patent och licenser",
	WSA_purchasing_label: "Inköp och logistik",
	WSA_copyright_label: "© Robert Bosch GmbH, all rights reserved",
	WSA_copyright_information_label: "Företagsinformation",
	WSA_copyright_notice_label: "Rättsligt meddelande",
	WSA_copyright_protectionnotice_label: "Dataskyddsmeddelande",
	WSA_copyright_terms_label: "Allmänna Villkor",
	WSA_copyright_settings_label: "Sekretessinställningar",
	Infor_CorporateInformation_Description: "Ansvarig för Robert Bosch GmbH:s internetsidor",
	Infor_DataProtectionNotice_Description: "Dataskyddsmeddelande för Bosch Remote Diagnostics Service",
	Infor_LegalNotice_Description: "För användare av Robert Bosch GmbH:s internetsidor",
	Id_panel: "Id",
	Topic_panel: "Ämne",
	Make_panel: "Märke",
	Model_pane: "Modell",
	Engine_code_panel: "Motorkod",
	Year_panel: "Årsmodell",
	Vin_panel: "VIN/Chassi nummer",
	Plate_panel: "Registrerings nummer",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Din tekniker är inte tillgänglig för AR",
	Write_something: "Skriv för att börja chatta",
	Session_title: "Session",
	Live_Translation: "Live översättning",
	Enabled_panel: "Aktiverad",
	Joined_note: "Du har gått med i sessionen...",
	Today_panel: "Idag",
	Read_panel: "Läst",
	Sent_panel: "Skickad",
	Dis_translation: "Deaktivera översättning",
	En_translation: "Översätt",
	Terminated_Channel_note: "Bosch Expert har stängt denna kanal",
	Not_start_note: "har inte startat än",
	Retry_note: "Klicka på \"Försök igen\" för att försöka igen eller stäng det här fönstret för att avsluta.",
	Retry_panel: "Försök igen",
	Thanks_note: "Tack för att du använder Bosch Technical Services. Jag är mycket glad att vi fick möjligheten att hjälpa dig idag. Kontakta oss igen om du behöver hjälp. Ha en underbar dag.",
	Continue_panel: "Aktivera översättning",
	En_live_translate: "Fortsätt",
	Translate_from: "Översätt från",
	Translate_to: "Översätt till",
	Random_ID: "Slumpmässigt ID",
	Anonymous: "Anonym",
	Generated_ID: "Slumpmässigt ID har genererats!",
	Your_ID: "Ditt slumpmässiga ID är:"
};