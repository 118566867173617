import { Fragment, useEffect, useState } from "react";
import React from "react";
import "./PublicDashBoard.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PublicMessageBox from "./PublicMessageBox/PublicMessageBox";
import { createChannelMembership, createMemberArn, describeChannel, listChannelMemberships, listChannelMembershipsForAppInstanceUser, listChannelMessages, listChannels } from "../../../api/ChimeAPI";
import { CommonService } from "../../../services/Common";
import AWS from "aws-sdk";
import { RSADecrypt } from "../../../services/RSA_Encrypt";
import MessagingService from "../../../services/MessagingService";
import SessionNotStartedModal from "../../modals/publicChannelModal/SessionNotStartedModal";
import { useAppDispatch } from "../../../redux/store";
import { ChannelClearState, ChannelSetMessage, SetNewMemberJoinChannel, channelSetChannel, selectChannelMessage, selectIsTranslate } from "../../../redux/chimeSlide";
import DeletedChannelModal from "../../modals/publicChannelModal/DeletedChannelModal";
import { useSelector } from "react-redux";
import Boschicon from "../../../common-components/boschIcon/Boschicon";
import ModalTranlationOptionsPublic from "../../modals/publicChannelModal/ModalTranlationOptionsPublic";
import { v4 } from 'uuid';
import CustomLoader from "../../../common-components/RDComponents/CustomLoader";

interface MemberModel {
  username: string,
  userId: string
}

const PublicDashBoard = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveChannel, setIsActiveChannel] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = window.location.search !== "" ? urlParams.get("sessionId") : "";
  const name = window.location.search !== "" ? urlParams.get("name") : "";
  const [messageProp, setMessageProp] = useState<any>();
  const messagesRedux = useSelector(selectChannelMessage)
  const [isStopChannel, setIsStopChannel] = useState<boolean>(false);
  const [messagingService] = useState(MessagingService.getInstance());
  const [displayErrorModal, setDisplayErrorModal] = useState<boolean>(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>("");
  const [unReadChannel, setUnReadChannel] = useState<string>("");
  const [channelArnString, setChannelArnString] = useState<string>("");
  const [member, setMember] = useState<MemberModel | null>(null);
  const isTranslate = useSelector(selectIsTranslate);
  const dispatchReduxToolkit = useAppDispatch();
  let uuid = v4();

  const beforeUnloadHandler = (event: any) => {
    // Recommended
    event.preventDefault();
    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
  };


  useEffect(() => {
    if (channelArnString !== "") {
      window.addEventListener("beforeunload", beforeUnloadHandler);
    } else {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }
  }, [channelArnString]);

  const messagesProcessor = async (message: any) => {
    const messageType = message?.headers["x-amz-chime-event-type"];
    const record = JSON.parse(message?.payload);
    switch (messageType) {
      // Channel Messages
      case "CHANNEL_DETAILS":
        // addMemberPresence(record.ChannelMemberships)
        //setActiveChannelMembershipsWithPresence([...activeChannelMembershipsWithPresence, record.ChannelMemberships])
        // processUnreadChannelInit(record);
        break;
      case "CREATE_CHANNEL_MESSAGE":
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
      case "DENIED_CREATE_CHANNEL_MESSAGE":
      case "FAILED_CREATE_CHANNEL_MESSAGE":
      case "DENIED_UPDATE_CHANNEL_MESSAGE":
      case "FAILED_UPDATE_CHANNEL_MESSAGE":
      case "PENDING_CREATE_CHANNEL_MESSAGE":
      case "PENDING_UPDATE_CHANNEL_MESSAGE":
        processChannelMessage(record);
        break;
      case "CREATE_CHANNEL":
      case "UPDATE_CHANNEL":
        break;
      case "DELETE_CHANNEL":
        dispatchReduxToolkit(ChannelClearState());
        setErrorModalMessage(t('Terminated_Channel_note'));
        setDisplayDeleteModal(true);
        // fetchChannels();
        break;
      case "CREATE_CHANNEL_MEMBERSHIP":
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        break;
      case "UPDATE_CHANNEL_MEMBERSHIP":
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        break;
      case "DELETE_CHANNEL_MEMBERSHIP":
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        if (record?.Member?.Arn.includes(member?.username.toLowerCase())) {
          setErrorModalMessage(t('Terminated_Channel_note'));
          setDisplayDeleteModal(true);
          dispatchReduxToolkit(ChannelClearState());
        }
        break;
      default:
        console.log(`Unexpected message type! ${messageType}`);
    }
  };

  useEffect(() => {
    const checkUser = async () => {
      setIsLoading(true);
      let memberTemp = {
        username: "anonymous",
        userId: "adfs.anonymous",
      };
      let newMemberChime = {
        ciamId: memberTemp.userId,
        name: memberTemp.username,
        mobile: "",
        email: ""
      }
      if (name && name !== "") {
        let nameTrim = name.replace(/\s/g, '');
        memberTemp.username = nameTrim;
        memberTemp.userId = `adfs.anonymous_${nameTrim.toLowerCase()}_` + `${uuid}`;
        newMemberChime.ciamId = memberTemp.userId;
        newMemberChime.name = memberTemp.username;
      } else {
        memberTemp.userId = `adfs.anonymous_` + `${uuid}`;
        newMemberChime.ciamId = memberTemp.userId;
        newMemberChime.name = memberTemp.username;
      }
      await CommonService.getChimeUserForPublic(newMemberChime)
      await setMember(memberTemp);
    }
    if (!member) {
      checkUser()
    }
  }, []);


  useEffect(() => {
    if (member) {
      AWS.config.region = process.env.REACT_APP_REACT_REGION;
      const setAwsKeysAndConnect = async () => {
        const awsKeys = await CommonService.getChimeSecretPublic();
        if (awsKeys) {
          const awsKeysDecrypt = await RSADecrypt.RSADecrypt(awsKeys);
          if (awsKeysDecrypt) {
            let awsKeysDecryptData = JSON.parse(awsKeysDecrypt!)
            AWS.config.credentials = {
              accessKeyId: awsKeysDecryptData.AccessKey,
              secretAccessKey: awsKeysDecryptData.SecretKey,
            };
            console.log("Get AWS keys Success");
          }
          if (sessionId && sessionId !== "") {
            excuteStartChannel(sessionId);
          }
        } else {
          console.log("Cannot get AWS keys");
        }

        messagingService.connect(member);
      }

      setAwsKeysAndConnect();

      return () => {
        messagingService.close();
      };
    }
  }, [member]);

  useEffect(() => {
    if (member) {
      messagingService.subscribeToMessageUpdate(messagesProcessor);
    }

    return () => {
      messagingService.unsubscribeFromMessageUpdate(messagesProcessor);
    };
  }, [messagingService, member]);

  const excuteStartChannel = async (ticketIdParam: string) => {
    setIsLoading(true);
    // Create a messaging session
    const channelName = ticketIdParam; // Replace with the channel name
    const anonymousUserId = member?.userId; // Replace with the anonymous user ID
    const response = await listChannels(process.env.REACT_APP_AWS_APP_INSTANCE_ARN, anonymousUserId);
    const exitChannel = response.find(
      (x: any) =>
        x.Name == channelName
    );
    if (exitChannel) {
      const channelArn = exitChannel.ChannelArn;
      const memberArn = JSON.parse(exitChannel.Metadata)?.memberArn;
      if (channelArn) {
        connectChannel(
          channelArn,
          member ? member.userId : "",
          memberArn
        );
        setIsLoading(false);
        setIsActiveChannel(true);
        setChannelArnString(channelArn)
      } else {
        setIsLoading(false);
        setErrorModalMessage("Cannot create new Channel");
        setDisplayErrorModal(true);
      }
    } else {
      setIsLoading(false);
      setErrorModalMessage(`${t('Session_title')} ${channelName} ${t('Not_start_note')} `);
      setDisplayErrorModal(true);
    }

  }


  const connectChannel = async (
    channelDescribe: string,
    userId: string,
    memberArn: string
  ) => {
    const promise = Promise.resolve(channelDescribe);
    const channelArnCurrent = await promise.then((m) => m);
    const channel = await describeChannel(channelArnCurrent, userId);
    const memberShip = await listChannelMemberships(
      channelArnCurrent,
      userId,
    )
    const exitMember = memberShip.find(
      (x: any) =>
        x.Member.Name == member?.username
    );
    if (channel) {
      if (!exitMember) {
        const statusAdd = await onAddMember(channel, memberArn, userId);
        if (statusAdd) {
          dispatchReduxToolkit(channelSetChannel(channel));
          if (channelArnCurrent && channelArnCurrent !== '') {
            const newMessages = await listChannelMessages(
              channelArnCurrent,
              userId
            )
            const message_translate = await Promise.all(
              newMessages.Messages.map(async (message: any) => {
                if (
                  createMemberArn(userId) !== message.Sender.Arn &&
                  isTranslate.status
                ) {
                  // const sourceLanguageCode = JSON.parse(message.Metadata)?.language || "";
                  const translateMessage = await CommonService.liveTranslateMessage(
                    message.Content,
                    isTranslate.langSource,
                    isTranslate.langTarget,
                  )
                  return { ...message, Content: translateMessage }
                }
                return message
              }),
            )
            await dispatchReduxToolkit(
              ChannelSetMessage({
                ChannelMessages: message_translate,
                NextToken: newMessages.NextToken,
              }),
            )
          }
          fetchChannels();
        }
      }
    } else {
      console.log("Error, could not retrieve channel information.");
    }
  };

  const onAddMember = async (channel: any, memberArn: string, userId: string) => {
    const promise = Promise.resolve(channel);
    const channelArn = await promise.then((m) => m.ChannelArn);

    try {
      // this function have swap position of memberArn and annonymous arn
      const membership = await createChannelMembership(
        channelArn,
        `${process.env.REACT_APP_AWS_APP_INSTANCE_ARN}/user/${userId}`,
        memberArn,
      );
      return membership;
    } catch {
      console.log("Error occurred. Member not added to channel.");
    }
  };


  const fetchChannels = async () => {
    const userChannelMemberships =
      await listChannelMembershipsForAppInstanceUser(member?.userId);
    const userChannelList = userChannelMemberships
      .map((channelMembership: any) => channelMembership.ChannelSummary)
      .sort((current: any, next: any) => {
        try {
          const newDate = new Date();
          const nextLast = next.LastMessageTimestamp
            ? next.LastMessageTimestamp.getTime()
            : newDate.getTime();
          const currentLast = current.LastMessageTimestamp
            ? current.LastMessageTimestamp.getTime()
            : newDate.getTime();

          return nextLast - currentLast;
        } catch {
          return null;
        }
      });
  };

  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);
    if (newMessage.Sender.Arn !== createMemberArn(member?.userId)) {
      setUnReadChannel(newMessage.ChannelArn);
      setUnReadChannel("");
    }
    setMessageProp(newMessage);
  };

  const onTrigger = () => {
    window.location.reload()
  }
  const onSetCloseTrigger = () => {
    window.close()
  }


  return (
    <>
      <div
        className="publicDashboard"
      >
        <div className={isTranslate.status ? "header-title onTranslate" : "header-title"}>
          {isTranslate.status && (<div className="isTranslate--note">
            <Boschicon name={'bosch-ic-translate'} />
            <p>
              {t('Live_Translation')} <a >{t('Enabled_panel')}</a>
            </p>
          </div>)}

          <p>{t("WSA_label")}​</p>
        </div>
        <div className={isActiveChannel ? "session-title--active" : "session-title"}>
          <p>{t("Session_title")}: {sessionId}</p>
        </div>
        {
          (isActiveChannel && messagesRedux.ChannelMessages.length == 0) && (
            <div className="joined--note">
              <div className="date--badge">{t('Today_panel')}</div>
              <div className="join--title">
                <p>{t('Joined_note')}</p>
              </div>
            </div>
          )
        }

        <div className="chatting-area">
          <PublicMessageBox
            message={messageProp}
            stopChannel={(e: string) => {
              setIsStopChannel(true);
            }}
            userId={member ? member.userId : ""}
            channelArn={channelArnString}
            uuid={uuid}
          />
        </div>

      </div>

      {isLoading && (
        <Fragment>
          <CustomLoader enable={isLoading}></CustomLoader>
        </Fragment>
      )}

      {
        displayErrorModal && (<SessionNotStartedModal showError={displayErrorModal}
          errorMessage={errorModalMessage}
          onSetErrorTrigger={onTrigger}
          onSetCloseTrigger={onSetCloseTrigger}
        />)
      }
      {
        displayDeleteModal && (<DeletedChannelModal showError={displayDeleteModal}
          errorMessage={errorModalMessage}
          onSetErrorTrigger={onTrigger} />)
      }
      {isTranslate.languagebox && (
        <ModalTranlationOptionsPublic
          translateModeData={isTranslate}
          isOpen={isTranslate.languagebox}
          onClose={() => { }} acceptButtonText={t('Continue_panel')} />
      )}

    </>
  );
};

export default PublicDashBoard;

