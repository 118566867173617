import React, { Fragment, useEffect, useState } from 'react';
import {
    Button,
    DeviceLabels,
    useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { MeetingMode } from '../../types';
import { MeetingManagerJoinOptions } from 'amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types';
import meetingConfig from '../../services/MeetingConfig';
import DeviceSetup from '../../components/views/DeviceSetup/DeviceSetup';
import MeetingView from '../../components/views/Meeting';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { leaveMeeting, selectMeetingState } from '../../redux/meeting';
import {
    visualConnectSetFirstStart
} from "../../redux/visualConnect";
import { useAppDispatch } from '../../redux/store';
import httpPCSPublic from '../../services/HttpServicePCSPublic';

interface MeetingFormProps {
    mtype: string | null,
    paramIsWebVer: boolean
}

const MeetingForm = ({
    mtype,
    paramIsWebVer
}: MeetingFormProps) => {
    const dispatchReduxToolkit = useAppDispatch();
    const meetingManager = useMeetingManager();
    const [deviceMode, setDeviceMode] = useState(false);
    const [meetingMode, setMeetingMode] = useState(false);
    const meetingData = useSelector(selectMeetingState);
    const { t } = useTranslation();
    const handleJoinMeeting = async () => {

        // meetingManager.getAttendee = createGetAttendeeCallback(account.chimeUserId);
        meetingManager.getAttendee = async (chimeAttendeeId: string, externalUserId?: string) => {
            const headers = {
                'ApiKey': process.env.REACT_APP_PCS_KEY || ""
            };
            const res = await httpPCSPublic.get(`/api/Chime/GetNameChimeUser?ciamId=${externalUserId}`, { headers });
            return {
                name: res.data.name,
            };
        }
        try {
            const JoinInfo = meetingData;
            const meetingSessionConfiguration = new MeetingSessionConfiguration(JoinInfo?.Meeting, JoinInfo?.Attendee);
            if (
                meetingConfig.postLogger &&
                meetingSessionConfiguration.meetingId &&
                meetingSessionConfiguration.credentials &&
                meetingSessionConfiguration.credentials.attendeeId
            ) {
                const existingMetadata = meetingConfig.postLogger.metadata;
                meetingConfig.postLogger.metadata = {
                    ...existingMetadata,
                    meetingId: meetingSessionConfiguration.meetingId,
                    attendeeId: meetingSessionConfiguration.credentials.attendeeId,
                };
            }

            meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = false;

            meetingSessionConfiguration.keepLastFrameWhenPaused = true;//keepLastFrameWhenPaused;
            const options: MeetingManagerJoinOptions = {
                deviceLabels: DeviceLabels.AudioAndVideo,//meetingMode === MeetingMode.Spectator ? DeviceLabels.None : DeviceLabels.AudioAndVideo,
                enableWebAudio: true//isWebAudioEnabled,
            };

            await meetingManager.join(meetingSessionConfiguration, options);
            setDeviceMode(true)
            // }
        } catch (error) {
            // updateErrorMessage((error as Error).message);
        }
    };

    useEffect(() => {
        handleJoinMeeting();
    }, []);

    useEffect(() => {
        const handleTabClose = () => {
            dispatchReduxToolkit(leaveMeeting());
            dispatchReduxToolkit(visualConnectSetFirstStart(false));
        };
        window.addEventListener('beforeunload', handleTabClose);
        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);
    return (
        <Fragment>
            {meetingMode ? <MeetingView mode={MeetingMode.Attendee} mtype={mtype} isWebVersion={paramIsWebVer} /> :
                deviceMode && <Fragment>
                    <DeviceSetup />
                    <Button onClick={async () => {
                        await meetingManager.start();
                        setMeetingMode(true)
                    }} label={t("Join_Meeting_Label")}></Button>
                </Fragment>
            }
        </Fragment>
    );
};

export default MeetingForm;
