import { Fragment, useEffect, useState } from "react";
import React from "react";
import Style from './styles/bot-widget.module.css';
import launcherIcon from './assets/robothead.svg';
import ChatWindow from "./ChatWindow";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../redux/store";
import {
    addMessageItem, funtionalTypeENUM, selectActiveState, selectAssistantMessageListState, selectStepAction, senderENUM, setActiveState, stepENUM,
    selectFeatureType,
    setSelectedFeatureState,
    setStepState,
    actionTypeENUM,
    selectOnStartAChatChannel,
    setOnStartAChatChannel,
    clearStateAfterEndChannel,
    clearAssistantState,
    setUserChime,
    selectUserChime,
    setPresenceOfUserInPublicChannel,
} from "../../../../redux/assistantBot";
import { useSelector } from "react-redux";
import MessagingService from "../../../../services/MessagingService";
import { ChannelClearState, ChannelSetMessage, SetIsTranslate, SetNewMemberJoinChannel, channelSetChannel, selectChannelArn, selectIsTranslate } from "../../../../redux/chimeSlide";
import { v4 } from "uuid";
import { CommonService } from "../../../../services/Common";
import AWS from "aws-sdk";
import { RSADecrypt } from "../../../../services/RSA_Encrypt";
import { MessageType, Persistence, createChannelMembership, createMemberArn, deleteChannelMembership, describeChannel, listChannelMemberships, listChannelMembershipsForAppInstanceUser, listChannelMessages, listChannels, sendChannelMessage } from "../../../../api/ChimeAPI";
import addNotification from "react-push-notification";
import { TimeOutOnlineStatus, countryOptions } from "../../../../helpers/AppConstants";
import ModalTranlationOptionsPublic from "../../../modals/publicChannelModal/ModalTranlationOptionsPublic";
import RDAlert from "../../../../common-components/RDComponents/RDAlert";
import CustomLoader from "../../../../common-components/RDComponents/CustomLoader";
import { selectMeetingState } from "../../../../redux/meeting";
import SignalRService from "../../../../services/SignalRService";

interface MemberModel {
    username: string,
    userId: string
}

interface memberSingal {
    memberARN: string,
    status: string,
    datetime: string,
    role: string
}

export interface MemberPresence {
    userArn: string,
    status: string,
    role: string
}
export interface languageDetectedCode {
    browserLang: string,
    messageLang: string
}

const BotWidget = () => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatchReduxToolkit = useAppDispatch();
    const activeState = useSelector(selectActiveState);
    const messageList = useSelector(selectAssistantMessageListState);
    const stepAction = useSelector(selectStepAction);
    const featureType = useSelector(selectFeatureType);
    const onStartAChatChannel = useSelector(selectOnStartAChatChannel);
    const [isLoading, setIsLoading] = useState(false);
    const [channelArnString, setChannelArnString] = useState<string>("");
    const [messagingService] = useState(MessagingService.getInstance());
    const [member, setMember] = useState<MemberModel | null>(null);
    const [errorModalMessage, setErrorModalMessage] = useState<string>("");
    const [displayErrorModal, setDisplayErrorModal] = useState<boolean>(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
    const [displayBEOfflineModal, setDisplayBEOfflineModal] = useState<boolean>(false);
    const [onDetectedLanguage, setOnDetectedLanguage] = useState<boolean>(false);
    const [onLoginAwsSuccess, setOnLoginAwsSuccess] = useState<boolean>(false);
    const [displaydetectedLanguageModal, setDisplaydetectedLanguageModal] = useState<boolean>(false);
    const [displaySelectLanguageModal, setDisplaySelectLanguageModal] = useState<boolean>(false);
    const [messageLanguageDetectedModal, setMessageLanguageDetectedModal] = useState<string>("");
    const [languageDetectedCode, setLanguageDetectedCode] = useState<languageDetectedCode | null>(null);
    const isTranslate = useSelector(selectIsTranslate);
    const userChime = useSelector(selectUserChime);
    const [messageProp, setMessageProp] = useState<any>();
    const [sendStartedMessage, setSendStartedMessage] = useState<boolean>(false);
    const [tabHasFocus, setTabHasFocus] = useState<boolean>(false);
    const channelArn = useSelector(selectChannelArn);
    const [memberPresenceList, setMemberPresenceList] = useState<MemberPresence[]>([]);
    const meetingData = useSelector(selectMeetingState);
    AWS.config.region = process.env.REACT_APP_REACT_REGION;
    let uuid = v4();
    const signalRConnectorInstance = SignalRService.getInstance();
    const [sinalRHubConnected, setSinalRHubConnected] = useState<boolean>(false);

    const handleFocus = () => {
        setTabHasFocus(true);
    };

    const handleBlur = () => {
        setTabHasFocus(false);
    };

    const handleClick = () => {
        setIsOpen(true)
    };

    const triggerBotAction = (code?: string, name?: string, nextStep?: number) => {
        let currentTime = getCurrentTime();
        // settimeout to delay response message of Bot
        setTimeout(() => {
            let messageSender = "";
            switch (nextStep) {
                case stepENUM.typeName:
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.assistant,
                        message: `${t("assistant_ask_name_message")}`,
                        datetime: currentTime,
                    }));
                    messageSender = t("assistant_ask_name_message");
                    break;
                case stepENUM.typeIdNumber:
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.assistant,
                        message: `${t("assistant_ask_code_message")}`,
                        datetime: currentTime,
                    }));
                    messageSender = t("assistant_ask_name_message");
                    break;
                case stepENUM.typeStart:
                    const text = t("assistant__confirm_message").replace("[code]", code || "").replace("[name]", name || "");
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.assistant,
                        message: text,
                        datetime: currentTime,
                    }));
                    messageSender = text;
                    break;
                case stepENUM.typeEdit:
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.assistant,
                        message: t("assistant_ask_change_message"),
                        datetime: currentTime,
                    }));
                    messageSender = t("assistant_ask_change_message");
                    break;
                case stepENUM.typeEditName:
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.assistant,
                        message: t("assistant_ask_change_name_message"),
                        datetime: currentTime,
                    }));
                    messageSender = t("assistant_ask_change_name_message");
                    break;
                case stepENUM.typeEditCode:
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.assistant,
                        message: t("assistant_ask_change_code_message"),
                        datetime: currentTime,
                    }));
                    messageSender = t("assistant_ask_change_code_message");
                    break;
                case stepENUM.goBackToFirstStep:
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.assistant,
                        message: t("assistant_ask_change_action_message"),
                        datetime: currentTime,
                    }));
                    messageSender = t("assistant_ask_change_action_message");
                    break;
                default:
                    break;
            }
            setMessageProp(messageSender);
        }, 1000)


    };

    const hanldeCloseState = async () => {
        setIsOpen(false)
        const chimeBearerArn = await createMemberArn(member?.userId)
        await deleteChannelMembership(channelArnString, chimeBearerArn, member?.userId)
        await dispatchReduxToolkit(SetIsTranslate({
            status: false,
            langSource: "auto",
            langTarget: "auto",
            languagebox: false
        }));
        dispatchReduxToolkit(setOnStartAChatChannel(false))
        dispatchReduxToolkit(ChannelClearState())
        dispatchReduxToolkit(clearAssistantState())
        setDisplaydetectedLanguageModal(false)
        setDisplaySelectLanguageModal(false)
        setOnDetectedLanguage(false)
        setSendStartedMessage(false)
        setMember(null)
    };

    const onMinimize = () => {
        setIsOpen(false)
    };

    const hanldeExpandWindow = () => {
        setIsOpen(false)
        window.open(
            `/assistant`,
            'null',
            'popup,width=1200,height=680,left=300,top=500, menubar=0, toolbar=0, scrollbar=1',
        )
    };

    const hanldeOpenIframe = () => {
        if (featureType?.type === funtionalTypeENUM.chatting) {
            if (tabHasFocus) {
                dispatchReduxToolkit(setOnStartAChatChannel(true))
            }
        }

        if (featureType?.type === funtionalTypeENUM.vsc) {
            window.open(
                `/assistant/publicMeeting/?sessionId=${featureType?.code}&name=${featureType?.name}`,
                'null',
                'popup,width=1200,height=680,left=300,top=500, menubar=0, toolbar=0, scrollbar=1',
            )
            hanldeCloseState()
        }
    };

    const hanldeOnSubmitInput = (data: { type: number, data: any }) => {
        let actionType = data.type;
        let actionData = data.data;
        let currentTime = getCurrentTime();
        let name = featureType?.name || "";
        let code = featureType?.code || "";
        let nextStep = 0;
        let messageSender = "";
        switch (actionType) {
            case actionTypeENUM.chooseOption:
                if (actionData === funtionalTypeENUM.change) {
                    nextStep = stepENUM.typeEdit
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.user,
                        message: t("Change_info_label"),
                        datetime: currentTime,
                    }));
                    messageSender = t("Change_info_label");
                    //use time out to delay response message of bot
                    setTimeout(() => {
                        dispatchReduxToolkit(setStepState(stepENUM.typeEdit));
                    }, 1000)
                }

                if (actionData === funtionalTypeENUM.changeName) {
                    nextStep = stepENUM.typeEditName
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.user,
                        message: t("Change_name_label"),
                        datetime: currentTime,
                    }));
                    messageSender = t("Change_name_label");
                    //use time out to delay response message of bot
                    setTimeout(() => {
                        dispatchReduxToolkit(setStepState(stepENUM.typeEditName));
                    }, 1000)
                }

                if (actionData === funtionalTypeENUM.changeCode) {
                    nextStep = stepENUM.typeEditCode
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.user,
                        message: t("Change_code_label"),
                        datetime: currentTime,
                    }));
                    messageSender = t("Change_code_label");
                    //use time out to delay response message of bot
                    setTimeout(() => {
                        dispatchReduxToolkit(setStepState(stepENUM.typeEditCode));
                    }, 1000)
                }

                if (actionData === funtionalTypeENUM.changeAction) {
                    nextStep = stepENUM.goBackToFirstStep
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.user,
                        message: t("Change_action_label"),
                        datetime: currentTime,
                    }));
                    messageSender = t("Change_action_label");
                    //use time out to delay response message of bot
                    setTimeout(() => {
                        dispatchReduxToolkit(setStepState(stepENUM.goBackToFirstStep));
                    }, 1000)
                }

                if (actionData === funtionalTypeENUM.vsc || actionData === funtionalTypeENUM.chatting) {
                    let dataMessage = actionData === funtionalTypeENUM.vsc ? t("Visual_Connect_Pro_btn") : t("Join_chat_btn")
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.user,
                        message: dataMessage,
                        datetime: currentTime,
                    }));
                    messageSender = dataMessage;
                    if (featureType && (featureType?.name !== "" && featureType?.code !== "")) {
                        nextStep = stepENUM.typeStart
                        //use time out to delay response message of bot
                        setTimeout(() => {
                            dispatchReduxToolkit(setStepState(nextStep));
                            dispatchReduxToolkit(setSelectedFeatureState({
                                type: actionData === funtionalTypeENUM.vsc ? funtionalTypeENUM.vsc : funtionalTypeENUM.chatting,
                                name: featureType?.name || "",
                                code: featureType?.code || ""
                            }));
                        }, 2000)
                    } else {
                        nextStep = stepENUM.typeName
                        //use time out to delay response message of bot
                        setTimeout(() => {
                            dispatchReduxToolkit(setStepState(nextStep));
                            dispatchReduxToolkit(setSelectedFeatureState({
                                type: actionData === funtionalTypeENUM.vsc ? funtionalTypeENUM.vsc : funtionalTypeENUM.chatting,
                                name: "",
                                code: ""
                            }));
                        }, 1000)
                    }
                }
                if (actionData === funtionalTypeENUM.start) {
                    hanldeOpenIframe()
                }
                break;
            case actionTypeENUM.inputText:
                dispatchReduxToolkit(addMessageItem({
                    sender: senderENUM.user,
                    message: actionData,
                    datetime: currentTime,
                }));
                messageSender = actionData;
                if (stepAction === stepENUM.typeName) {
                    name = actionData;
                    nextStep = stepENUM.typeIdNumber
                    setTimeout(() => {
                        dispatchReduxToolkit(setStepState(stepENUM.typeIdNumber));
                    }, 1000)
                    dispatchReduxToolkit(setSelectedFeatureState({
                        type: featureType?.type,
                        name: actionData,
                        code: ""
                    }));
                }
                if (stepAction === stepENUM.typeIdNumber) {
                    code = actionData
                    nextStep = stepENUM.typeStart
                    setTimeout(() => {
                        dispatchReduxToolkit(setStepState(stepENUM.typeStart));
                    }, 2000)
                    dispatchReduxToolkit(setSelectedFeatureState({
                        type: featureType?.type,
                        name: featureType?.name || "",
                        code: actionData
                    }));
                }
                if (stepAction === stepENUM.typeEditName) {
                    name = actionData;
                    nextStep = stepENUM.typeStart
                    setTimeout(() => {
                        dispatchReduxToolkit(setStepState(stepENUM.typeStart));
                    }, 2000)
                    dispatchReduxToolkit(setSelectedFeatureState({
                        type: featureType?.type,
                        name: actionData,
                        code: featureType?.code || "",
                    }));
                }
                if (stepAction === stepENUM.typeEditCode) {
                    code = actionData
                    nextStep = stepENUM.typeStart
                    setTimeout(() => {
                        dispatchReduxToolkit(setStepState(stepENUM.typeStart));
                    }, 2000)
                    dispatchReduxToolkit(setSelectedFeatureState({
                        type: featureType?.type,
                        name: featureType?.name || "",
                        code: actionData
                    }));
                }
                break;
            default:
                break;
        }
        setMessageProp(messageSender);
        triggerBotAction(code, name, nextStep);
    };

    const getCurrentTime = () => {
        const userLocale = navigator.language;
        const now = new Date();
        const timeFormat = new Intl.DateTimeFormat(userLocale, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        });

        return timeFormat.format(now);
    };


    /* Chat Channel has started */
    const beforeUnloadHandler = (event: any) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const messagesProcessor = async (message: any) => {
        const messageType = message?.headers["x-amz-chime-event-type"];
        const record = JSON.parse(message?.payload);
        const currentTime = getCurrentTime();
        switch (messageType) {
            // Channel Messages
            case "CHANNEL_DETAILS":
                //send message joined channel
                dispatchReduxToolkit(addMessageItem({
                    sender: senderENUM.system,
                    message: t("Joined_note"),
                    datetime: currentTime,
                }));
                setSendStartedMessage(true)
                break;
            case "CREATE_CHANNEL_MESSAGE":
            case "REDACT_CHANNEL_MESSAGE":
            case "UPDATE_CHANNEL_MESSAGE":
            case "DELETE_CHANNEL_MESSAGE":
            case "DENIED_CREATE_CHANNEL_MESSAGE":
            case "FAILED_CREATE_CHANNEL_MESSAGE":
            case "DENIED_UPDATE_CHANNEL_MESSAGE":
            case "FAILED_UPDATE_CHANNEL_MESSAGE":
            case "PENDING_CREATE_CHANNEL_MESSAGE":
            case "PENDING_UPDATE_CHANNEL_MESSAGE":
                await processChannelMessage(record);
                break;
            case "CREATE_CHANNEL":
            case "UPDATE_CHANNEL":
                break;
            case "DELETE_CHANNEL":
                dispatchReduxToolkit(ChannelClearState());
                setErrorModalMessage(t('Terminated_Channel_note'));
                setDisplayDeleteModal(true);
                fetchChannels();
                break;
            case "CREATE_CHANNEL_MEMBERSHIP":
                if (!sendStartedMessage) {
                    //send message joined channel
                    dispatchReduxToolkit(addMessageItem({
                        sender: senderENUM.system,
                        message: t("Joined_note"),
                        datetime: currentTime,
                    }));
                    setSendStartedMessage(true)
                }
                dispatchReduxToolkit(SetNewMemberJoinChannel(true));
                fetchChannels();
                break;
            case "UPDATE_CHANNEL_MEMBERSHIP":
                dispatchReduxToolkit(SetNewMemberJoinChannel(true));
                break;
            case "DELETE_CHANNEL_MEMBERSHIP":
                dispatchReduxToolkit(SetNewMemberJoinChannel(true));
                if (record?.Member?.Arn.includes(member?.username.toLowerCase())) {
                    setErrorModalMessage(t('End_Channel_note'));
                    setDisplayDeleteModal(true);
                    dispatchReduxToolkit(ChannelClearState());
                    dispatchReduxToolkit(clearStateAfterEndChannel())
                }
                fetchChannels();
                break;
            default:
                console.log(`Unexpected message type! ${messageType}`);
        }
    };

    const excuteStartChannel = async (ticketIdParam: string) => {
        setIsLoading(true);
        // Create a messaging session
        const channelName = ticketIdParam; // Replace with the channel name
        const anonymousUserId = member?.userId; // Replace with the anonymous user ID
        const response = await listChannels(process.env.REACT_APP_AWS_APP_INSTANCE_ARN, anonymousUserId);
        const exitChannel = response.find(
            (x: any) =>
                x.Name == channelName
        );
        if (exitChannel) {
            const channelArn = exitChannel.ChannelArn;
            const memberArn = JSON.parse(exitChannel.Metadata)?.memberArn;
            if (channelArn) {
                connectChannel(
                    channelArn,
                    member ? member.userId : "",
                    memberArn
                );
                setChannelArnString(channelArn)
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setErrorModalMessage("Cannot create new Channel");
                setDisplayErrorModal(true);
            }
        } else {
            setIsLoading(false);
            setErrorModalMessage(`${t('Session_title')} ${channelName} ${t('Not_start_note')} `);
            setDisplayErrorModal(true);
        }

    }

    const connectChannel = async (
        channelDescribe: string,
        userId: string,
        memberArn: string
    ) => {
        const promise = Promise.resolve(channelDescribe);
        const channelArnCurrent = await promise.then((m) => m);
        const channel = await describeChannel(channelArnCurrent, userId);
        const memberShip = await listChannelMemberships(
            channelArnCurrent,
            userId,
        )
        const exitMember = memberShip.find(
            (x: any) =>
                x.Member.Name == member?.username
        );
        if (channel) {
            if (!exitMember) {
                const statusAdd = await onAddMember(channel, memberArn, userId);
                if (statusAdd) {
                    dispatchReduxToolkit(channelSetChannel(channel));
                    if (channelArnCurrent && channelArnCurrent !== '') {
                        const newMessages = await listChannelMessages(
                            channelArnCurrent,
                            userId
                        )
                        let listNewChannelMessage = newMessages.Messages ? newMessages.Messages.filter((m: any, i, seif) => {
                            let checkType = m.Metadata ? JSON.parse(m.Metadata).type : ""
                            if (checkType === 'history') {
                                return false;
                            }
                            return true;
                        }) : [];

                        const message_translate = await Promise.all(
                            listNewChannelMessage.map(async (message: any) => {
                                if (
                                    createMemberArn(userId) !== message.Sender.Arn &&
                                    isTranslate.status
                                ) {
                                    // const sourceLanguageCode = JSON.parse(message.Metadata)?.language || "";
                                    const translateMessage = await CommonService.liveTranslateMessage(
                                        message.Content,
                                        isTranslate.langSource,
                                        isTranslate.langTarget,
                                    )
                                    return { ...message, Content: translateMessage }
                                }
                                return message
                            }),
                        )
                        await dispatchReduxToolkit(
                            ChannelSetMessage({
                                ChannelMessages: message_translate,
                                NextToken: newMessages.NextToken,
                            }),
                        )

                    }
                    fetchChannels();
                }
            }
        } else {
            console.log("Error, could not retrieve channel information.");
        }
    };

    const processChannelMessage = async (message: any) => {
        const promise = Promise.resolve(message);
        const newMessage = await promise.then((m) => m);
        let browserLanguage = window.navigator.language;
        let messageDetectLanguage = await CommonService.detectLanguage({ text: newMessage.Content });
        if (newMessage.Persistence === Persistence.NON_PERSISTENT && newMessage.Type === MessageType.CONTROL) {
            dispatchReduxToolkit(setPresenceOfUserInPublicChannel(newMessage));
        } else {
            //enable detected language
            if ((!browserLanguage.toLocaleLowerCase().includes(messageDetectLanguage)) && (newMessage.Sender.Arn !== createMemberArn(member?.userId))) {
                await processChannelMessageDetectLanguage(browserLanguage, messageDetectLanguage);
            }
            let metaData = JSON.parse(newMessage.Metadata)
            let checkHistoryType = metaData.type ? (metaData.type === 'history' ? true : false) : false;
            //send noti in browser
            if (!tabHasFocus && !checkHistoryType) {
                addNotification({
                    title: newMessage.Sender.Name + " sent you a message",
                    message: newMessage.Content,
                    theme: "darkblue",
                    duration: 10000,
                    native: true,
                    onClick: notifiOnclick,
                });
            }
            setMessageProp(newMessage);
        }
    };

    const processChannelMessageDetectLanguage = async (browserLanguage: string, messageDetectLanguage: string) => {
        if (!onDetectedLanguage) {
            let getCountyByBrowserLanguage = await getTextByKey(browserLanguage);
            let getCountyByMessageDetectLanguage = await getTextByKey(messageDetectLanguage);
            setOnDetectedLanguage(true)
            const messageForModal = t("Translation_is_available_detail").replace("[tech_language]", getCountyByBrowserLanguage || "").replace("[expert_language]", getCountyByMessageDetectLanguage || "");
            await setMessageLanguageDetectedModal(messageForModal)
            setDisplaydetectedLanguageModal(true)
            setLanguageDetectedCode({
                browserLang: browserLanguage,
                messageLang: messageDetectLanguage
            })
        }
    }

    const getTextByKey = (key: string) => {
        let country = countryOptions.find(option => option.key.includes(key));
        if (!country) {
            country = countryOptions.find(option => key.includes(option.key));
        }
        return country ? country.text : undefined;
    }

    const onHanldeStartLiveTranslation = () => {
        dispatchReduxToolkit(SetIsTranslate({
            status: true,
            langSource: languageDetectedCode?.messageLang,
            langTarget: languageDetectedCode?.browserLang,
            languagebox: true
        }));
        setDisplaydetectedLanguageModal(false)
    };
    const onCancelStartLiveTranslation = () => {
        dispatchReduxToolkit(SetIsTranslate({
            status: false,
            langSource: languageDetectedCode?.messageLang,
            langTarget: languageDetectedCode?.browserLang,
            languagebox: false
        }));
        setDisplaydetectedLanguageModal(false)
    };

    const notifiOnclick = () => {
        window.focus();
    };

    const onTrigger = () => {
        setDisplayErrorModal(false)
        dispatchReduxToolkit(setOnStartAChatChannel(false))
        setMember(null)
    }
    const onCloseModal = () => {
        setDisplayBEOfflineModal(false)
    }

    const onDeleteChannel = () => {
        dispatchReduxToolkit(clearStateAfterEndChannel())
        setDisplayDeleteModal(false)
        dispatchReduxToolkit(setOnStartAChatChannel(false))
        dispatchReduxToolkit(ChannelClearState())
        setMember(null)
        setIsOpen(false)
        dispatchReduxToolkit(SetIsTranslate({
            status: false,
            langSource: "auto",
            langTarget: "auto",
            languagebox: false
        }));
        setDisplaydetectedLanguageModal(false);
        setDisplaySelectLanguageModal(false)
        setOnDetectedLanguage(false)
        setSendStartedMessage(false)
    }

    const onAddMember = async (channel: any, memberArn: string, userId: string) => {
        const promise = Promise.resolve(channel);
        const channelArn = await promise.then((m) => m.ChannelArn);

        try {
            // this function have swap position of memberArn and annonymous arn
            const membership = await createChannelMembership(
                channelArn,
                `${process.env.REACT_APP_AWS_APP_INSTANCE_ARN}/user/${userId}`,
                memberArn,
            );
            return membership;
        } catch {
            console.log("Error occurred. Member not added to channel.");
        }
    };

    const fetchChannels = async () => {
        const userChannelMemberships = await listChannelMembershipsForAppInstanceUser(member?.userId);
        userChannelMemberships
            .map((channelMembership: any) => channelMembership.ChannelSummary)
            .sort((current: any, next: any) => {
                try {
                    const newDate = new Date();
                    const nextLast = next.LastMessageTimestamp
                        ? next.LastMessageTimestamp.getTime()
                        : newDate.getTime();
                    const currentLast = current.LastMessageTimestamp
                        ? current.LastMessageTimestamp.getTime()
                        : newDate.getTime();

                    return nextLast - currentLast;
                } catch {
                    return null;
                }
            });

    };

    const handleOnOpenTranslateModal = (status: boolean) => {
        if (!status) {
            dispatchReduxToolkit(SetIsTranslate({
                ...isTranslate,
                status: false,
                languagebox: false
            }))
        }
        setDisplaySelectLanguageModal(status)
    };


    const registerGroup = (channelArn: string) => {
        signalRConnectorInstance.joinGroup(channelArn).then(() => {
            console.log(`Joined into group ${channelArn}`);
        }).catch((err: any) =>
            console.log(
                `Join to group ${channelArn} failed. Err: ${err}`
            )
        );
    };

    const handleReceiveMessage = (messages: any) => {
        if (messages) {
            let newArrayStatus = messages.map((item: memberSingal) => {
                let newItem: MemberPresence = {
                    userArn: item.memberARN,
                    status: item.status,
                    role: item.role
                }
                return newItem
            })
            setMemberPresenceList(newArrayStatus)
        } else {
            setMemberPresenceList([])
        }
    }

    //connect to SignalR
    useEffect(() => {
        if (channelArn && !sinalRHubConnected) {
            signalRConnectorInstance.onConnectionEstablished(() => {
              registerGroup(channelArn);
            });
      
            signalRConnectorInstance.onRegisterMessageHandler(
              (message: any) => {
                handleReceiveMessage(message);
              }
            );
            signalRConnectorInstance.setupEmitter();
            setSinalRHubConnected(true);
          }
    }, [channelArn, channelArnString])

    //send ping message to channel
    useEffect(() => {
        let isExitedMetting = meetingData?.Meeting.meetingId ? true : false;
        let messageObj = {
            key: channelArn,
            value: [
                {
                    memberARN: member?.userId,
                    status: "online",
                    datetime: new Date().toISOString(),
                    role: "technician"
                }
            ]
        }
        const sendMessageToGroup = (message: any) => {
            signalRConnectorInstance.sendMessageToGroup("SendSinalToGroup", channelArn, JSON.stringify(message));
        };
        sendMessageToGroup(messageObj);
        const intervalId = setInterval(() => {
            // send online to active channel
            messageObj.value = [{
                memberARN: member?.userId,
                status: "online",
                datetime: new Date().toISOString(),
                role: "technician"
            }]
            sendMessageToGroup(messageObj);
        }, TimeOutOnlineStatus);

        //clear inteval
        if ((!tabHasFocus && !isExitedMetting) || !channelArn || !member) {
            clearInterval(intervalId);
        }
        // send onhold signal
        if (!tabHasFocus && channelArn && member) {
            messageObj.value = [{
                memberARN: member?.userId,
                status: "onhold",
                datetime: new Date().toISOString(),
                role: "technician"
            }]
            sendMessageToGroup(messageObj);
            clearInterval(intervalId);
        } else {
            sendMessageToGroup(messageObj);
        }
        return () => clearInterval(intervalId);
    }, [tabHasFocus, channelArn, member, meetingData]);


    //get channel arn
    useEffect(() => {
        if (channelArnString !== "") {
            window.addEventListener("beforeunload", beforeUnloadHandler);
        } else {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [channelArnString]);

    // register user to chimeSDK
    useEffect(() => {
        if (featureType && !member && onStartAChatChannel) {
            setIsLoading(true);
            let memberTemp = {
                username: "anonymous",
                userId: "adfs.anonymous",
            };
            let newMemberChime = {
                ciamId: memberTemp.userId,
                name: memberTemp.username,
                mobile: "",
                email: ""
            }
            if (featureType.name && featureType.name !== "") {
                let nameTrim = featureType.name.replace(/\s/g, '');
                memberTemp.username = nameTrim;
                memberTemp.userId = userChime?.userId || `adfs.anonymous_${nameTrim.toLowerCase()}_` + `${uuid}`
                newMemberChime.ciamId = memberTemp.userId;
                newMemberChime.name = memberTemp.username;

            } else {
                memberTemp.userId = `adfs.anonymous_` + `${uuid}`;
                newMemberChime.ciamId = memberTemp.userId;
                newMemberChime.name = memberTemp.username;
            }
            CommonService.getChimeUserForPublic(newMemberChime);
            dispatchReduxToolkit(setUserChime(memberTemp));
            setMember(memberTemp);
        }
    }, [featureType, onStartAChatChannel]);

    //Authen to AWS SDK
    useEffect(() => {
        if (member) {
            AWS.config.region = process.env.REACT_APP_REACT_REGION;
            const setAwsKeysAndConnect = async () => {
                const awsKeys = await CommonService.getChimeSecretPublic();
                if (awsKeys) {
                    const awsKeysDecrypt = await RSADecrypt.RSADecrypt(awsKeys);
                    if (awsKeysDecrypt) {
                        let awsKeysDecryptData = JSON.parse(awsKeysDecrypt!)
                        AWS.config.credentials = {
                            accessKeyId: awsKeysDecryptData.AccessKey,
                            secretAccessKey: awsKeysDecryptData.SecretKey,
                        };
                        console.log("Get AWS keys Success");
                    }
                    if (featureType?.code && featureType.code !== "") {
                        excuteStartChannel(featureType.code);
                    }
                } else {
                    console.log("Cannot get AWS keys");
                }
                await messagingService.connect(member);
                setOnLoginAwsSuccess(true)
            }
            setAwsKeysAndConnect();
            return () => {
                messagingService.close();
            };
        }
    }, [member]);

    //subscribeToMessageUpdate
    useEffect(() => {
        if (member || !onDetectedLanguage || channelArn) {
            messagingService.subscribeToMessageUpdate(messagesProcessor);
        }

        return () => {
            messagingService.unsubscribeFromMessageUpdate(messagesProcessor);
        };
    }, [messagingService, member, onDetectedLanguage, channelArn]);

    //send history messages
    useEffect(() => {
        const sendChannelMessageFunc = async () => {
            //send history messages
            let options = {
                botHistory: messageList,
                type: `history`
            }
            let memberObject = {
                userId: member?.userId
            }
            await sendChannelMessage(
                channelArn,
                "History",
                Persistence.PERSISTENT,
                MessageType.STANDARD,
                memberObject,
                JSON.stringify(options),
            )
            setSendStartedMessage(false)
        }

        if (sendStartedMessage && channelArn && member) {
            sendChannelMessageFunc()
        }
    }, [sendStartedMessage, channelArn, member])

    //set to false when user reload page
    useEffect(() => {
        setOnLoginAwsSuccess(false)
    }, []);

    //detect focus or blur
    useEffect(() => {
        window.addEventListener("focus", handleFocus);
        window.addEventListener("blur", handleBlur);

        return () => {
            window.removeEventListener("focus", handleFocus);
            window.removeEventListener("blur", handleBlur);
        };
    }, []);

    //set first assistant message
    useEffect(() => {
        if (isOpen && !activeState && messageList.length === 0) {
            let currentTime = getCurrentTime();
            dispatchReduxToolkit(addMessageItem({
                sender: senderENUM.assistant,
                message: `${t("Assistant_first_message")}`,
                datetime: currentTime,
            }));
            dispatchReduxToolkit(setActiveState(true));
        }
    }, [isOpen, activeState])
    return (
        <div className={Style.sc_widget}>
            <div className={isOpen ? `${Style.sc_launcher} ${Style.opened}` : Style.sc_launcher} onClick={handleClick}>
                <img className={Style.sc_closed_icon} src={launcherIcon} />
            </div>
            <ChatWindow
                isOpen={isOpen}
                onClose={() => hanldeCloseState()}
                onExpandWindow={() => hanldeExpandWindow()}
                title={t("Assistant_Label_Bot")}
                onUserInputSubmit={hanldeOnSubmitInput}
                message={messageProp}
                onMinimize={onMinimize}
                onOpenTranslateModal={() => { handleOnOpenTranslateModal(true) }}
                onCloseTranslateModal={() => { handleOnOpenTranslateModal(false) }}
                onLoginAwsSuccess={onLoginAwsSuccess}
                memberPresenceList={memberPresenceList}
            />
            {
                displayErrorModal && (<RDAlert title={errorModalMessage} messages={t('Retry_note_v2')} isOpen={displayErrorModal} type={"retry"} acceptButtonText={t('Retry_panel')} onAccept={onTrigger} />)
            }
            {
                displayDeleteModal && (<RDAlert title={errorModalMessage} messages={t('Thanks_note')} onAccept={onDeleteChannel} isOpen={displayDeleteModal} type={"close_channel"} acceptButtonText={t('Close_panel')} />)
            }
            {
                displaydetectedLanguageModal && (<RDAlert title={t('Translation_is_available')} messages={messageLanguageDetectedModal} onCancel={onCancelStartLiveTranslation} onAccept={onHanldeStartLiveTranslation} isOpen={displaydetectedLanguageModal} type={"detected_language"} acceptButtonText={t("Yes_Label")} cancelButtonText={t("No_Label")} />)
            }
            {
                displaySelectLanguageModal && (<ModalTranlationOptionsPublic isOpen={displaySelectLanguageModal} translateModeData={isTranslate} onClose={() => setDisplaySelectLanguageModal(false)} enableByBotWidget={displaySelectLanguageModal} acceptButtonText={t('Continue_panel')} />)
            }
            {
                displayBEOfflineModal && (<RDAlert title={t('Channel_Notification')} messages={t('BE_Leave')} isOpen={displayBEOfflineModal} type={"info"} acceptButtonText={t('OK_Label')} onAccept={onCloseModal} />)
            }
            {isLoading && (
                <Fragment>
                    <CustomLoader enable={isLoading}></CustomLoader>
                </Fragment>
            )}
        </div>
    );
};

export default BotWidget;