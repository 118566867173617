import React, { useEffect } from 'react';


const DeepLinkForQR = () => {

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            return 'iOS';
        }
        else if (userAgent.match(/Android/i)) {
            return 'Android';
        }
        else if (userAgent.match(/Windows/i)) {
            return 'Windows';
        }
    }

    useEffect(() => {
        let appURL = "";
        switch (getMobileOperatingSystem()) {
            case 'Android':
                console.log("vô androi");
                
                appURL = process.env.REACT_APP_MOBILE_APP_CHPLAY_URL || "";
                break;
            case 'iOS':
                console.log("vô ios");
                appURL = process.env.REACT_APP_MOBILE_APP_APPSTORE_URL || "";
                break;
            case 'Windows':
                appURL = process.env.REACT_APP_DEFAULT_CLIENTROOT || "";
                break;
            default:
                break;
        }


        setTimeout(() => {
            window.location.href = appURL;
        }, 1000);
    }, [])


    return (
        <></>
    );
};
export default DeepLinkForQR;
