import React, { Fragment } from 'react';
import {
  RosterGroup,
  useRosterState,
  RosterAttendeeType,
} from 'amazon-chime-sdk-component-library-react';
import RosterAttendeeWrapper from './RosterAttendeeWrapper';
import { useTranslation } from "react-i18next";

const MeetingRoster = () => {
  const { roster } = useRosterState();
  const { t } = useTranslation();
  const attendees = Object.values(roster);

  const attendeeItems = attendees.map((attendee: RosterAttendeeType) => {
    const { chimeAttendeeId } = attendee || {};
    return <RosterAttendeeWrapper key={chimeAttendeeId} attendeeId={chimeAttendeeId} />;
  });

  return (
    <div className='list--attendees'>{
      (attendeeItems && attendeeItems.length > 0 &&
        <Fragment>
          <span>{t("Attendees_Label") + ": "}</span>
          <br />
          <span></span>
          <br />
          <RosterGroup>{attendeeItems}</RosterGroup>
        </Fragment>
      )
    }
    </div>
  );
};

export default MeetingRoster;
