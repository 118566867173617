import { FC, useEffect, useRef, useState } from "react";
import React from "react";
import Style from './styles/chat-window.module.css';
import Header from "./Header";
import Messages from "./MessageList";
import UserInput from "./UserInput";
import { messageListModal, selectAssistantMessageListState, selectChatChannelHistory, selectFeatureType, selectOnSaveHistoryMessages, selectOnStartAChatChannel, selectShowLoadingDot, selectUserChime, senderENUM, setChatChannelHistory, setOnSaveHistoryMessages } from "../../../../redux/assistantBot";
import { useSelector } from "react-redux";
import PublicInputMessage from "../../PublicDashBoard/PublicInputChat/PublicInputMessage";
import { ChannelSetMessage, selectChannelArn, selectIsTranslate } from "../../../../redux/chimeSlide";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Boschicon from "../../../../common-components/boschIcon/Boschicon";
import { createMemberArn, listChannelMessages } from "../../../../api/ChimeAPI";
import { CommonService } from "../../../../services/Common";
import { useAppDispatch } from "../../../../redux/store";
import { MemberPresence } from "./BotWidget";
import formatTime from "../../../../services/FormatTime";

export interface IChatProps {
  isOpen: boolean;
  onLoginAwsSuccess: boolean;
  onClose: () => void;
  onExpandWindow: () => void;
  onMinimize: () => void;
  onOpenTranslateModal: () => void;
  onCloseTranslateModal: () => void;
  title: string;
  message: messageListModal[];
  onUserInputSubmit: any;
  memberPresenceList: MemberPresence[]
}

const ChatWindow: FC<IChatProps> = ({ isOpen, onClose, title, message, onUserInputSubmit, onExpandWindow, onOpenTranslateModal, onCloseTranslateModal, onMinimize, onLoginAwsSuccess, memberPresenceList }) => {
  const showLoadingDot = useSelector(selectShowLoadingDot);
  const onStartAChatChannel = useSelector(selectOnStartAChatChannel);
  const assistantMessageListState = useSelector(selectAssistantMessageListState);
  const featureType = useSelector(selectFeatureType);
  const chatChannelHistory = useSelector(selectChatChannelHistory);
  const browserLanguage = window.navigator.language
  const channelArn = useSelector(selectChannelArn);
  const userChimeID = useSelector(selectUserChime);
  const onSaveHistoryMessages = useSelector(selectOnSaveHistoryMessages);
  const [onCloseSessionConfirm, setOnCloseSessionConfirm] = useState<boolean>(false);
  const [updatedListMessage, setUpdatedListMessage] = useState<messageListModal[]>([]);
  const [updatedListChannelMessage, setUpdatedListChannelMessage] = useState<messageListModal[] | null>(null);
  const [channelMessageState, setChannelMessageState] = useState<any>(null);
  const isTranslate = useSelector(selectIsTranslate);
  const chatWidgetDivRef = useRef<null | HTMLDivElement>(null);
  const dispatchReduxToolkit = useAppDispatch();
  const [onLoadChannelMessageSuccess, setOnLoadChannelMessageSuccess] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOnCloseSession = () => {
    setOnCloseSessionConfirm(true)
  }

  const handleOnBeforeCloseSession = () => {
    onClose()
    setTimeout(() => { setOnCloseSessionConfirm(false) }, 500)
  }

  useEffect(() => {
    const progressMessages = async () => {
      let tempMessageList: messageListModal[] = [];
      if (assistantMessageListState) {
        tempMessageList = assistantMessageListState;
      }
      if (channelArn && channelMessageState && channelMessageState.ChannelMessages && onLoadChannelMessageSuccess) {
        let tempChannelMessage = await channelMessageState.ChannelMessages.map((m: any) => {
          const senderArn = m?.Sender?.Arn;
          let senderType = senderENUM.boschExpert;
          let currentTime = formatTime(m.CreatedTimestamp);
          if (senderArn.includes(`${userChimeID?.userId}`)) {
            senderType = senderENUM.user
          } else {
            senderType = senderENUM.member
          }

          if (senderArn.includes(`@bosch.com`)) {
            senderType = senderENUM.boschExpert
          }
          return {
            sender: senderType,
            message: m.Content,
            datetime: currentTime,
            metaData: m.Metadata,
            messageId: m.MessageId || "",
            senderName: m.Sender?.Name || "",
            senderArn: m.Sender?.Arn || ""
          }
        })
        if (!onSaveHistoryMessages && !chatChannelHistory) {
          let checkedTempChannelMessage = tempChannelMessage.length === 0 ? ["empty"] : tempChannelMessage;
          await dispatchReduxToolkit(
            setChatChannelHistory(checkedTempChannelMessage),
          )
          await dispatchReduxToolkit(
            setOnSaveHistoryMessages(true),
          )
        }
        setUpdatedListChannelMessage(tempChannelMessage);
      }
      setUpdatedListMessage(tempMessageList);
    }
    progressMessages();
  }, [message, channelMessageState, assistantMessageListState, onSaveHistoryMessages])


  useEffect(() => {
    const updateMessage = async () => {
      if (onLoginAwsSuccess && channelArn && channelArn !== '') {
        const newMessages = await listChannelMessages(
          channelArn,
          userChimeID?.userId
        )

        let listNewChannelMessage = newMessages.Messages ? newMessages.Messages.filter((m: any, i, seif) => {
          let checkType = m.Metadata ? JSON.parse(m.Metadata).type : ""
          if (checkType === 'history') {
            return false;
          }
          return true;
        }) : [];

        const message_translate = await Promise.all(
          listNewChannelMessage.map(async (message: any) => {
            if (
              createMemberArn(userChimeID) !== message.Sender.Arn &&
              isTranslate.status
            ) {
              const translateMessage = await CommonService.liveTranslateMessage(
                message.Content,
                isTranslate.langSource,
                isTranslate.langTarget,
              )
              return { ...message, Content: translateMessage }
            }
            return message
          }),
        )
        await dispatchReduxToolkit(
          ChannelSetMessage({
            ChannelMessages: message_translate,
            NextToken: newMessages.NextToken,
          }),
        )
        await setChannelMessageState(
          newMessages ? {
            ChannelMessages: message_translate,
            NextToken: newMessages.NextToken,
          } : null
        )
        setOnLoadChannelMessageSuccess(true);
      }
    }
    updateMessage()
  }, [channelArn, message, isTranslate, onLoginAwsSuccess])

  useEffect(() => {
    if (!channelArn) {
      setUpdatedListChannelMessage(null)
      setChannelMessageState(null)
    }
  }, [channelArn])

  useEffect(() => {
    /**
     * minimize chat widget clicked on outside of element
     */
    function handleClickOutside(e: any) {
      const hasClassContainer = e.target.classList.contains('container-landingpage');
      const hasClassContainerMainContent = e.target.classList.contains('LandingPage_main_content__47xmq');
      if (chatWidgetDivRef.current && !chatWidgetDivRef.current.contains(e.target) && (hasClassContainer || hasClassContainerMainContent)) {
        onMinimize()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [chatWidgetDivRef]);

  return (
    <div
      className={isOpen ? Style.sc_chat_window : `${Style.sc_chat_window} ${Style.closed}`}
      ref={chatWidgetDivRef}
    >
      <Header
        title={title}
        onClose={handleOnCloseSession}
        onExpandWindow={onExpandWindow}
        onMinimize={onMinimize}
        onOpenTranslateModal={onOpenTranslateModal}
        onCloseTranslateModal={onCloseTranslateModal}
      />


      {
        onCloseSessionConfirm ? (
          <>
            <div className={Style.content_confirm_close_session}>
              <Boschicon name="bosch-ic-robothead"></Boschicon>
              <p>{t("Close_Channel_Message_Question")}</p>
            </div>
            <div className={Style.btns_confirm_close_session}>
              <Button type="button" className={Style.btn_confirm_close_session__button} content={t("Close_panel")} onClick={handleOnBeforeCloseSession} />
              <Button type="button" className={`${Style.btn_confirm_close_session__button} ${Style.gray}`} content={t("Cancel_Label")} onClick={() => { setOnCloseSessionConfirm(false) }} />
            </div>
          </>
        ) : (
          <>
            <Messages
              messagesAssit={updatedListMessage}
              messagesChanel={updatedListChannelMessage}
              memberPresenceList={memberPresenceList}
            />
            {
              (onStartAChatChannel) ? (
                <>
                  {
                    (featureType && channelArn && userChimeID) && (
                      <PublicInputMessage
                        activeChannelArn={channelArn}
                        member={userChimeID.userId}
                        browserLanguage={browserLanguage}
                      />
                    )
                  }
                </>
              ) : (
                !showLoadingDot ? (
                  <UserInput
                    onSubmit={onUserInputSubmit}
                  />
                ) : (
                  <></>
                )
              )
            }
          </>
        )
      }
    </div>
  );
};

export default ChatWindow;
