import React, { createContext, useContext, useEffect, useMemo } from "react";

interface WebChatComponentContextType {}

const WebChatComponentContext = createContext<
  WebChatComponentContextType | undefined
>(undefined);
interface WebChatComponentContextProviderProps {
  children: React.ReactNode;
}

//TODO: Need to fix interval stopped in idle issue
export const WebChatComponentContextProvider: React.FC<
  WebChatComponentContextProviderProps
> = ({ children }) => {
  //TODO: Refactor code for web component reference
  useEffect(() => {
    // Adding CSS file dynamically
    const cssPath = process.env.REACT_APP_CHAT_WEB_COMPONENT_STYLE_URL ?? "";
    const isCssExisting = !!document.querySelector(`link[href^="${cssPath}"]`);
    const cssLink = document.createElement("link");
    if (!isCssExisting) {
      cssLink.href = `${
        process.env.REACT_APP_CHAT_WEB_COMPONENT_STYLE_URL ?? ""
      }?v=${Math.random()}`;
      cssLink.rel = "stylesheet";
      document.head.prepend(cssLink);
    }

    // Adding JS file dynamically
    const jsScriptPath =
      process.env.REACT_APP_CHAT_WEB_COMPONENT_JAVASCRIPT_URL ?? "";
    const isJsScriptExisting = !!document.querySelector(
      `script[src^="${jsScriptPath}"]`
    );
    const jsScript = document.createElement("script");
    if (!isJsScriptExisting) {
      jsScript.src = `${
        process.env.REACT_APP_CHAT_WEB_COMPONENT_JAVASCRIPT_URL ?? ""
      }?v=${Math.random()}`;
      jsScript.async = true;
      document.body.prepend(jsScript);
    }

    return () => {
      // Cleanup: remove the dynamically added files
      if (!isCssExisting) {
        document.head.removeChild(cssLink);
      }

      if (!isJsScriptExisting) {
        document.body.removeChild(jsScript);
      }
    };
  }, []);
  const contextValue = useMemo(() => ({}), []);

  return (
    <WebChatComponentContext.Provider value={contextValue}>
      {children}
    </WebChatComponentContext.Provider>
  );
};

export const useWebChatComponentContext = () => {
  const context = useContext(WebChatComponentContext);
  if (!context) {
    throw new Error(
      "use  WebChatComponentContext must be used within a   WebChatComponentContextProvider"
    );
  }
  return context;
};
