import React, { Fragment } from 'react';
import { Modal } from "semantic-ui-react";
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

interface ModalGetEmojiProps {
    isOpen: boolean,
    onClose: Function,
    getEmoji: Function
}

const ModalGetEmoji = ({ isOpen, onClose, getEmoji }: ModalGetEmojiProps) => {
    return (
        <Fragment>
            <Modal
                onClose={() => onClose()}
                open={isOpen}
                dimmer={{ className: "test-dimmer" }}
                size={"tiny"}
                closeIcon
                closeOnDimmerClick={false}
            >
                <EmojiPicker onEmojiClick={(emojiData: EmojiClickData) => getEmoji(emojiData)} width="100%" searchDisabled={true} lazyLoadEmojis={true} autoFocusSearch={false}></EmojiPicker>
            </Modal>
        </Fragment>

    );
};

export default ModalGetEmoji;
