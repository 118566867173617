import React, { Fragment, useState } from 'react'
import { Grid, Button, Modal, Icon, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import './css/ModalCreateNewChannel.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Boschicon from '../../common-components/boschIcon/Boschicon'
interface ModalCreateNewChannelProps {
  isOpen: boolean
  onClose: Function
  onchangeTextSearchInput: Function
  sendLinkVisualConnect: Function
  sendEmailVisualConnect: Function
  onStartRandomChannel: Function
  onStartRandomVSC: Function
}

const ModalCreateNewChannel = ({
  isOpen,
  onClose,
  onchangeTextSearchInput,
  onStartRandomChannel,
  sendLinkVisualConnect,
  sendEmailVisualConnect,
  onStartRandomVSC
}: ModalCreateNewChannelProps) => {
  const [openSendInviteChat, setOpenSendInviteChat] = useState<boolean>(false)
  const [
    openSendInviteVisualConnect,
    setOpenSendInviteVisualConnect,
  ] = useState<boolean>(false)
  const [textSearchInput, setTextSearchInput] = useState<String>('')
  const [openCodeInput, setOpenCodeInput] = useState<boolean>(false)
  const [openPhoneInput, setOpenPhoneInput] = useState<boolean>(false)
  const [openEmailInput, setOpenEmailInput] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const { t } = useTranslation()
  const onChangeEmail = (e: any) => {
    setEmail(e.target.value)
  }

  const randomNumberFunc = () => {
    return Math.floor(Math.random() * 100000000).toString().padStart(8, '0');
  }

  const onHandleGenerateRandomCode = (e: any) => {
    const randomNumber = randomNumberFunc()
    onStartRandomChannel(randomNumber)
  }
  const onHandleGenerateRandomCodeVSC = (e: any) => {
    const randomNumber = randomNumberFunc()
    onStartRandomVSC(randomNumber)
  }

  return (
    <Fragment>
      {!openSendInviteChat && !openSendInviteVisualConnect && (
        <Modal
          onClose={() => onClose()}
          open={isOpen}
          dimmer={{ className: 'test-dimmer' }}
          size={'tiny'}
          closeIcon
          closeOnDimmerClick={false}
          className='create-new-modal'
        >
          <div className="header-color-line"></div>
          <Modal.Header className='start-new-header' style={{ textAlign: 'center' }}>
            {t('Start_New_Question')}
          </Modal.Header>
          <Modal.Content>
            <Grid stackable columns={2}>
              <Grid.Column className="options">
                <Button
                  className="options--item"
                  onClick={() => setOpenSendInviteChat(true)}
                >
                  <Boschicon name={'bosch-ic-ticket'} />
                  <p>{t('Chat_Room_Label')}</p>
                </Button>
              </Grid.Column>
              <Grid.Column className="options">
                <Button
                  className="options--item"
                  onClick={() => setOpenSendInviteVisualConnect(true)}
                >
                  <Icon style={{ verticalAlign: 'sub', padding: 'unset' }}>
                    <div className='visualConnect-image'></div>
                  </Icon>
                  <p>{t('Visual_Connect_Label')}</p>
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      )}
      {openSendInviteChat && (
        <Modal
          onClose={() => onClose()}
          open={isOpen}
          dimmer={{ className: 'test-dimmer' }}
          size={'tiny'}
          closeIcon
          closeOnDimmerClick={false}
          className='create-new-modal'
        >
          <div className="header-color-line"></div>
          {(!openCodeInput) && (
            <>
              <Modal.Header className='start-new-header' style={{ textAlign: 'center' }}>
                {t('Invite_To_Chat_Question')}
              </Modal.Header>
              <Modal.Content>
                <Grid stackable columns={2}>
                  <Grid.Column className="options">
                    <Button
                      className="options--item"
                      onClick={() => setOpenCodeInput(true)}
                    >
                      <Boschicon name={'bosch-ic-ticket'} />
                      {t('Ticket_Id_Label')}
                    </Button>
                    <Button className="options--item" onClick={onHandleGenerateRandomCode}>
                      <Boschicon name={'bosch-ic-binary'} />
                      {t('Random_ID')}
                    </Button>
                  </Grid.Column>
                  <Grid.Column className="options">
                    <Button className="options--item" disabled>
                      <Boschicon name={'bosch-ic-smartphone-loading-wheel'} />
                      {t('Bosch_ID_Label')}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Modal.Content>
            </>
          )}
          {openCodeInput && (
            <>
              <Modal.Header className='start-new-header' style={{ textAlign: 'center' }}>
                {t('Invite_To_Chat_By_Ticket_ID')}
              </Modal.Header>
              <Modal.Content
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  justifyContent: 'center',
                  flexWrap: "wrap",
                }}
              >
                <Input
                  placeholder="Ticket ID"
                  className="txt-voucher-inputValue"
                  onChange={(e: any) => {
                    setTextSearchInput(e.target.value)
                  }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      onchangeTextSearchInput(textSearchInput)
                    }
                  }}
                />
                <Button
                  disabled={!textSearchInput}
                  primary
                  className="btn-connect-channel"
                  onClick={() => onchangeTextSearchInput(textSearchInput)}
                >
                  {t('Start_Label')}
                </Button>
              </Modal.Content>
            </>
          )}
        </Modal>
      )}
      {openSendInviteVisualConnect && (
        <Modal
          onClose={() => onClose()}
          open={isOpen}
          dimmer={{ className: 'test-dimmer' }}
          size={'tiny'}
          closeIcon
          closeOnDimmerClick={false}
          className='create-new-modal'
        >
          {openPhoneInput || openEmailInput ? (
            <>
              {openPhoneInput && (
                <>
                  <div className="header-color-line"></div>
                  <Modal.Header className='start-new-header' style={{ textAlign: 'center' }}>
                    {t('Invite_Sms_Question')}
                  </Modal.Header>
                  <Modal.Content>
                    <Grid>
                      <Grid.Row
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <PhoneInput
                            onChange={(e) => setPhoneNumber(e)}
                            value={phoneNumber}
                            onEnterKeyPress={(
                              e: React.KeyboardEvent<HTMLInputElement>,
                            ) => {
                              if (e.key === 'Enter') {
                                sendLinkVisualConnect(phoneNumber)
                              }
                            }}
                          />
                        </div>
                      </Grid.Row>

                      <Grid.Row
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          disabled={phoneNumber === ''}
                          onClick={() => sendLinkVisualConnect(phoneNumber)}
                          primary
                          className="button-search"
                        >
                          {t('Send_Invite_Label')}
                        </Button>
                      </Grid.Row>
                    </Grid>
                  </Modal.Content>
                </>
              )}
              {openEmailInput && (
                <>
                  <div className="header-color-line"></div>
                  <Modal.Header className='start-new-header' style={{ textAlign: 'center' }}>
                    {t('Invite_Email_Question')}
                  </Modal.Header>
                  <Modal.Content>
                    <Grid>
                      <Grid.Row
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Input
                            onChange={onChangeEmail}
                            placeholder="Type an Email"
                            onKeyPress={(
                              e: React.KeyboardEvent<HTMLInputElement>,
                            ) => {
                              if (e.key === 'Enter') {
                                sendEmailVisualConnect(email)
                              }
                            }}
                          />
                        </div>
                      </Grid.Row>

                      <Grid.Row
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          disabled={email === ''}
                          onClick={() => sendEmailVisualConnect(email)}
                          primary
                          className="button-search"
                        >
                          {t('Send_Invite_Label')}
                        </Button>
                      </Grid.Row>
                    </Grid>
                  </Modal.Content>
                </>
              )}
            </>
          ) : (
            <>
              <div className="header-color-line"></div>
              <Modal.Header className='start-new-header' style={{ textAlign: 'center' }}>
                {t('Invite_To_Visual_Question')}
              </Modal.Header>
              <Modal.Content>
                <Grid stackable columns={2}>
                  <Grid.Column className="options">
                    <Button
                      className="options--item"
                      onClick={() => setOpenPhoneInput(true)}
                    >
                      <Boschicon name={'bosch-ic-smartphone-loading-wheel'} />
                      {t('Sms_label')}
                    </Button>
                    <Button className="options--item" onClick={onHandleGenerateRandomCodeVSC}>
                      <Boschicon name={'bosch-ic-binary'} />
                      {t('Code_label')}
                    </Button>
                  </Grid.Column>
                  <Grid.Column className="options">
                    <Button
                      className="options--item"
                      onClick={() => setOpenEmailInput(true)}
                    >
                      <Boschicon name={'bosch-ic-mail'} />
                      {t('Email_label')}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Modal.Content>
            </>
          )}
        </Modal>
      )}
    </Fragment>
  )
}

export default ModalCreateNewChannel
