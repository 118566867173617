import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from 'oidc-client-ts'
import { Language } from '../models/RegionState'
import { AuthService } from '../services/AuthService'
import { getAccountSimpleAsync, logoutCallbackAsync } from './account'
import { RootState } from './store'

export interface AuthState {
  user: User | null
  isSignIn: boolean | undefined
  isSignInMS: boolean
  countryCode: string
  language: string
  domain: string
  clientId: string
  timeZoneId: string
  languages: Language[] | null
}

export const initialState: AuthState = {
  user: null,
  isSignIn: undefined,
  isSignInMS: false,
  countryCode: '',
  language: '',
  domain: '',
  clientId: '',
  timeZoneId: '',
  languages: [],
}

export const selectAuthState = (rootState: RootState) => rootState.auth
export const selectIsSignIn = (rootState: RootState) =>
  selectAuthState(rootState).isSignIn
export const selectIsSignInMS = (rootState: RootState) =>
  selectAuthState(rootState).isSignInMS
export const selectCountryCode = (rootState: RootState) =>
  selectAuthState(rootState).countryCode
export const selectTimeZoneId = (rootState: RootState) =>
  selectAuthState(rootState).timeZoneId
export const selectLanguage = (rootState: RootState) =>
  selectAuthState(rootState).language

const authReducerSlice = createSlice({
  name: 'authReducer',
  initialState: initialState,
  reducers: {
    authSetSignIn: (state, action: PayloadAction<boolean>) => {
      return { ...state, isSignIn: action.payload }
    },
    authSetSignInMS: (state, action: PayloadAction<boolean>) => {
      return { ...state, isSignInMS: action.payload }
    },
    authSetUser: (state, action: PayloadAction<User | null>) => {
      return { ...state, user: action.payload }
    },
    authSetCountryCode: (state, action: PayloadAction<AuthState>) => {
      return {
        ...state,
        countryCode: action.payload.countryCode,
        language: action.payload.language,
        domain: action.payload.domain,
        timeZoneId: action.payload.timeZoneId,
        languages: action.payload.languages,
      }
    },
    authSetLanguage: (state, action: PayloadAction<string>) => {
      let authService = AuthService.getInstance()
      const orginalState = authService.getAuthState()
      orginalState.language = action.payload
      AuthService.setAuthState(orginalState)
      return {
        ...state,
        language: action.payload,
      }
    },
    authFetchState: (state) => {
      let authService = AuthService.getInstance()
      const authState = authService.getAuthState()
      return {
        ...state,
        clientId: authState.clientId,
        countryCode: authState.countryCode,
        domain: authState.domain,
        language: authState.language,
        timeZoneId: authState.timeZoneId,
        languages: authState.languages,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountSimpleAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.value && payload.value.isSuccess) {
          state.isSignIn = true
        }
      })
      .addCase(logoutCallbackAsync.fulfilled, (state, { payload }) => {
        state.isSignIn = false
      })
  },
})

export const {
  authSetUser,
  authSetSignIn,
  authFetchState,
  authSetCountryCode,
  authSetLanguage,
  authSetSignInMS
} = authReducerSlice.actions
export default authReducerSlice.reducer
