import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { VideoTileGrid, UserActivityProvider, useMeetingManager, useAudioVideo, useActiveSpeakersState, useRosterState, RosterAttendeeType } from 'amazon-chime-sdk-component-library-react';
import './meeting.css'

import { MeetingMode } from '../../../types';
import MeetingDetails from '../../../containers/MeetingDetails';
import MeetingControls from '../../../containers/MeetingControls/MeetingControls';
import { useSelector } from 'react-redux';
import { addListAttendee, selectAttendeeState, selectListAttendeeState, selectActionMeetingState } from '../../../redux/meeting';
import { Header, Message } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { useAppDispatch } from '../../../redux/store';
import { v4 } from 'uuid';
import { DATA_MESSAGE_ACTION, DATA_MESSAGE_TYPE } from '../../../helpers/AppConstants';

type RealtimeDataCmd = "TEXT" | "WHITEBOARD"

export type RealtimeData = {
    uuid: string
    action: string
    cmd: RealtimeDataCmd
    data: any
    createdDate: number
    senderName: string
}
const MeetingView = (props: { mode: MeetingMode, mtype: string | null, isWebVersion: boolean}) => {
    const dispatchReduxToolkit = useAppDispatch()
    const meetingManager = useMeetingManager();
    const { roster } = useRosterState();
    const attendeeData = useSelector(selectAttendeeState);
    const actionMeeting = useSelector(selectActionMeetingState);
    const { t } = useTranslation();
    const attendees = Object.values(roster);
    const listAttendee = useSelector(selectListAttendeeState);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notification, setNotification] = useState<string>("");
    const audioVideo = useAudioVideo();
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isNewMemberJoined, setIsNewMemberJoined] = useState(false);

    useEffect(() => {
        function handleWindowResize() {
            setWidth(ref.current.clientWidth);
            setHeight(ref.current.clientHeight);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        setWidth(ref.current?.offsetWidth);
        setHeight(ref.current?.offsetHeight);
    }, []);

    useEffect(() => {
        // //check attendee join or leave
        if (listAttendee[0]?.chimeAttendeeId != "" && attendees.length > listAttendee.length) {
            const output = attendees.filter(({ chimeAttendeeId: id1 }) => !listAttendee.some(({ chimeAttendeeId: id2 }) => id2 === id1));
            let attendeeChimeID = output[0]?.chimeAttendeeId;
            //join
            if (attendeeData.attendeeId !== attendeeChimeID) {
                setShowNotification(true);
                setIsNewMemberJoined(true);
                setNotification("New Attendee has join in the meeting")
                setTimeout(() => {
                    setShowNotification(false)
                }, 8000);
            }
        }
        if (listAttendee[0]?.chimeAttendeeId != "" && attendees.length < listAttendee.length) {
            const output = listAttendee.filter(({ chimeAttendeeId: id1 }) => !attendees.some(({ chimeAttendeeId: id2 }) => id2 === id1));
            //leave
            setShowNotification(true);
            setNotification(`${output[0]?.name}` + " has leave out the meeting")
            setTimeout(() => setShowNotification(false), 8000);
        }

        dispatchReduxToolkit(addListAttendee(attendees));
    }, [roster]);

    useEffect(() => {
        if (!meetingManager.meetingSession) {
            return;
        }

        meetingManager.meetingSession.audioVideo.addObserver({
            audioVideoDidStop: () => {
                window.close()
            },
        })
    }, [meetingManager.meetingSession]);


    const handleMouseDown = (event: any) => {
        setIsMouseDown(true);
        sendActionMouseDown("mouse_down", event);
    };

    const handleMouseUp = (event: any) => {
        setIsMouseDown(false);
        sendActionMouseDown("mouse_up", event);
    };

    const handleResetMemberJoined = () => {
        setIsNewMemberJoined(false);
    };

    const onMove = (event: any) => {
        //Get mouse position relative to element
        let rect = event.target.getBoundingClientRect();
        let localX = event.clientX - rect.left;
        let localY = event.clientY - rect.top;
        if ((actionMeeting.AR && actionMeeting.Draw)) {
            if (isMouseDown) {
                let data = {
                    position: { x: localX, y: localY },
                    resolution: {
                        width: width,
                        height: height
                    },
                    status: "mouse_move"
                }
                hanldeDrawPoint(data)
            }
        } else if ((actionMeeting.MousePointer)) {
            let data = {
                position: { x: localX, y: localY },
                resolution: {
                    width: width,
                    height: height
                }
            }
            hanldeMouseMove(data)
        }
    };

    const getPosition = (event: any) => {
        let rect = event.target.getBoundingClientRect();
        let localX = event.clientX - rect.left;
        let localY = event.clientY - rect.top;
        let data = {
            position: { x: localX, y: localY },
            resolution: {
                width: width,
                height: height
            }
        }
        if (actionMeeting.AR) {
            if (!actionMeeting.Draw) {
                hanldeClickPoint(data)
            }
        }
    };

    const hanldeClickPoint = async (data: any) => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: DATA_MESSAGE_ACTION.Pointer,
            cmd: "TEXT",
            data: JSON.stringify(data),
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }
    const hanldeDrawPoint = async (data: any) => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: DATA_MESSAGE_ACTION.Draw,
            cmd: "TEXT",
            data: JSON.stringify(data),
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }
    const hanldeMouseMove = async (data: any) => {
        let uuid = v4();
        const mess: RealtimeData = {
            uuid: uuid,
            action: DATA_MESSAGE_ACTION.MouseMove,
            cmd: "TEXT",
            data: JSON.stringify(data),
            createdDate: new Date().getTime(),
            senderName: 'member' + uuid
        }
        audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
        return () => {
            audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
        }
    }
    const sendActionMouseDown = async (type: string, event: any) => {
        if ((actionMeeting.AR && actionMeeting.Draw)) {
            let rect = event.target.getBoundingClientRect();
            let localX = event.clientX - rect.left;
            let localY = event.clientY - rect.top;
            let data = {
                position: { x: localX, y: localY },
                resolution: {
                    width: width,
                    height: height
                },
                status: type
            }
            // console.log("status draw:=======: ", data);
            let uuid = v4();
            const mess: RealtimeData = {
                uuid: uuid,
                action: DATA_MESSAGE_ACTION.Draw,
                cmd: "TEXT",
                data: JSON.stringify(data),
                createdDate: new Date().getTime(),
                senderName: 'member' + uuid
            }
            audioVideo!.realtimeSendDataMessage(DATA_MESSAGE_TYPE.Command, JSON.stringify(mess));
            return () => {
                audioVideo!.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TYPE.Command)
            }
        }

    }

    return (
        <UserActivityProvider>
            {meetingManager.meetingSession ?
                <div className='meetingSession'>
                    <div ref={ref} className='videoStream' onClick={getPosition} onMouseMove={onMove} onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}>
                        <VideoTileGrid
                            css=''
                            layout={'standard'}
                            className="videos"
                            noRemoteVideoView={<MeetingDetails />}
                        >
                        </VideoTileGrid>
                    </div>
                    <div className='meetingControls'>
                        <MeetingControls mtype={props.mtype} isNewMemberJoined={isNewMemberJoined} resetMemberJoined={handleResetMemberJoined} isWebVersion={props.isWebVersion}/>
                    </div>

                    {showNotification &&
                        (
                            <div className='popup--notification--attendee'>
                                <Message info>
                                    <Message.Header>{t("Attendees_Label")}</Message.Header>
                                    <p>{notification}</p>
                                </Message>
                            </div>
                        )
                    }
                </div>
                :
                <Header textAlign='center'>{t("Meeting_Ended")}</Header>
            }
        </UserActivityProvider>
    );
};

export default MeetingView;
