import { GlobalStyles, MeetingProvider, useVoiceFocus, VoiceFocusProvider } from 'amazon-chime-sdk-component-library-react';
import { AudioInputDevice, Device, VoiceFocusTransformDevice } from 'amazon-chime-sdk-js';
import React, { Fragment, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { addAttendeeByRandomCode } from '../../../../redux/meeting';
import { lightTheme } from 'amazon-chime-sdk-component-library-react';
import { useParams } from "react-router-dom";
import { useAppDispatch } from '../../../../redux/store';
import { Button, Grid, Modal } from 'semantic-ui-react';
import './PublicWindowMeeting.css'
import MeetingFormPublic from '../../../../containers/MeetingFormPublic/MeetingFormPublic';
import { CommonService } from '../../../../services/Common';
import { v4 } from 'uuid';
import CustomLoader from '../../../../common-components/RDComponents/CustomLoader';

const MeetingProviderWithDeviceReplacement: React.FC = ({ children }) => {
    const { addVoiceFocus } = useVoiceFocus();

    const onDeviceReplacement = (
        nextDevice: string,
        currentDevice: AudioInputDevice
    ): Promise<Device | VoiceFocusTransformDevice> => {
        if (currentDevice instanceof VoiceFocusTransformDevice) {
            return addVoiceFocus(nextDevice);
        }
        return Promise.resolve(nextDevice);
    };

    const meetingConfigValue = {
        onDeviceReplacement,
    };

    return <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>;
};

const PublicWindowMeeting = () => {

    const dispatchReduxToolkit = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [vfConfigeValue, setVfConfigeValue] = useState<any>();
    const [displayErrorModal, setDisplayErrorModal] = useState<boolean>(false);
    const [errorModalMessage, setErrorModalMessage] = useState<string>("");
    const urlParams = new URLSearchParams(window.location.search);
    const meeting = window.location.search !== "" ? urlParams.get("sessionId") : "";
    const name = window.location.search !== "" ? urlParams.get("name") : "";
    let uuid = v4();
    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            return 'iOS';
        }
        else if (userAgent.match(/Android/i)) {
            return 'Android';
        }
        else if (userAgent.match(/Windows/i)) {
            return 'Windows';
        }
    }

    useEffect(() => {
        let appURL = "";
        let isMobile = false;
        switch (getMobileOperatingSystem()) {
            case 'Android':
                appURL = process.env.REACT_APP_MOBILE_APP_CHPLAY_URL || "";
                isMobile = true;
                break;
            case 'iOS':
                appURL = process.env.REACT_APP_MOBILE_APP_APPSTORE_URL || "";
                isMobile = true;
                break;
            case 'Windows':
                appURL = process.env.REACT_APP_REDI_PAGE || "";
                isMobile = false;
                break;
            default:
                break;
        }

        if (isMobile) {
            setTimeout(() => {
                window.location.href = appURL;
            }, 1000);
        } else {
            fetchMeetingInfor();
        }
    }, [])

    const fetchMeetingInfor = async () => {
        if (meeting) {
            setIsLoading(true);
            
            let newMemberChime = {
                ciamId: `anonymous_` + `${uuid}`,
                name: name ? name : "anonymous",
                mobile: "",
                email: ""
            }
            let newChimeUser = await CommonService.getChimeUserForPublic(newMemberChime)
            if (newChimeUser.isSuccess) {
                const attendeeReq = {
                    randomID: meeting,
                    externalUserId: newChimeUser.userId
                }
                const meetingInfor = await dispatchReduxToolkit(addAttendeeByRandomCode(attendeeReq));

                if (addAttendeeByRandomCode.fulfilled.match(meetingInfor)) {
                    console.log(meetingInfor)
                    setIsLoading(false);
                    setVfConfigeValue({
                        "spec": {
                            "name": "ns_es"
                        },
                        "createMeetingResponse": meetingInfor.payload.dataObject.createMeetingResponse
                    })

                }
                if (addAttendeeByRandomCode.rejected.match(meetingInfor)) {
                    setTimeout(() => {
                        setIsLoading(false);
                        setErrorModalMessage("Meeting ID is not exist.");
                        setDisplayErrorModal(true);
                    }, 2000);
                };
            }else{
                setTimeout(() => {
                    setIsLoading(false);
                    setErrorModalMessage("Cannot create new user in PCS. Please try again!");
                    setDisplayErrorModal(true);
                }, 2000);
            }


        }
    }

    const onTrigger = () => {
        setDisplayErrorModal(false);
    };

    return (

        <ThemeProvider theme={lightTheme}>
            <GlobalStyles />
            <div
                className="grid-responsive-width meetingroom"
            >
                {isLoading &&
                    <Fragment>
                        <CustomLoader enable={isLoading}></CustomLoader>
                    </Fragment>}
                {vfConfigeValue && <VoiceFocusProvider {...vfConfigeValue}>
                    <MeetingProviderWithDeviceReplacement>
                        <MeetingFormPublic />
                    </MeetingProviderWithDeviceReplacement>
                </VoiceFocusProvider>}

            </div>
            <Fragment>
                <Modal onClose={onTrigger} open={displayErrorModal} dimmer={{ className: "test-dimmer" }} size={"mini"} closeIcon>
                    <Modal.Content>
                        <Grid divided="vertically">
                            <Grid.Row>
                                <label style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    Error
                                </label>
                            </Grid.Row>
                            {errorModalMessage && (
                                <Grid.Row style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    {errorModalMessage}
                                </Grid.Row>
                            )}
                            <Grid.Row>
                                <Button className="button-search" style={{ width: "100%" }} onClick={onTrigger}>Close</Button>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                </Modal>
            </Fragment>
        </ThemeProvider>
    );
};
export default PublicWindowMeeting;
