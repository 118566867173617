import * as signalR from "@microsoft/signalr";
import EventEmitter from 'events';

const URL =
  process.env.REACT_APP_PCS_SOCKET_SIGNALR ??
  "SIGNALR_HUB_ADDRESS is not provided";

class SignalRService {
  public connection: signalR.HubConnection;
  private static instance: SignalRService;
  private eventEmitter: EventEmitter;
  private connectionId: string = "";

  private constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL, {
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => 5000, // Retry every 1 second
      })
      .build();
    this.eventEmitter = new EventEmitter();
    this.setupConnection();
  }

  public static getInstance(): SignalRService {
    if (!SignalRService.instance) {
      SignalRService.instance = new SignalRService();
    }
    return SignalRService.instance;
  }

  public onConnectionEstablished(callback: () => void) {
    this.eventEmitter.on("connectionEstablished", callback);
  }

  public setupConnection() {
    this.connection.onreconnected(() => {
      console.log("Connection reestablished: ", this.connection.connectionId);
      this.registerEvents();
      this.eventEmitter.emit("connectionEstablished");
    });

    this.connection
      .start()
      .then(() => {
        console.log("Connection established: ", this.connection.connectionId);
      })
      .catch((error: any) => {
        console.error("Connection failed:", error);
      });
  }

  public setupEmitter() {
    console.log("Event Emitter established: ", this.connection.connectionId);
    this.registerEvents();
    this.eventEmitter.emit("connectionEstablished");
  }

  private registerEvents = () => {
    this.connection.on("ReceiveSignal", (data: string) => {
      this.eventEmitter.emit("registerMessageHandler", JSON.parse(data));
    });
  };

  public async sendMessageToGroup(methodName: string, group: string, message: string): Promise<void> {
    try {
      if (this.connection && this.connection.state === "Connected") {
        await this.connection.invoke(methodName, group, message);
      }
    } catch (error) {
      console.error("error=========: ", error);
    }
  }

  public onRegisterMessageHandler(
    callback: (message: any) => void
  ) {
    this.eventEmitter.on("registerMessageHandler", callback);
  }

  public joinGroup(groupName: string) {
    return this.connection.invoke("JoinGroup", groupName);
  };
}

export default SignalRService;