export const TRANSLATIONS_FR = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Nouvelle session",
	Start_New_Question: "Comment souhaitez-vous démarrer une nouvelle session?",
	Sms_label: "SMS",
	Email_label: "Email",
	Ticket_Id_Label: "N° de ticket",
	Code_label: "Code",
	Channel_List: "Liste des canaux",
	Chat_Label: "Chat",
	Call_Label: "Appel",
	Video_Label: "Video",
	Share_Label: "Partager",
	Stop_Channel_Label: "Arrêter le canal",
	Contact_Details_Label: "Coordonnées",
	Request_Details_Label: "Demande de coordonées",
	Attendees_Label: "Participants",
	Customer_Label: "Client",
	Bosch_Expert_Label: "Expert Bosch",
	Type_Your_Message_Label: "Saisissez votre message",
	Stop_Channel_Question: "Voulez-vous clôturer le canal?",
	Yes_Label: "Oui",
	Cancel_Label: "Annuler",
	Start_New_Video_Session: "Démarrez une nouvelle session video",
	Without_SMS_Label: "Sans SMS",
	Send_SMS_Label: "Envoi SMS",
	Send_Invite_Label: "Envoi invitation",
	Log_Out_Question: "Voulez-vous vous déconnecter?",
	Welcome_Label: "Bienvenu dans le Private Channel Solution",
	Login_Label: "Connexion",
	Login_Is_Required_Label: "Une connexion est nécessaire pour démarrer le canal",
	Account_Permission_Label: "Ce compte n'a pas la permission de démarrer un canal",
	Start_Label: "Démarrer",
	Noted_Recommend: "En raison de la politique de Bosch concernant l'utilisation de Firefox, nous vous recommandons d'utiliser un autre navigateur pour profiter au mieux de l'application Private Channel Solution. Navigateurs recommandés : Microsoft Edge et Google Chrome",
	Available_Languages_Label: "Langues disponibles",
	Can_Not_Create_Meeting_Title: "Il n'est pas possible de démarrer une nouvelle session",
	Can_Not_Create_Meeting_Content: "Vous êtes toujours actif dans un canal privé. Veuillez mettre un terme à ce canal avant d'en lancer un nouveau.",
	Leave_meeting_Warning: "Quittez le canal privé ou mettez fin au canal pour tous. Le canal ne peut plus être utilisé une fois cloturé.",
	Leave_Lable: "Quitter",
	End_Lable: "Clôturer",
	Meeting_Ended: "La session est terminée",
	Start_Channel_Session: "Démarrer session",
	Start_Channel_Session_Content: "Le technicien est actuellement occupé avec une autre session dont le N° de ticket est :",
	Join_Lable: "Participer",
	Join_Meeting_Label: "Participer à la session",
	Meeting_Information_Label: "Information sur la session",
	Meeting_ID_Label: "N° de session",
	Translate_Option_Title: "Option traduction",
	Translate_Mode_Label: "Mode traduction",
	From_Label: "De :",
	To_Label: "A :",
	Chat_Room_Label: "Session privée",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Comment souhaitez-vous partager l'invitation à la session privée?",
	Invite_To_Visual_Question: "Comment souhaitez-vous partager l'invitation à Visual Connect ?",
	Invite_Sms_Question: "Envoyer invitation par SMS",
	Invite_To_Chat_By_Ticket_ID: "Envoyer invitation via le N° de ticket",
	Invite_Email_Question: "Envoyer invitation par mail",
	Link_label: "Lien",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Applications",
	WSA_Remote_Diagnostics_label: "Diagnostic à distance",
	WSA_Service_Training_label: "Formation",
	WSA_Enter_website_label: "Ouvrir site internet",
	WSA_Slogan_label: "Des technologies pour la vie",
	WSA_contact_info_label: "Coordonnées générales",
	WSA_PSIRT_label: "Sécurité des produits",
	WSA_licences_label: "Innovations, brevets et licences",
	WSA_purchasing_label: "Achats et logistique",
	WSA_copyright_label: "© Robert Bosch GmbH, tous droits préservés",
	WSA_copyright_information_label: "Informations sur l'entreprise",
	WSA_copyright_notice_label: "Mentions légales",
	WSA_copyright_protectionnotice_label: "Avis relatif à la protection des données",
	WSA_copyright_terms_label: "Conditions générales d'utilisation",
	WSA_copyright_settings_label: "Paramètres de confidentialité",
	Infor_CorporateInformation_Description: "Responsable des pages internet de Robert Bosch Gmbh",
	Infor_DataProtectionNotice_Description: "Avis de protection des données pour le service de diagnostic à distance de Bosch",
	Infor_LegalNotice_Description: "Pour les utilisateurs des pages Internet de Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Sujet",
	Make_panel: "Marque",
	Model_pane: "Modèle",
	Engine_code_panel: "Code moteur",
	Year_panel: "Année de construction",
	Vin_panel: "N° de châssis",
	Plate_panel: "N° d'immatriculation",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Votre appareil n'est pas disponible pour AR",
	Write_something: "Lancez le chat en écrivant quelque chose",
	Session_title: "Sessions",
	Live_Translation: "Traduction instantanée",
	Enabled_panel: "Active",
	Joined_note: "Vous avez été ajouté à la session",
	Today_panel: "Aujourd'hui",
	Read_panel: "Lui",
	Sent_panel: "Envoyé",
	Dis_translation: "Désactiver la traduction",
	En_translation: "Activer la traduction",
	Terminated_Channel_note: "L'expert Bosch a cloturé le canal !",
	Not_start_note: "n'a pas encore commencé !",
	Retry_note: "Veuillez cliquer sur \"Réessayer\" pour réessayer ou fermer cette fenêtre pour quitter.",
	Retry_panel: "Réessayer",
	Thanks_note: "Merci d'avoir fait appel aux Services Technique Bosch. Je suis très heureux que nous ayons pu vous aider aujourd'hui. N'hésitez pas à nous recontacter si vous avez besoin d'aide. Je vous souhaite une excellente journée.",
	Continue_panel: "Activer la traduction instantanée",
	En_live_translate: "Continuer",
	Translate_from: "Traduit de",
	Translate_to: "Traduit par",
	Random_ID: "ID aléatoire",
	Anonymous: "Anonyme",
	Generated_ID: "ID aléatoire a été généré !",
	Your_ID: "Votre ID aléatoire est :",
	Public_Meeting_ID_Label: "ID de la réunion publique :",
	SelectContactPersonModal_Title: "Avec qui souhaitez-vous discuter ?",
	SelectContactPersonDropDown_Title: "Personne de contact",
	SelectContactPersonDropDown_Error_Message: "Cet utilisateur n'a pas d'ID CIAM (compte utilisateur pour CDM), veuillez choisir une autre personne de contact pour continuer ... (créateur du ticket)",
	TicketCreator__Title: "Statut WSA",
	Wsa_status_title: "Fermer",
	Close_panel: "Reconnaissance optique de caractères détectée :",
	OCR_Detected_title: "Appel vocal",
	Voice_Call_Label: "Comment souhaitez-vous partager l'invitation à l'appel vocal ?",
	Invite_To_Voice_Call_Question: "MODE AR",
	AR_mode: "Changer",
	Switch_modal_title: "Passage à l'appel vidéo réussi !",
	Switch_modal_content_Video: "Passage à l'appel vocal réussi !",
	Switch_modal_content_Voice: "Appel vidéo",
	Video_Call_Label: "Fonctionnalité",
	Functionality_Label: "Appuyez sur le bouton de discussion pour démarrer une salle de discussion",
	Press_Chatting_Button_Label: "Applications de services d'atelier",
	WSA_Applications_label_full: "Entrer dans l'application",
	WSA_Applications_item_card: "Assistance diagnostique",
	WSA_Applications_item_card_diagnostic_support: "Diagnostics à distance",
	WSA_Applications_item_card_remote_diagnostics: "Formation au service",
	WSA_Applications_item_card_service_training: "Support technique",
	WSA_Applications_item_card_technical_support: "Paramètres",
	Header_Label_Settings: "Assistant",
	Assistant_Label_Bot: "Bonjour, comment puis-je vous aider aujourd'hui ?",
	Assistant_first_message: "Aujourd'hui",
	Assistant_today_label: "Visual Connect Pro",
	Visual_Connect_Pro_btn: "Rejoindre la discussion",
	Join_chat_btn: "Votre nom",
	Your_name_input_label: "Code",
	Code_input_label: "Allons-y",
	Start_btn_label: "Modifier les informations",
	Change_info_label: "Modifier le nom",
	Change_name_label: "Modifier le code",
	Change_code_label: "Avant de commencer, puis-je connaître votre nom, s'il vous plaît ?",
	assistant_ask_name_message: "Veuillez entrer votre code pour rejoindre la session",
	assistant_ask_code_message: "Veuillez choisir l'élément que vous souhaitez modifier",
	assistant_ask_change_message: "Veuillez taper votre nom",
	assistant_ask_change_name_message: "Veuillez taper le code",
	assistant_ask_change_code_message: "Veuillez sélectionner le type d'action",
	assistant_ask_change_action_message: "J'ai reçu vos informations : \n Votre code : [code] \n Votre nom : [nom] \n Souhaitez-vous continuer ?",
	assistant__confirm_message: "Bienvenue sur Tessa Bot !",
	Assistant_Welcome_Bot: "Êtes-vous sûr de vouloir mettre fin à la réunion ? La réunion ne pourra plus être utilisée une fois terminée.",
	End_meeting_Warning: "Vous êtes sur le point de mettre fin à l'appel, cliquez ici pour continuer",
	Leave_meeting_meesage_Warning: "L'utilisateur n'autorise pas l'activation de la caméra",
	Reject_Switch_modal_content_Voice: "Modifier le type d'action",
	Change_action_label: "Agent Bosch",
	BoschExpert_Label_Sender: "Veuillez fermer la fenêtre modale puis réessayer ou changer le code",
	Retry_note_v2: "Le canal est terminé !",
	End_Channel_note: "Le technicien a quitté le canal !",
	Leave_Channel_note: "Le technicien a rejoint le canal !",
	Join_Channel_note: "La traduction est disponible !",
	Translation_is_available: "Nous avons détecté que votre langue est [tech_language] et que la langue des experts Bosch est [expert_language]. \n Souhaitez-vous activer la traduction en direct ?",
	Translation_is_available_detail: "Non",
	No_Label: "Réduire",
	Minimize_Label: "Agrandir",
	Maximize_Label: "Fermer la session",
	Close_Session_Label: "Avertissement",
	Popup_Title_Warning: "Cliquez ici pour afficher l'historique complet de cette conversation",
	Show_Message_Label: "Cliquez ici pour masquer l'historique de la discussion",
	Hide_Message_label: "Voulez-vous fermer cette session ?",
	Close_Channel_Message_Question: "[nom] a rejoint le canal.",
	Channel_noti_joined_message: "Cliquez ici pour afficher l'historique de la discussion",
	Click_to_view_history_message: "En ligne",
	Online_Label: "Hors ligne",
	Offline_Label: "En attente",
	Onhold_Label: "Occupé",
	Busy_Label: "OK",
	OK_Label: "Notification de canal",
	Channel_Notification: "Il semble que l'agent Bosch soit hors ligne depuis un certain temps. Vous pouvez continuer d'attendre ou quitter cette conversation.",
	BE_Leave: "Chargement de la discussion...",
	Chat_LoadingChat_Message: "Oups, quelque chose a mal tourné !"
};