export const TRANSLATIONS_NN = {
	Noted_Recommend: "På grunn av Boschs retningslinjer for bruk av Firefox, anbefaler vi at du bruker en annen nettleser for å få den beste brukeropplevelsen av Private Channel Solution-applikasjonen. Anbefalte nettlesere: Microsoft Edge og Google Chrome",
	Available_Languages_Label: "Tilgjengelige språk",
	Can_Not_Create_Meeting_Title: "Kan ikke opprette ny privat kanal",
	Can_Not_Create_Meeting_Content: "Du er fortsatt i en aktiv privat kanal. Avslutt gjeldende kanal før du starter en ny.",
	Leave_meeting_Warning: "Gå ut av den private kanalen, eller du kan avslutte kanalen for alle. Kanalen kan ikke brukes når den avsluttes.",
	Leave_Lable: "Forlat",
	End_Lable: "Avslutt",
	Meeting_Ended: "Møtet er avsluttet",
	Start_Channel_Session: "Start kanal",
	Start_Channel_Session_Content: "Teknikeren er for øyeblikket i gang med Ticket ID:",
	Join_Lable: "Delta",
	Join_Meeting_Label: "Delta i kanal",
	Meeting_Information_Label: "Kanalinformasjon",
	Meeting_ID_Label: "Kanal ID",
	Translate_Option_Title: "Oversettelsesaltertnativ",
	Translate_Mode_Label: "Oversettelse:",
	From_Label: "Fra:",
	To_Label: "Til:",
	Chat_Room_Label: "Privat Kanal",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "Bosch ID",
	WSA_label: "Workshop Service Assist",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_copyright_label: "© Robert Bosch GmbH, all rights reserved",
	Id_panel: "Id",
	Topic_panel: "Emne",
	Make_panel: "Merke",
	Model_pane: "Modell",
	Engine_code_panel: "Motorkode",
	Year_panel: "Årsmodell",
	Vin_panel: "VIN",
	Plate_panel: "Skiltnr.",
	Hardware_panel: "Hardware",
	Today_panel: "Idag",
	Read_panel: "Lest",
	Sent_panel: "Sendt",
	Dis_translation: "Deaktiver oversettelse",
	En_translation: "Oversett",
	Continue_panel: "Aktiver oversettelse",
	En_live_translate: "Fortsett",
	Translate_from: "Oversett fra",
	Translate_to: "Oversett til"
};