import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export const senderENUM = {
    assistant: 1,
    user: 2,
    boschExpert: 3,
    system: 4,
    member: 5,
    channel: 6
}
export const stepENUM = {
    chooseAction: 1,
    typeName: 2,
    typeIdNumber: 3,
    typeStart: 4,
    typeEdit: 5,
    typeEditName: 6,
    typeEditCode: 7,
    goBackToFirstStep: 8
}

export const senderNameType = {
    technician: "adfs.anonymous",
    boschExper: "@bosch.com"
}

export const funtionalTypeENUM = {
    vsc: 1,
    chatting: 2,
    start: 3,
    change: 4,
    changeName: 5,
    changeCode: 6,
    changeAction: 7
}

export const actionTypeENUM = {
    chooseOption: 1,
    inputText: 2
}

interface chimeUserModal {
    username: string,
    userId: string
}
interface presenceUsersSate {
    name: string,
    userArn: string,
    status: string,
    updatedTimeStamp: string
}

export interface AssistantBotState {
    username: string;
    messageList: messageListModal[];
    onAState: boolean;
    showLoadingDot: boolean,
    stepAction: number,
    selectedFeature: selectedFeatureModal | null,
    onStartAChatChannel: boolean,
    userChime: chimeUserModal | null,
    chatChannelHistory: any,
    onSaveHistoryMessages: boolean
    presenceOfUserInPublicChannel: presenceUsersSate[] | [],
}

export interface messageListModal {
    sender: number,
    message: string,
    datetime: string,
    metaData?: string,
    messageId?: string,
    senderName?: string,
    senderArn?: string
}

export interface selectedFeatureModal {
    type: number | undefined,
    name: string,
    code: string,
}

export const initialAssistantBotState: AssistantBotState = {
    username: "",
    messageList: [],
    onAState: false,
    showLoadingDot: true,
    stepAction: stepENUM.chooseAction,
    selectedFeature: null,
    onStartAChatChannel: false,
    userChime: null,
    chatChannelHistory: null,
    onSaveHistoryMessages: false,
    presenceOfUserInPublicChannel: []
}

export const selectAssistantState = (rootState: RootState) => rootState.assistant;
export const selectAssistantMessageListState = (rootState: RootState) => rootState.assistant.messageList;
export const selectActiveState = (rootState: RootState) => rootState.assistant.onAState;
export const selectShowLoadingDot = (rootState: RootState) => rootState.assistant.showLoadingDot;
export const selectStepAction = (rootState: RootState) => rootState.assistant.stepAction;
export const selectFeatureType = (rootState: RootState) => rootState.assistant.selectedFeature;
export const selectOnStartAChatChannel = (rootState: RootState) => rootState.assistant.onStartAChatChannel;
export const selectUserChime = (rootState: RootState) => rootState.assistant.userChime;
export const selectChatChannelHistory = (rootState: RootState) => rootState.assistant.chatChannelHistory;
export const selectOnSaveHistoryMessages = (rootState: RootState) => rootState.assistant.onSaveHistoryMessages;
export const selectPresenceOfUserInPublicChannel = (rootState: RootState) => rootState.assistant.presenceOfUserInPublicChannel;

const assistantBotReducerSlice = createSlice({
    name: 'assistantBotReducer',
    initialState: initialAssistantBotState,
    reducers: {
        addMessageItem: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                messageList: state.messageList.concat(action.payload)
            }
        },
        setActiveState: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                onAState: action.payload
            }
        },
        setShowLoadingDot: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                showLoadingDot: action.payload
            }
        },
        setStepState: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                stepAction: action.payload
            }
        },
        setOnStartAChatChannel: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                onStartAChatChannel: action.payload
            }
        },
        setUserChime: (state, action: PayloadAction<chimeUserModal>) => {
            return {
                ...state,
                userChime: action.payload
            }
        },
        setSelectedFeatureState: (state, action: PayloadAction<selectedFeatureModal>) => {
            return {
                ...state,
                selectedFeature: action.payload
            }
        },
        setChatChannelHistory: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                chatChannelHistory: action.payload
            }
        },
        setOnSaveHistoryMessages: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                onSaveHistoryMessages: action.payload
            }
        },
        clearAssistantState: () => {
            return {
                username: "",
                messageList: [],
                onAState: false,
                showLoadingDot: true,
                stepAction: stepENUM.chooseAction,
                selectedFeature: null,
                onStartAChatChannel: false,
                userChime: null,
                onDetectedMessageLanguage: false,
                chatChannelHistory: null,
                onSaveHistoryMessages: false,
                presenceOfUserInPublicChannel: []
            }
        },
        clearStateAfterEndChannel: (state) => {
            return {
                ...state,
                messageList: [],
                onAState: false,
                showLoadingDot: true,
                stepAction: stepENUM.chooseAction,
                onStartAChatChannel: false,
                onDetectedMessageLanguage: false,
                chatChannelHistory: null,
                onSaveHistoryMessages: false,
                presenceOfUserInPublicChannel: []
            }
        },
        setPresenceOfUserInPublicChannel: (state, action: PayloadAction<any>) => {
            let mappingArray: presenceUsersSate[] = [...current(state.presenceOfUserInPublicChannel)];
            let message = action.payload;
            let newUpdated: presenceUsersSate = {
                name: "",
                userArn: "",
                status: "",
                updatedTimeStamp: ""
            }
            newUpdated.name = message.Sender.Name;
            newUpdated.status = message.Content.toLowerCase();
            newUpdated.updatedTimeStamp = message.CreatedTimestamp;
            newUpdated.userArn = message.Sender.Arn;
            if (mappingArray) {
                const index = mappingArray.findIndex(obj => obj.userArn === message.Sender.Arn && obj.name === message.Sender.Name);
                if (index !== -1) {
                    mappingArray[index] = newUpdated;
                } else {
                    mappingArray.push(newUpdated);
                }
            }
            return {
                ...state,
                presenceOfUserInPublicChannel: mappingArray
            }
        },
    }
});

export const { addMessageItem, clearAssistantState, clearStateAfterEndChannel, setActiveState, setShowLoadingDot, setStepState, setSelectedFeatureState, setOnStartAChatChannel, setUserChime, setChatChannelHistory, setOnSaveHistoryMessages, setPresenceOfUserInPublicChannel } = assistantBotReducerSlice.actions;
export default assistantBotReducerSlice.reducer;