export const TRANSLATIONS_LT = {
	Logo_Title_Label: "Privataus kanalo sprendimas",
	Service_Assist_Label: "Aptarnavimo asistentas",
	Start_New_LabelBtn: "Atidaryti Naują",
	Start_New_Question: "Kaip norėtumėte pradėti naują Privatų kanalą?",
	Sms_label: "SMS",
	Email_label: "El. paštas",
	Ticket_Id_Label: "Bilieto ID",
	Code_label: "Kodas",
	Channel_List: "Kanalų sąrašas",
	Chat_Label: "Pokalbis",
	Call_Label: "Skambinti",
	Video_Label: "Video",
	Share_Label: "Dalintis",
	Stop_Channel_Label: "Sustabdyti kanalą",
	Contact_Details_Label: "Kontaktiniai duomenys",
	Request_Details_Label: "Prašyti išsamios informacijos",
	Attendees_Label: "Dalyviai",
	Customer_Label: "Klientas",
	Bosch_Expert_Label: "Bosch ekspertas",
	Type_Your_Message_Label: "Įveskite savo pranešimą",
	Stop_Channel_Question: "Ar norite uždaryti kanalą?",
	Yes_Label: "Taip",
	Cancel_Label: "Atšaukti",
	Start_New_Video_Session: "Pradėkite naują vaizdo sesiją",
	Without_SMS_Label: "Be SMS",
	Send_SMS_Label: "Siųsti SMS",
	Send_Invite_Label: "Siųsti pakvietimą",
	Log_Out_Question: "Ar norite atsijungti?",
	Welcome_Label: "Sveiki atvykę į Privataus kanalo sprendimą",
	Login_Label: "Prisijungti",
	Login_Is_Required_Label: "Norint paleisti kanalą reikia prisijungti",
	Account_Permission_Label: "Ši paskyra neturi leidimo pradėti kanalo",
	Start_Label: "Pradėti",
	Noted_Recommend: "Atsižvelgiant į „Bosch“ taikomą „Firefox“ naudojimo politiką, rekomenduojame naudoti kitą naršyklę, kad gautumėte geriausią „Private Channel Solution“ programos naudojimo patirtį. Rekomenduojamos naršyklės: Microsoft Edge ir Google Chrome",
	Available_Languages_Label: "Galimos kalbos",
	Can_Not_Create_Meeting_Title: "Nepavyko sukurti naujo privataus kanalo",
	Can_Not_Create_Meeting_Content: "Vis dar esate aktyviame privačiame kanale. Užbaikite esamą kanalą prieš pradėdami naują.",
	Leave_meeting_Warning: "Išeikite iš privataus kanalo arba galite uždaryti kanalą visiems. Kanalas negali būti naudojamas jam pasibaigus.",
	Leave_Lable: "Palikti",
	End_Lable: "Pabaiga",
	Meeting_Ended: "Susitikimas baigtas",
	Start_Channel_Session: "Pradėti kanalo sesiją",
	Start_Channel_Session_Content: "Technikas šiuo metu dirba su kitu bilieto ID:",
	Join_Lable: "Prisijunkite",
	Join_Meeting_Label: "Prisijunkite prie kanalo",
	Meeting_Information_Label: "Kanalo informacija",
	Meeting_ID_Label: "Kanalo ID",
	Translate_Option_Title: "Vertimo parinktis",
	Translate_Mode_Label: "Vertimo režimas:",
	From_Label: "Iš:",
	To_Label: "Kam:",
	Chat_Room_Label: "Privatus kanalas",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Kaip norėtumėte bendrinti privataus kanalo kvietimą?",
	Invite_To_Visual_Question: "Kaip norėtumėte bendrinti „Visual Connect“ kvietimą?",
	Invite_Sms_Question: "Kvietimą siųsti SMS žinute",
	Invite_To_Chat_By_Ticket_ID: "Siųsti kvietimą pagal bilieto ID",
	Invite_Email_Question: "Siųsti kvietimą el. paštu",
	Link_label: "Nuoroda",
	WSA_label: "Dirbtuvių aptarnavimo asistentas",
	WSA_Applications_label: "Programos",
	WSA_Remote_Diagnostics_label: "Nuotolinė diagnostika",
	WSA_Service_Training_label: "Techniniai mokymai",
	WSA_Enter_website_label: "Įveskite svetainę",
	WSA_Slogan_label: "Išrasta gyvenimui",
	WSA_contact_info_label: "Bendra kontaktinė informacija",
	WSA_PSIRT_label: "Produkto sauga (PSIRT)",
	WSA_licences_label: "Inovacijos, patentai ir licencijos",
	WSA_purchasing_label: "Pirkimas ir logistika",
	WSA_copyright_label: "© Robert Bosch GmbH, visos teisės saugomos",
	WSA_copyright_information_label: "Įmonės informacija",
	WSA_copyright_notice_label: "Teisinis įspėjimas",
	WSA_copyright_protectionnotice_label: "Duomenų apsaugos pranešimas",
	WSA_copyright_terms_label: "Sąlygos ir nuostatos",
	WSA_copyright_settings_label: "Privatumo nustatymai",
	Infor_CorporateInformation_Description: "Atsakingas už Robert Bosch GmbH internetinius puslapius",
	Infor_DataProtectionNotice_Description: "„Bosch Remote Diagnostics Service“ duomenų apsaugos pranešimas",
	Infor_LegalNotice_Description: "Robert Bosch GmbH interneto puslapių naudotojams",
	Id_panel: "Id",
	Topic_panel: "Tema",
	Make_panel: "Gamintojas",
	Model_pane: "Modelis",
	Engine_code_panel: "Variklio kodas",
	Year_panel: "Metai",
	Vin_panel: "Vin",
	Plate_panel: "Valst. numeris",
	Hardware_panel: "Aparatūra",
	ar_mode_warning: "Jūsų įrenginys nepalaiko papildytos realybės",
	Write_something: "Parašykite ką nors, kad pradėtumėte pokalbį",
	Session_title: "Sesija",
	Live_Translation: "Tiesioginis vertimas",
	Enabled_panel: "Įjungtas",
	Joined_note: "Jūs prisijungėte prie sesijos…",
	Today_panel: "Šiandien",
	Read_panel: "Skaityti",
	Sent_panel: "Išsiųsta",
	Dis_translation: "Išjungti vertimą",
	En_translation: "Įjungti vertimą",
	Terminated_Channel_note: "„Bosch Expert“ nutraukė kanalą!",
	Not_start_note: "dar neprasidėjo!",
	Retry_note: "Spustelėkite „Bandyti dar kartą“, kad bandytumėte dar kartą, arba uždarykite šį langą, kad išeitumėte.",
	Retry_panel: "Bandykite dar kartą",
	Thanks_note: "Dėkojame, kad naudojatės „Bosch“ techninėmis paslaugomis. Labai džiaugiuosi, kad šiandien turėjome galimybę jums padėti. Jei reikia pagalbos, susisiekite su mumis dar kartą. Geros dienos.",
	Continue_panel: "Įgalinti tiesioginį vertimą",
	En_live_translate: "Tęsti",
	Translate_from: "Išversti iš",
	Translate_to: "Išversti į",
	Random_ID: "Atsitiktinis ID",
	Anonymous: "Anoniminis",
	Generated_ID: "Atsitiktinis ID buvo sugeneruotas!",
	Your_ID: "Jūsų atsitiktinis ID yra:",
	Public_Meeting_ID_Label: "Viešo susirinkimo ID:",
	SelectContactPersonModal_Title: "Viešojo susitikimo ID",
	SelectContactPersonDropDown_Title: "Su kuo norėtumėte pasikalbėti?",
	SelectContactPersonDropDown_Error_Message: "Kontaktinis asmuo",
	TicketCreator__Title: "Šis vartotojas neturi CIAM ID (vartotojo paskyros CDM), pasirinkite kitą kontaktinį asmenį, kad tęstumėte...",
	Wsa_status_title: "(bilieto kūrėjas)",
	Close_panel: "WSA būklė",
	OCR_Detected_title: "Uždaryti",
	Voice_Call_Label: "Optinio simbolių atpažinimo nustatymas:",
	Invite_To_Voice_Call_Question: "Balso skambutis",
	AR_mode: "Kaip norėtumėte pasidalinti balso skambučio kvietimu?",
	Switch_modal_title: "AR režimas",
	Switch_modal_content_Video: "Perjungti",
	Switch_modal_content_Voice: "Vaizdo skambučio perjungimas sėkmingas!",
	Video_Call_Label: "Balso skambučio perjungimas sėkmingas!",
	Functionality_Label: "Vaizdo skambutis",
	Press_Chatting_Button_Label: "Funkcionalumas",
	WSA_Applications_label_full: "Paspauskite pokalbio mygtuką norėdami pradėti pokalbių kambarį",
	WSA_Applications_item_card: "Dirbtuvių paslaugų programos",
	WSA_Applications_item_card_diagnostic_support: "Įveskite programą",
	WSA_Applications_item_card_remote_diagnostics: "Diagnostikos palaikymas",
	WSA_Applications_item_card_service_training: "Nuotolinė diagnostika",
	WSA_Applications_item_card_technical_support: "Paslaugų mokymai",
	Header_Label_Settings: "Techninė pagalba",
	Assistant_Label_Bot: "Nustatymai",
	Assistant_first_message: "Asistentas",
	Assistant_today_label: "Sveiki, kaip galiu jums padėti šiandien?",
	Visual_Connect_Pro_btn: "Šiandien",
	Join_chat_btn: "„Visual Connect Pro“",
	Your_name_input_label: "Prisijungti prie pokalbio",
	Code_input_label: "Jūsų vardas",
	Start_btn_label: "Kodas",
	Change_info_label: "Pradėkime",
	Change_name_label: "Keisti informaciją",
	Change_code_label: "Keisti vardą",
	assistant_ask_name_message: "Keisti kodą",
	assistant_ask_code_message: "Prieš pradėdami, ar galiu sužinoti jūsų vardą?",
	assistant_ask_change_message: "Įveskite savo kodą, kad prisijungtumėte prie sesijos",
	assistant_ask_change_name_message: "Pasirinkite elementą, kurį norite pakeisti",
	assistant_ask_change_code_message: "Įveskite savo vardą",
	assistant_ask_change_action_message: "Įveskite kodą",
	assistant__confirm_message: "Pasirinkite veiksmų tipą",
	Assistant_Welcome_Bot: "Gavau jūsų informaciją: \n Jūsų kodas: [code] \n Jūsų vardas: [name] \n Ar norėtumėte tęsti?",
	End_meeting_Warning: "Sveiki atvykę į „Tessa Bot“!",
	Leave_meeting_meesage_Warning: "Ar tikrai norite užbaigti susitikimą? Susitikimo nebus galima naudoti pasibaigus.",
	Reject_Switch_modal_content_Voice: "Jūs ketinate baigti skambutį, spustelėkite čia, kad tęstumėte",
	Change_action_label: "Vartotojas neleidžia įjungti kameros",
	BoschExpert_Label_Sender: "Keisti veiksmų tipą",
	Retry_note_v2: "Bosch atstovas",
	End_Channel_note: "Užverkite modalą ir bandykite dar kartą arba pakeiskite kodą",
	Leave_Channel_note: "Kanalo sesija baigėsi!",
	Join_Channel_note: "Technikas paliko kanalą!",
	Translation_is_available: "Technikas prisijungė prie kanalo!",
	Translation_is_available_detail: "Vertimas yra prieinamas!",
	No_Label: "Aptikome, kad jūsų kalba yra [tech_language], o Bosch ekspertų kalba yra [expert_language]. \n Ar norėtumėte įjungti tiesioginį vertimą?",
	Minimize_Label: "Ne",
	Maximize_Label: "Sumažinti",
	Close_Session_Label: "Padidinti",
	Popup_Title_Warning: "Uždaryti sesiją",
	Show_Message_Label: "Įspėjimas",
	Hide_Message_label: "Spustelėkite čia norėdami peržiūrėti visą šio pokalbio istoriją",
	Close_Channel_Message_Question: "Spustelėkite čia norėdami paslėpti pokalbio istoriją",
	Channel_noti_joined_message: "Ar norite uždaryti šią sesiją?",
	Click_to_view_history_message: "[name] prisijungė prie kanalo.",
	Online_Label: "Spustelėkite čia norėdami peržiūrėti pokalbių istoriją",
	Offline_Label: "Prisijungęs",
	Onhold_Label: "Atsijungęs",
	Busy_Label: "Laikinas laukimas",
	OK_Label: "Užimta",
	Channel_Notification: "Gerai",
	BE_Leave: "Kanalo pranešimas",
	Chat_LoadingChat_Message: "Atrodo, kad „Bosch“ agentas kurį laiką buvo neprisijungęs. Galite toliau laukti arba palikti šį pokalbį.",
	Popup_Title_Error: "Kraunasi pokalbis....."
};