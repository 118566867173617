import React, { useState } from 'react';
import {
  PreviewVideo,
  QualitySelection,
  CameraSelection,
  Label,
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';

const CameraDevices = () => {

  return (
    <div>
      <CameraSelection />
      <QualitySelection />
      <Label style={{ display: 'block', marginBottom: '.5rem' }}>
        Video preview
      </Label>
      <PreviewVideo />
    </div>
  );
};

export default CameraDevices;