export const TRANSLATIONS_IT = {
	Logo_Title_Label: "Chat",
	Service_Assist_Label: "Servizio Assistenza",
	Start_New_LabelBtn: "Nuova chat",
	Start_New_Question: "Vuoi avviare una nuova chat?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Codice",
	Channel_List: "Elenco chat",
	Chat_Label: "Chat",
	Call_Label: "Chiama",
	Video_Label: "Video",
	Share_Label: "Condividi",
	Stop_Channel_Label: "Interrompi chat",
	Contact_Details_Label: "Dettagli del contatto",
	Request_Details_Label: "Dettagli della richiesta",
	Attendees_Label: "Partecipanti",
	Customer_Label: "Cliente",
	Bosch_Expert_Label: "Esperto Bosch",
	Type_Your_Message_Label: "Digita il tuo messaggio",
	Stop_Channel_Question: "Vuoi chiudere la chat?",
	Yes_Label: "Si",
	Cancel_Label: "Annulla",
	Start_New_Video_Session: "Avvia una nuova sessione video",
	Without_SMS_Label: "Senza SMS",
	Send_SMS_Label: "Invia SMS",
	Send_Invite_Label: "Invia invito",
	Log_Out_Question: "Vuoi disconnetterti?",
	Welcome_Label: "Benvenuto nella chat",
	Login_Label: "Login",
	Login_Is_Required_Label: "Effettua il login per avviare la chat",
	Account_Permission_Label: "Questo account non dispone dell'autorizzazione per avviare una chat",
	Start_Label: "Inizia",
	Noted_Recommend: "A causa della politicy Bosch per l'utilizzo di Firefox, è consigliato utilizzare un browser diverso per un migliore funzionamento dell'applicazione Chat. Browser consigliati: Microsoft Edge e Google Chrome",
	Available_Languages_Label: "Lingue disponibili",
	Can_Not_Create_Meeting_Title: "Impossibile iniziare una nuova chat",
	Can_Not_Create_Meeting_Content: "Hai già una chat attiva. Terminala prima di iniziarne una nuova.",
	Leave_meeting_Warning: "Abbandona la chat o chiudi la chat per tutti. Una volta terminata, la chat non potrà più essere utilizzata",
	Leave_Lable: "Termina",
	End_Lable: "FINE",
	Meeting_Ended: "La riunione è terminata",
	Start_Channel_Session: "Avvia la chat",
	Start_Channel_Session_Content: "Il tecnico è momentaneamente occupato:",
	Join_Lable: "Entra",
	Join_Meeting_Label: "Entra nella chat",
	Meeting_Information_Label: "Informazioni sulla Chat",
	Meeting_ID_Label: "ID Chat",
	Translate_Option_Title: "Opzioni di traduzione",
	Translate_Mode_Label: "Modalità di traduzione:",
	From_Label: "Da:",
	To_Label: "A:",
	Chat_Room_Label: "Chat",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "ID Bosch",
	Invite_To_Chat_Question: "Come vorresti condividere l'invito alla chat?",
	Invite_To_Visual_Question: "Come vorresti condividere l'invito di Visual Connect?",
	Invite_Sms_Question: "Invia invito tramite SMS",
	Invite_To_Chat_By_Ticket_ID: "Invia invito tramite ticket ID",
	Invite_Email_Question: "Invia invito tramite e-mail",
	Link_label: "Link",
	WSA_label: "Servizio Assistenza Officina",
	WSA_Applications_label: "Applicazioni",
	WSA_Remote_Diagnostics_label: "Diagnosi Remota",
	WSA_Service_Training_label: "Formazione",
	WSA_Enter_website_label: "Entra nel sito web",
	WSA_Slogan_label: "Tecnologia per la vita",
	WSA_contact_info_label: "Informazioni di contatto",
	WSA_PSIRT_label: "Sicurezza del prodotto (PSIRT)",
	WSA_licences_label: "Innovazioni, brevetti e licenze",
	WSA_purchasing_label: "Acquisti e logistica",
	WSA_copyright_label: "© Robert Bosch GmbH, tutti i diritti riservati",
	WSA_copyright_information_label: "Informazioni aziendali",
	WSA_copyright_notice_label: "Note legali",
	WSA_copyright_protectionnotice_label: "Avviso sulla protezione dei dati",
	WSA_copyright_terms_label: "Termini & Condizioni",
	WSA_copyright_settings_label: "Impostazioni privacy",
	Infor_CorporateInformation_Description: "Responsabile per le pagine Internet di Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Informativa sulla protezione dei dati per Servizio Diagnosi Remota Bosch",
	Infor_LegalNotice_Description: "Per gli utenti delle pagine Internet di Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Oggetto",
	Make_panel: "Marca",
	Model_pane: "Modello",
	Engine_code_panel: "Codice motore",
	Year_panel: "Anno",
	Vin_panel: "VIN",
	Plate_panel: "Targa",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Il tuo dispositivo non è disponibile per AR",
	Write_something: "Scrivi qualcosa per iniziare a chattare",
	Session_title: "Sessione",
	Live_Translation: "Traduzione in tempo reale",
	Enabled_panel: "Abilitato",
	Joined_note: "Sei entrato nella sessione...",
	Today_panel: "Oggi",
	Read_panel: "Leggi",
	Sent_panel: "Inviato",
	Dis_translation: "Disabilita la traduzione",
	En_translation: "Abilita la traduzione",
	Terminated_Channel_note: "L'Esperto Bosch ha terminato la chat!",
	Not_start_note: "non è ancora iniziato!",
	Retry_note: "Fai clic su \"Riprova\" per riprovare o chiudi questa finestra per uscire.",
	Retry_panel: "Riprova",
	Thanks_note: "Grazie per aver utilizzato il servizio di assistenza Bosch. Contattaci di nuovo se hai bisogno di aiuto. Buona giornata.",
	Continue_panel: "Abilita la traduzione in tempo reale",
	En_live_translate: "Continua",
	Translate_from: "Tradotto da",
	Translate_to: "Traduci in",
	Random_ID: "ID casuale",
	Anonymous: "Anonima",
	Generated_ID: "ID casuale è stato generato!",
	Your_ID: "Il tuo ID casuale è:",
	Public_Meeting_ID_Label: "ID riunione pubblica",
	SelectContactPersonModal_Title: "Con chi ti piacerebbe chattare?",
	SelectContactPersonDropDown_Title: "Persona di contatto",
	SelectContactPersonDropDown_Error_Message: "Questo utente non ha un id ciam (account utente per CDM), seleziona un'altra persona di contatto per continuare ...",
	TicketCreator__Title: "(creatore del ticket)",
	Wsa_status_title: "Stato WSA",
	Close_panel: "Chiudi",
	OCR_Detected_title: "Riconoscimento Ottico dei Caratteri Rilevato:",
	Voice_Call_Label: "Chiamata Voce",
	Invite_To_Voice_Call_Question: "Come vorresti condividere l'invito alla chiamata vocale?",
	AR_mode: "MODALITÀ AR",
	Switch_modal_title: "Cambia",
	Switch_modal_content_Video: "Cambio a Chiamata Video riuscito!",
	Switch_modal_content_Voice: "Cambio a Chiamata Voce riuscito!",
	Video_Call_Label: "Chiamata Video",
	Functionality_Label: "Funzionalità",
	Press_Chatting_Button_Label: "Premi il pulsante di chat per iniziare la stanza di chat",
	WSA_Applications_label_full: "Applicazioni di Servizio del Workshop",
	WSA_Applications_item_card: "Inserisci applicazione",
	WSA_Applications_item_card_diagnostic_support: "Supporto Diagnostico",
	WSA_Applications_item_card_remote_diagnostics: "Diagnostica Remota",
	WSA_Applications_item_card_service_training: "Formazione al Servizio",
	WSA_Applications_item_card_technical_support: "Supporto Tecnico",
	Header_Label_Settings: "Impostazioni",
	Assistant_Label_Bot: "Assistente",
	Assistant_first_message: "Ciao, come posso aiutarti oggi?",
	Assistant_today_label: "Oggi",
	Visual_Connect_Pro_btn: "Visual Connect Pro",
	Join_chat_btn: "Unisciti alla Chat",
	Your_name_input_label: "Il tuo nome",
	Code_input_label: "Codice",
	Start_btn_label: "Andiamo",
	Change_info_label: "Cambia informazione",
	Change_name_label: "Cambia Nome",
	Change_code_label: "Cambia Codice",
	assistant_ask_name_message: "Prima di iniziare, posso sapere il tuo nome per favore?",
	assistant_ask_code_message: "Per favore inserisci il tuo codice per unirti alla sessione",
	assistant_ask_change_message: "Per favore scegli l'elemento che desideri cambiare",
	assistant_ask_change_name_message: "Per favore digita il tuo nome",
	assistant_ask_change_code_message: "Per favore digita il codice",
	assistant_ask_change_action_message: "Per favore seleziona il tipo di azione",
	assistant__confirm_message: "Ho ricevuto le tue informazioni: \n Il tuo codice: [code] \n Il tuo nome: [name] \n Vuoi continuare?",
	Assistant_Welcome_Bot: "Benvenuto nel Tessa Bot!",
	End_meeting_Warning: "Sei sicuro di voler terminare la riunione? La riunione non può essere utilizzata una volta terminata.",
	Leave_meeting_meesage_Warning: "Stai per terminare la chiamata, clicca qui per continuare",
	Reject_Switch_modal_content_Voice: "L'utente non consente l'attivazione della fotocamera",
	Change_action_label: "Cambia Tipo di Azione",
	BoschExpert_Label_Sender: "Agente Bosch",
	Retry_note_v2: "Chiudi il modulo e riprova o cambia il codice",
	End_Channel_note: "Il canale è terminato!",
	Leave_Channel_note: "Il tecnico ha lasciato il canale!",
	Join_Channel_note: "Il tecnico si è unito al canale!",
	Translation_is_available: "La traduzione è disponibile!",
	Translation_is_available_detail: "Abbiamo rilevato che la tua lingua è [tech_language] e che la lingua degli esperti Bosch è [expert_language]. \n Vuoi abilitare la traduzione in tempo reale?",
	No_Label: "No",
	Minimize_Label: "Riduci a icona",
	Maximize_Label: "Massimizza",
	Close_Session_Label: "Chiudi Sessione",
	Popup_Title_Warning: "Attenzione",
	Show_Message_Label: "Clicca qui per visualizzare la cronologia completa della chat di questa conversazione",
	Hide_Message_label: "Clicca qui per nascondere la cronologia della chat",
	Close_Channel_Message_Question: "Vuoi chiudere questa sessione?",
	Channel_noti_joined_message: "[name] si è unito al canale.",
	Click_to_view_history_message: "Clicca qui per visualizzare la cronologia della chat",
	Online_Label: "Online",
	Offline_Label: "Offline",
	Onhold_Label: "In attesa",
	Busy_Label: "Occupato",
	OK_Label: "OK",
	Channel_Notification: "Notifica del canale",
	BE_Leave: "Sembra che l'agente Bosch sia stato offline per un po'. Puoi continuare ad aspettare o lasciare questa conversazione.",
	Chat_LoadingChat_Message: "Caricamento chat.....",
	Popup_Title_Error: "Ops, qualcosa è andato storto!"
};