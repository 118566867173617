import React, { Fragment, useState } from 'react'
import { Grid, Button, Modal, Icon, Input } from 'semantic-ui-react'
import { ReactComponent as SMS } from '../../assets/svg/smartphone-loading-wheel.svg'
import { ReactComponent as Ticket } from '../../assets/svg/ticket.svg'
import { useTranslation } from 'react-i18next'
import './css/ModalCreateNewChannel.css'
import Boschicon from '../../common-components/boschIcon/Boschicon'

interface ModalSendInviteChattingChannelProps {
  isOpen: boolean
  onClose: Function
  onchangeTextSearchInput: Function
}

const ModalSendInviteChattingChannel = ({
  isOpen,
  onClose,
  onchangeTextSearchInput,
}: ModalSendInviteChattingChannelProps) => {
  const [openCodeInput, setOpenCodeInput] = useState<boolean>(false)
  const [textSearchInput, setTextSearchInput] = useState<String>('')
  const { t } = useTranslation()
  return (
    <Fragment>
      <Modal
        onClose={() => onClose()}
        open={isOpen}
        dimmer={{ className: 'test-dimmer' }}
        size={'tiny'}
        closeIcon
        closeOnDimmerClick={false}
      >
        <div className="header-color-line"></div>
        {!openCodeInput && (
          <>
            <Modal.Header style={{ textAlign: 'center' }}>
              {t('Invite_To_Chat_Question')}
            </Modal.Header>
            <Modal.Content>
              <Grid stackable columns={2}>
                <Grid.Column className="options">
                  <Button
                    className="options--item"
                    onClick={() => setOpenCodeInput(true)}
                  >
                    <Boschicon name={'bosch-ic-ticket'} />
                    {t('Ticket_Id_Label')}
                  </Button>
                </Grid.Column>
                <Grid.Column className="options">
                  <Button className="options--item" disabled>
                    <Boschicon name={'bosch-ic-smartphone-loading-wheel'} />
                    {t('Bosch_ID_Label')}
                  </Button>
                </Grid.Column>
              </Grid>
            </Modal.Content>
          </>
        )}
        {openCodeInput && (
          <>
            <Modal.Header style={{ textAlign: 'center' }}>
              {t('Invite_To_Chat_By_Ticket_ID')}
            </Modal.Header>
            <Modal.Content
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                justifyContent: 'center',
              }}
            >
              <Input
                placeholder="Ticket ID"
                className="txt-voucher-inputValue"
                onChange={(e: any) => {
                  setTextSearchInput(e.target.value)
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    onchangeTextSearchInput(textSearchInput)
                  }
                }}
              />
              <Button
                disabled={textSearchInput === ''}
                primary
                className="btn-connect-channel"
                onClick={() => onchangeTextSearchInput(textSearchInput)}
              >
                {t('Start_Label')}
              </Button>
            </Modal.Content>
          </>
        )}
      </Modal>
    </Fragment>
  )
}

export default ModalSendInviteChattingChannel
