import React from 'react'
import { useState, useEffect } from 'react'
import { Icon } from 'semantic-ui-react'

const Boschicon = (props: any) => {
  const [selctedIcon, setselectedicon] = useState(0)
  const { name, style, ...data } = props
  //Add below bosch font icon unicode and give name
  const iconconfig = [
    { name: 'shopping-cart', key: '\ue247' },
    { name: 'bell-plus', key: '\ue1f7' },
    { name: 'alert-info', key: '\ueb0f' },
    { name: 'filter', key: '\ue16e' },
    { name: 'grid-view', key: '\ue190' },
    { name: 'table-view', key: '\ue1c0' },
    { name: 'up-small', key: '\ue6d4' },
    { name: 'down-small', key: '\ue6c3' },
    { name: 'up', key: '\ue296' },
    { name: 'down', key: '\ue147' },
    { name: 'bosch-share', key: '\ue242' },
    { name: 'bosch-print', key: '\ue213' },
    { name: 'bosch-delete', key: '\ue118' },
    { name: 'bosch-edit', key: '\ue152' },
    { name: 'bosch-add', key: '\ue087' },
    { name: 'bosch-minimize', key: '\ue1ba' },
    { name: 'locked', key: '\ue1c8' },
    { name: 'spark', key: '\ue25d' },
    { name: 'bosch-login', key: '\ue1ca' },
    { name: 'bosch-logout', key: '\ue1cc' },
    { name: 'bosch-forward-right', key: '\ue181' },
    { name: 'bosch-close', key: '\ue0f0' },
    { name: 'bosch-upload-frame', key: '\ue89f' },
    { name: 'bosch-download-frame', key: '\ue893' },
    { name: 'bosch-pdf-doc', key: '\ue133' },
    { name: 'bosch-calendar-add', key: '\ue0c9' },
    { name: 'bosch-ic-alert-warning-filled', key: '\ueb0c' },
    { name: 'bosch-ic-alert-warning', key: '\ueb0d' },
    { name: 'bosch-ic-alert-error', key: '\ueb09' },
    { name: 'bosch-ic-alert-success', key: '\ueb0b' },
    { name: 'bosch-ic-translate', key: '\ue44b' },
    { name: 'bosch-ic-microphone-classic', key: '\ue1df' },
    { name: 'bosch-ic-desktop', key: '\ue11b' },
    { name: 'bosch-ic-video', key: '\ue2a8' },
    { name: 'bosch-ic-registration', key: '\ue220' },
    { name: 'bosch-ic-garage', key: '\ue597' },
    // { name: "bosch-ic-ordernumber", key: "\ue4ef" },
    { name: 'bosch-ic-find-id', key: '\ue38f' },
    { name: 'bosch-ic-alert-info-filled', key: '\ueb0e' },
    { name: 'bosch-ic-edit', key: '\ue152' },
    { name: 'bosch-ic-car-connected', key: '\ue0d5' },
    { name: 'bosch-ic-car-mechanic', key: '\ue0d7' },
    { name: 'bosch-ic-alert-success-filled', key: '\ueb0a' },
    { name: 'bosch-ic-checkmark', key: '\ue0e9' },
    { name: 'bosch-ic-paperclip', key: '\ue200' },
    { name: 'bosch-ic-back-left', key: '\ue0a0' },
    { name: 'bosch-ic-lock-closed', key: '\ue1c8' },
    { name: 'bosch-ic-upload', key: '\ue299' },
    { name: 'bosch-ic-document-pdf', key: '\ue133' },
    { name: 'bosch-ic-coin', key: '\ue50e' },
    { name: 'bosch-ic-user-add', key: '\ue2f7' },
    { name: 'bosch-ic-car', key: '\ue0d3' },
    { name: 'bosch-ic-wide-angled-arrow', key: '\ue2b9' },
    { name: 'bosch-ic-truck-delivery', key: '\ue5c4' },
    { name: 'bosch-ic-ticket', key: '\ue27f' },
    { name: 'bosch-ic-share', key: '\ue242' },
    { name: 'bosch-ic-question-frame', key: '\ue89b' },
    { name: 'bosch-ic-vci', key: '\ue48b' },
    { name: 'bosch-ic-van-delivery', key: '\ue5c5' },
    { name: 'bosch-ic-payment', key: '\ue204' },
    { name: 'bosch-ic-search', key: '\ue235' },
    { name: 'bosch-ic-paperplane', key: '\ue201' },
    { name: 'bosch-ic-thumb-up', key: '\ue27e' },
    { name: 'bosch-ic-smartphone-loading-wheel', key: '\ue257' },
    { name: 'bosch-ic-binary', key: '\ue3f4' },
    { name: 'bosch-ic-mail', key: '\ue1cf' },
    { name: 'bosch-ic-link', key: '\ue1be' },
    { name: 'bosch-ic-forward-right', key: '\ue181' },
    { name: 'bosch-ic-facebook', key: '\uea99' },
    { name: 'bosch-ic-twitter', key: '\ueaa4' },
    { name: 'bosch-ic-youtube', key: '\ueaac' },
    { name: 'bosch-ic-instagram', key: '\uea9d' },
    { name: 'bosch-ic-linkedin', key: '\uea9f' },
    { name: 'bosch-ic-externallink', key: '\ue167' },
    { name: 'bosch-ic-flash', key: '\ue178' },
    { name: 'bosch-ic-lightbulb-halogen-rays-bright', key: '\ue6f3' },
    { name: 'bosch-ic-augmented-reality', key: '\ue09e' },
    { name: 'bosch-ic-augmented-reality-player', key: '\ue3ed' },
    { name: 'bosch-ic-barcode-scan', key: '\ue4e4' },
    { name: 'bosch-ic-screenshot-frame', key: '\ue561' },
    { name: 'bosch-ic-pause', key: '\ue203' },
    { name: 'bosch-ic-play', key: '\ue20b' },
    { name: 'bosch-ic-settings', key: '\ue23f' },
    { name: 'bosch-ic-my-brand-frame', key: '\ue897' },
    { name: 'bosch-ic-service-agent', key: '\ue23d' },
    { name: 'bosch-ic-document-plain', key: '\ue134' },
    { name: 'bosch-ic-list-view-mobile', key: '\ue1c1' },
    { name: 'bosch-ic-call', key: '\ue0cb' },
    { name: 'bosch-ic-mechanic', key: '\ue1da' },
    { name: 'bosch-ic-replace', key: '\ue48f' },
    { name: 'bosch-ic-cursor-connected', key: '\ue38c' },
    { name: 'bosch-ic-robothead', key: '\ue226' },
    { name: 'bosch-ic-window-new', key: '\ue2c0' },
    { name: 'bosch-ic-less-minimize', key: '\ue1ba' },
    { name: 'bosch-ic-user', key: '\ue29b' },
    { name: 'bosch-ic-notification-active', key: '\ue1f6' },
    { name: 'bosch-ic-dot', key: '\ue6c2' },
    { name: 'bosch-ic-abort-frame', key: '\ue886' },
  ]

  useEffect(() => {
    setselectedicon(iconconfig.findIndex((val) => val.name === name))
  }, [props.name])

  return (
    <Icon className="Boschicon" {...data} style={style}>
      {iconconfig[selctedIcon]?.key || iconconfig[0].key}{' '}
    </Icon>
  )
}
export default Boschicon
