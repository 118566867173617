import React, { Fragment } from 'react';
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
interface ModalNotiEndMeettingProps {
    isOpen: boolean,
    onClose: Function,
}

const ModalNotiEndMeetting = ({ isOpen, onClose }: ModalNotiEndMeettingProps) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Modal
                onClose={() => onClose()}
                open={isOpen}
                dimmer={{ className: "test-dimmer" }}
                size={"tiny"}
                closeIcon
                closeOnDimmerClick={false}
            >
                <Modal.Header style={{ textAlign: "center" }}>{t("Can_Not_Create_Meeting_Title")}</Modal.Header>
                <Modal.Content>
                    {t("Can_Not_Create_Meeting_Content")}
                </Modal.Content>

            </Modal>
        </Fragment>

    );
};

export default ModalNotiEndMeetting;
