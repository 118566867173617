import React, { Fragment, useState } from 'react';
import { Modal, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Boschicon from '../../../common-components/boschIcon/Boschicon';
import { useAppDispatch } from '../../../redux/store';
import { SetIsTranslate } from '../../../redux/chimeSlide';
import Styles from '../css/ModalTranlationOptionsPublic.module.css'
import { countryOptions } from '../../../helpers/AppConstants';
import RDButton from '../../../common-components/RDComponents/RDButton';
import RDDropdown from '../../../common-components/RDComponents/RDDropdown';
interface ModalTranlationOptionsProps {
    isOpen: boolean,
    onClose: () => void | null,
    translateModeData: any,
    enableByBotWidget?: boolean,
    acceptButtonText: string;
}

const ModalTranlationOptionsPublic = ({ isOpen, translateModeData, onClose, enableByBotWidget = false, acceptButtonText }: ModalTranlationOptionsProps) => {
    const { t } = useTranslation();
    const dispatchReduxToolkit = useAppDispatch();
    const [isTranslate, setIsTranslate] = useState<boolean>(translateModeData.status);
    const [langSource, setLangSource] = useState<string>(translateModeData.langSource);
    const [langTarget, setTarget] = useState<string>(translateModeData.langTarget);

    const handleOnClick = () => {
        dispatchReduxToolkit(SetIsTranslate({
            ...translateModeData,
            status: true,
            langSource: langSource,
            langTarget: langTarget,
            languagebox: false
        }))
        onClose()
    }
    return (
        <Fragment>
            <Modal
                dimmer={{
                    className: `${Styles.wrapper_modal} ${Styles.success}`,
                }}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                size={"small"}
                className={Styles.modal}
            >
                <Modal.Header className={Styles.header}>
                    <Boschicon name={'bosch-ic-translate'} />
                    <span className={Styles.title}>{t('En_live_translate')}</span>
                </Modal.Header>
                <Modal.Content className={Styles.content}>
                    <Grid className={`${Styles.ui} ${Styles.grid}`} columns={2}>
                        <Grid.Row>
                            <Grid.Column width={4} className={`${Styles.column}`}>
                                {t('Translate_from')}
                            </Grid.Column>
                            <Grid.Column width={12} className={`${Styles.column}`}>
                                <RDDropdown
                                    disabled={!isTranslate && !enableByBotWidget}
                                    value={langSource}
                                    onChange={(event: any, data: any) => setLangSource(data.value)}
                                    selection
                                    search
                                    options={countryOptions}
                                    title={''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4} className={`${Styles.column}`}>
                                {t('Translate_to')}
                            </Grid.Column>
                            <Grid.Column width={12} className={`${Styles.column}`}>
                                <RDDropdown
                                    disabled={!isTranslate && !enableByBotWidget}
                                    value={langTarget}
                                    search
                                    selection
                                    onChange={(event: any, data: any) => setTarget(data.value)}
                                    options={countryOptions}
                                    title={''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions className={Styles.actions}>
                    <RDButton onClick={handleOnClick}>{acceptButtonText}</RDButton>
                </Modal.Actions>
            </Modal>

        </Fragment >
    );
};

export default ModalTranlationOptionsPublic;
