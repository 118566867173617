import React from "react";
import style from "./RDButton.module.css";
import { Button, ButtonProps } from "semantic-ui-react";

interface Props extends ButtonProps {
  variant?: "primary" | "secondary" | "tertiary" | "integrated";
  minWidth?: "fluid" | "maxContent";
  small?: boolean;
  testId?: string;
}

const RDButton = (props: Props) => {
  const {
    primary,
    secondary,
    disabled,
    variant,
    minWidth,
    small,
    testId,
    ...restProps
  } = props;

  const getButtonClassNames = () => {
    const defaultClassNames: string[] = [style.button];

    defaultClassNames.push(!variant ? style.primary : style[variant]);
    defaultClassNames.push(!!disabled ? style.disabled : "");
    defaultClassNames.push(!!small ? style.small : "");
    defaultClassNames.push(restProps.className ? restProps.className : "");
    defaultClassNames.push(minWidth ? style[minWidth] : "");

    return defaultClassNames.join(" ");
  };

  return (
    <Button
      {...restProps}
      disabled={disabled}
      className={getButtonClassNames()}
      data-testid={testId}
    >
      {props.children}
    </Button>
  );
};

export default RDButton;
