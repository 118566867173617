import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import httpPCS from '../services/HttpServicePCS'
import { RootState } from './store'
import httpPCSPublic from '../services/HttpServicePCSPublic'

export interface MediaPlacementState {
  AudioHostUrl: string
  AudioFallbackUrl: string
  ScreenDataUrl: string
  ScreenSharingUrl: string
  ScreenViewingUrl: string
  SignalingUrl: string
  TurnControlUrl: string
  EventIngestionUrl: string
}

export interface MeetingDetailState {
  meetingId: string
  externalMeetingId: string
  MediaPlacement: MediaPlacementState
  mediaRegion: string
}

export interface AttendeeState {
  ExternalUserId: string
  attendeeId: string
  JoinToken: string
}
export interface AttendeeRoute {
  chimeAttendeeId: string
  externalUserId: string
  name: string
}

export interface ActionMeetingState {
  AR: boolean
  Draw: boolean
  MousePointer: boolean
}

export interface MeetingState {
  Title: string
  Meeting: MeetingDetailState
  Attendee: AttendeeState
  ChannelName: string
  ListAttendee: AttendeeRoute[]
  ActionInMeeting: ActionMeetingState
}

const initialMediaPlacementState: MediaPlacementState = {
  AudioHostUrl: '',
  AudioFallbackUrl: '',
  ScreenDataUrl: '',
  ScreenSharingUrl: '',
  ScreenViewingUrl: '',
  SignalingUrl: '',
  TurnControlUrl: '',
  EventIngestionUrl: '',
}

const initialMeetingDetailState: MeetingDetailState = {
  meetingId: '',
  externalMeetingId: '',
  MediaPlacement: initialMediaPlacementState,
  mediaRegion: '',
}

const initialAttendeeState: AttendeeState = {
  ExternalUserId: '',
  attendeeId: '',
  JoinToken: '',
}

const initialListAttendeeState: AttendeeRoute[] = [
  {
    chimeAttendeeId: '',
    externalUserId: '',
    name: '',
  },
]

const initialActionMeetingState: ActionMeetingState = {
  AR: false,
  Draw: false,
  MousePointer: false,
}

export const initialState: MeetingState = {
  Title: '',
  Meeting: initialMeetingDetailState,
  Attendee: initialAttendeeState,
  ChannelName: '',
  ListAttendee: initialListAttendeeState,
  ActionInMeeting: initialActionMeetingState,
}

async function getNearestMediaRegion() {
  const url = 'https://nearest-media-region.l.chime.aws'
  let result = process.env.REACT_APP_REACT_REGION

  try {
    //Find the nearest media region
    console.log('Nearest media region URL: ' + url)
    const response = await fetch(url, { method: 'GET' })
    const body = await response.json()
    result = body.region
  } catch (error) {
    console.log('error')
  } finally {
    console.log('Nearest media region found: ' + result)
    return result
  }
}

export const createMeeting = createAsyncThunk(
  'meeting/createMeeting',
  async (ticketRequest, thunkApi) => {
    var result: any
    try {
      const mediaRegion = await getNearestMediaRegion()
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      const response = await httpPCS.get(
        `/api/Chime/CreateMeeting?region=${mediaRegion}`,
        { headers },
      )
      console.log(response)
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return result
  },
)

export const createMeetingByRandomCode = createAsyncThunk<any, any>(
  'meeting/createMeetingByRandomCode',
  async (randomCode, thunkApi) => {
    var result: any
    try {
      const mediaRegion = await getNearestMediaRegion()
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      const body = {
        region: mediaRegion,
        randomId: randomCode,
      }
      const response = await httpPCS.post(
        `/api/Chime/CreateMeetingByRamdomCode`,
        body,
        { headers },
      )
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return result
  },
)

export const addAttendeeByRandomCode = createAsyncThunk<any, any>(
  'meeting/addAttendeeByRandomCode',
  async (attendeeRequest, thunkApi) => {
    var result: any
    try {
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      var response = await httpPCSPublic.post(
        `/api/Chime/CreateAttendeeByRamdomCode`,
        attendeeRequest,
        { headers },
      )
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return result
  },
)

export const getAttendeeName = createAsyncThunk(
  'meeting/getAttendeeName',
  async (ciamId, thunkApi) => {
    var result: any
    try {
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      const response = await httpPCS.get(
        `/api/Chime/GetNameChimeUser?ciamId=${ciamId}`,
        { headers },
      )

      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return result
  },
)

export const addAttendee = createAsyncThunk<any, any>(
  'meeting/addAttendee',
  async (attendeeRequest, thunkApi) => {
    var result: any
    try {
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      var response = await httpPCSPublic.post(
        `/api/Chime/CreateAttendee`,
        attendeeRequest,
        { headers },
      )
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return result
  },
)

export const endMeeting = createAsyncThunk<any, any>(
  'meeting/endMeeting',
  async (data, thunkApi) => {
    var result: any
    try {
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      var response = await httpPCSPublic.get(
        `/api/Chime/DeleteMeeting?meetingId=${data.meetingId}&externalMeetingId=${data.externalMeetingId}`,
        { headers }
      )
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return result
  },
)

export const selectMeetingState = (rootState: RootState) => rootState.meeting
export const selectAttendeeState = (rootState: RootState) =>
  rootState.meeting.Attendee
export const selectActionMeetingState = (rootState: RootState) =>
  rootState.meeting.ActionInMeeting
export const selectListAttendeeState = (rootState: RootState) =>
  rootState.meeting.ListAttendee

const meetingReducerSlice = createSlice({
  name: 'meetingReducer',
  initialState: initialState,
  reducers: {
    getMeetingState: (state) => {
      return { ...state }
    },
    leaveMeeting: (state) => {
      return initialState
    },
    meetingSetChannelName: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ChannelName: action.payload,
      }
    },
    addListAttendee: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ListAttendee: action.payload,
      }
    },
    changeActionMeeting: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ActionInMeeting: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMeeting.fulfilled, (state, { payload }) => {
        state.Meeting = payload.dataObject.createMeetingResponse.meeting
        // state.Attendee = payload.dataObject.attendee.attendee;
        state.Title = payload.dataObject.title
      })
      .addCase(createMeetingByRandomCode.fulfilled, (state, { payload }) => {
        state.Meeting = payload.dataObject.createMeetingResponse.meeting
        // state.Attendee = payload.dataObject.attendee.attendee;
        state.Title = payload.dataObject.title
      })
      .addCase(addAttendeeByRandomCode.fulfilled, (state, { payload }) => {
        state.Meeting = payload.dataObject.createMeetingResponse.meeting
        state.Attendee = payload.dataObject.attendee.attendee
        state.Title = payload.dataObject.title
      })
      .addCase(addAttendee.fulfilled, (state, { payload }) => {
        state.Meeting = payload.dataObject.createMeetingResponse.meeting
        state.Attendee = payload.dataObject.attendee.attendee
        state.Title = payload.dataObject.title
      })
      .addCase(endMeeting.fulfilled, (state, { payload }) => {
        return initialState
      })
  },
})

export const {
  getMeetingState,
  leaveMeeting,
  meetingSetChannelName,
  addListAttendee,
  changeActionMeeting,
} = meetingReducerSlice.actions
export default meetingReducerSlice.reducer
