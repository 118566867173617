import { FC, useState } from "react";
import { Button, Dropdown, Grid, Menu, Popup } from "semantic-ui-react";
import Style from "./styles/header.module.css";
import { useTranslation } from "react-i18next";
import React from "react";
import Boschicon from "../../../../common-components/boschIcon/Boschicon";
import { useSelector } from "react-redux";
import { selectChannelArn, selectIsTranslate } from "../../../../redux/chimeSlide";

export interface IHeader {
  title: string;
  onExpandWindow: () => void;
  onClose: () => void;
  onMinimize: () => void;
  onOpenTranslateModal: () => void;
  onCloseTranslateModal: () => void;
}

const Header: FC<IHeader> = ({ onClose, title, onExpandWindow, onMinimize, onOpenTranslateModal, onCloseTranslateModal }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isTranslate = useSelector(selectIsTranslate);
  const channelArn = useSelector(selectChannelArn);

  const onCheckClick = () => {
    if (isTranslate.status) {
      onCloseTranslateModal()
    } else {
      onOpenTranslateModal()
    }
  }

  return (
    <div>
      <div className={Style.sc_header}>
        <div className={Style.menu_icon_area}>
          <div className={Style.dropdown_menu_icon}>
            <Button
              className={Style.header_icon_button}
              onClick={() => setOpen(!open)}
              icon={
                open ? <Boschicon name="bosch-close"></Boschicon> : <Boschicon name="bosch-ic-list-view-mobile"></Boschicon>
              }
            />
            {
              open && <div className={Style.dropDown_box}>
                <Button
                  className={isTranslate.status ? `${Style.translate_modal_btn} ${Style.disable}` : `${Style.translate_modal_btn}`}
                  onClick={onCheckClick}
                  disabled={!channelArn}
                ><Boschicon
                    style={{ padding: "unset" }}
                    name={'bosch-ic-translate'}
                  /> {t("Live_Translation")}
                </Button>
              </div>
            }
          </div>
          <p>{title}</p>
        </div>

        <div className={Style.close_icon_area}>
          <Popup trigger={<Button
            icon={<Boschicon name="bosch-ic-less-minimize"></Boschicon>}
            onClick={() => {
              setOpen(false)
              onMinimize()
            }}
            className={Style.header_icon_button}
          />} position="top center">
            {t("Minimize_Label")}
          </Popup>

          <Popup trigger={<Button
            icon={<Boschicon name="bosch-ic-window-new"></Boschicon>}
            onClick={() => {
              onExpandWindow()
              setOpen(false)
            }}
            className={Style.header_icon_button}

          />} position="top center">
            {t("Maximize_Label")}
          </Popup>

          {
            channelArn && (<Popup trigger={<Button
              icon={<Boschicon name="bosch-close"></Boschicon>}
              onClick={() => {
                onClose()
                setOpen(false)
              }}
              className={Style.header_icon_button}
            />} position="top left">
              {t("Close_Session_Label")}
            </Popup>)
          }

        </div>

      </div>
    </div>
  );
};

export default Header;
