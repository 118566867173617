import React, { useState, useRef, Fragment, useEffect } from 'react'
import {
  Persistence,
  MessageType,
  sendChannelMessage,
  updateChannelReadMarker,
} from '../../../../api/ChimeAPI'
import './Input.css'
import {
  Button,
  Icon,
  Input as InputAV,
  Popup,
} from 'semantic-ui-react'
import { EmojiClickData } from 'emoji-picker-react'
import { ReactComponent as Emoji } from '../../../../assets/svg/emoji-happy.svg'
import ModalGetEmoji from '../../../../components/modals/ModalGetEmoji'
import { useTranslation } from 'react-i18next'
import { checkText } from 'smile2emoji'
import Boschicon from '../../../../common-components/boschIcon/Boschicon'
import autosize from 'autosize'
interface InputProps {
  activeChannelArn: string
  member: string
  browserLanguage: string
}

const PublicInputMessage = ({
  activeChannelArn,
  member,
  browserLanguage,
}: InputProps) => {
  const [text, setText] = useState('')
  const [openEmoji, setOpenEmoji] = useState<boolean>(false)
  const inputRef = useRef<any>()
  const { t } = useTranslation()
  const memberSender = { userId: member }
  const [countWordMesssage, setCountWordMesssage] = useState<number>(0)
  const maxSizeMessageInWord = 4000;
  const resetState = () => {
    setText('')
    setCountWordMesssage(0)
  }

  const onChange = (e: any) => {
    let text = e.target.value
    //convert text to emoji
    let result = checkText(text)
    let countWord = result.length;
    if (countWord <= maxSizeMessageInWord) {
      setText(result);
      setCountWordMesssage(countWord)
    }

    if (countWord > maxSizeMessageInWord) {
      let maxSizeMessageInWordInText = result.substring(0, maxSizeMessageInWord);
      setText(maxSizeMessageInWordInText);
      setCountWordMesssage(maxSizeMessageInWordInText.length)
    }


  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    let textMessage = text;
    const options = {
      language: browserLanguage,
    }
    if ((textMessage.trim() !== "") && (countWordMesssage < maxSizeMessageInWord)) {
      await sendChannelMessage(
        activeChannelArn,
        textMessage,
        Persistence.PERSISTENT,
        MessageType.STANDARD,
        memberSender,
        JSON.stringify(options),
      )
      updateChannelReadMarker(activeChannelArn, memberSender.userId)
      resetState()
    }
  }

  const clearInput = async (e: any) => {
    e.preventDefault()
    setText("");
  }

  const getEmoji = (data: EmojiClickData) => {
    setText(text + data.emoji)
    setOpenEmoji(false)
  }

  const insertLineBreak = () => {
    const textarea = inputRef.current;

    if (!textarea) {
      return;
    }

    const { selectionStart, selectionEnd, value } = textarea;
    const newText = `${value.substring(0, selectionStart)}\n${value.substring(selectionEnd)}`;

    setText(newText);

    // Move the cursor to the position after the inserted line break
    const newPosition = selectionStart + 1;
    textarea.setSelectionRange(newPosition, newPosition);
  };

  useEffect(() => {
    if (inputRef.current) {
      autosize(inputRef.current);
      return () => {
        autosize.destroy(inputRef.current!);
      };
    }
  }, [text, inputRef.current]);


  return (
    <div className="message-input-container">
      <form onSubmit={onSubmit} className="message-input-form">
        <Fragment>
          <div className={'input-component'}>
            <textarea
              className="text_area"
              placeholder={t('Type_Your_Message_Label')}
              onChange={onChange}
              value={text}
              onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (e.key === 'Enter' && e.altKey) {
                  e.preventDefault();
                  insertLineBreak();
                } else if (e.key === 'Enter') {
                  onSubmit(e);
                }
              }}
              ref={inputRef}
            />
          </div>
          <div className={(countWordMesssage >= maxSizeMessageInWord) ? 'input-text-count limited' : 'input-text-count'}>
            <p>{`${countWordMesssage}/${maxSizeMessageInWord}`}</p>
          </div>
          <div className={'input-container'}>
            <div className='input-container--function'>
              <Button
                className="input-button"
                onClick={() => setOpenEmoji(!openEmoji)}
                type="button"
              >
                <Popup
                  content="Emoji"
                  inverted
                  position="top right"
                  trigger={
                    <Icon>
                      <Emoji />
                    </Icon>
                  }
                />
              </Button>

            </div>
            <div className='input-container--function'>
              <Button
                className="input-button"
                onClick={(e) => {
                  onSubmit(e)
                }}
              >
                <Popup
                  content="Send message"
                  inverted
                  position="top right"
                  trigger={<Icon name="send" />}
                />
              </Button>
              <Button
                className="input-button"
                onClick={(e) => {
                  clearInput(e)
                }}
              >
                <Popup
                  content="Clear Input"
                  inverted
                  position="top right"
                  trigger={<Boschicon name="bosch-close" />}
                />
              </Button>

            </div>
          </div>
        </Fragment>
      </form>

      {openEmoji && (
        <ModalGetEmoji
          isOpen={openEmoji}
          onClose={() => setOpenEmoji(false)}
          getEmoji={(e: EmojiClickData) => getEmoji(e)}
        ></ModalGetEmoji>
      )}

    </div>
  )
}

export default PublicInputMessage
