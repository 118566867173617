import * as React from "react";
import Styles from "./ProductCard.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppItemDetail } from "../../models/AppItem.model";
import Boschicon from "../boschIcon/Boschicon";

interface ProductsCardProps {
  item: AppItemDetail;
}

const ProductsCardCom: React.FunctionComponent<ProductsCardProps> = ({
  item,
}: ProductsCardProps) => {
  let [newImage, setNewImage] = useState<string>(item.image);
  const { t } = useTranslation();
  useEffect(() => {
    import("../../assets/" + item.image).then((image) => {
      setNewImage(image.default);
    });
  }, [item.image]);

  return (
    <div className={Styles.product_card}>
      <div className={Styles.product_card__image}>
        <img className={Styles.image} src={newImage} alt="" />
      </div>
      <div className={Styles.product_card__name}>{item.title}</div>
      <div className={Styles.product_card__learnmore}>
        <a className={Styles.right_icon} href={item.link}>{t("WSA_Applications_item_card")}</a>
        <Boschicon name="bosch-forward-right"></Boschicon>
      </div>
    </div>
  );
};

export { ProductsCardCom };
