import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import store from './redux/store';
import { persistStore } from 'redux-persist';
import { Notifications } from 'react-push-notification';
import { PersistGate } from 'redux-persist/integration/react';
import "./translations/i18n";
import i18n from "i18next";
import { msalInstance } from './authConfig';
import { MsalProvider } from "@azure/msal-react";

let persistor = persistStore(store);
i18n.changeLanguage('en');


ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Notifications />
        <App />
      </PersistGate>
    </Provider>
  </MsalProvider>,
  document.getElementById("root")
);