import React from "react";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Modal,
} from "semantic-ui-react";
import '../css/DeletedChannelModal.css'
import Boschicon from "../../../common-components/boschIcon/Boschicon";

const DeletedChannelModal: FC<any> = (props: any) => {
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { t } = useTranslation();

    useEffect(() => {
        if (props.showError && props.errorMessage != null && props.errorMessage != '') {
            setErrorMessage(props.errorMessage);
            setOpenErrorModal(true);
        }
        else {
            setErrorMessage('');
        }
    }, [props]);


    const handleClose = () => {
        setErrorMessage('');
        setOpenErrorModal(false);
        window.close()
    }

    return (
        <Fragment>
            <Modal
                open={openErrorModal}
                size={"mini"}>
                <div className="deleteModal">
                    <div className="colorBar">

                    </div>
                    <div className="error-area">
                        <div className="error--title">
                            <Boschicon name={'bosch-ic-service-agent'} />
                            <p>
                                {errorMessage}
                            </p>
                        </div>
                        <div className="error--content">
                            <p>{t('Thanks_note')}</p>
                        </div>
                        <div className="btn--option">
                            <Button onClick={handleClose} primary color='blue'>
                                {t("Close_panel")}</Button>
                        </div>
                    </div>

                </div>
            </Modal>
        </Fragment>
    )
}

export default DeletedChannelModal;