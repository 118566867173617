import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Modal } from "semantic-ui-react";
import Styles from "./RDAlert.module.css";
import RDButton from "./RDButton";
import Boschicon from "../boschIcon/Boschicon";
import React from "react";
import CustomLoader from "./CustomLoader";

interface RDAlertProps {
  type: "warning" | "success" | "error" | "info" | "retry" | "close_channel" | "detected_language";
  isOpen: boolean;
  title?: string;
  messages: string;
  cancelButtonText?: string;
  acceptButtonText: string;
  isAcceptLoading?: boolean;
  onCancel?: Function;
  onAccept: Function;
}

const RDAlert: FC<RDAlertProps> = ({
  type,
  isOpen,
  title,
  messages,
  isAcceptLoading,
  acceptButtonText,
  onAccept,
  cancelButtonText,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [displayMessages, setDisplayMessages] = useState<string[]>([]);

  useEffect(() => {
    if (!!messages) {
      if (typeof messages === "string") {
        setDisplayMessages([messages]);
      } else {
        setDisplayMessages([...messages]);
      }
    }
  }, [messages]);

  const handleAccept = () => {
    onAccept();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Fragment>
      <Modal
        dimmer={{
          className: `${Styles.wrapper_modal} ${(type === "warning" || type === "retry") && Styles.warning
            } ${type === "error" && Styles.error} ${(type === "success" || type === "close_channel" || type === "detected_language") && Styles.success
            } ${type === "info" && Styles.info}`,
        }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={isOpen}
        size={"small"}
        className={Styles.modal}
      >
        <Modal.Header className={Styles.header}>
          {(type === "warning" || type === "retry") && (
            <>

              <Boschicon name={type === "warning" ? "bosch-ic-alert-warning" : "alert-info"} />
              <span className={Styles.title}>{title}</span>
            </>
          )}
          {type === "error" && (
            <>
              <Boschicon name="bosch-ic-alert-error" />
              <span className={Styles.title}>{t("Popup_Title_Error")}</span>
            </>
          )}
          {(type === "success" || type === "close_channel" || type === "detected_language") && (
            <>
              {
                type === "success" ? (
                  <Boschicon name={"bosch-ic-alert-success"} />
                ) : (
                  <Boschicon name={type === "close_channel" ? "bosch-ic-service-agent" : "bosch-ic-translate"} />
                )
              }
              <span className={Styles.title}>{title}</span>
            </>
          )}

          {type === "info" && (
            <>
              <Boschicon name="alert-info" />
              <span className={Styles.title}>{title}</span>
            </>
          )}

          {!!onCancel && (
            <RDButton
              className={Styles.close_button_header}
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
              }}
            >
              <Boschicon name="bosch-close" />
            </RDButton>
          )}
        </Modal.Header>
        <Modal.Content className={Styles.content}>
          <Grid className={`${Styles.ui} ${Styles.grid}`}>
            {displayMessages.map((message: string, index) => {
              return (
                <Grid.Row className={Styles.row} key={index}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: message,
                    }}
                  ></span>
                </Grid.Row>
              );
            })}
          </Grid>
        </Modal.Content>
        <Modal.Actions className={Styles.actions}>
          <RDButton onClick={handleAccept}>{acceptButtonText}</RDButton>
          {!!onCancel && (
            <RDButton variant="secondary" onClick={handleCancel}>
              {cancelButtonText}
            </RDButton>
          )}
        </Modal.Actions>
      </Modal>

      {isAcceptLoading && <CustomLoader enable />}
    </Fragment>
  );
};

export default RDAlert;
