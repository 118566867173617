import { Fragment, useEffect, useRef, useState } from "react";
import Style from "./styles/message.module.css";
import { useTranslation } from "react-i18next";
import React from "react";
import { ChatBubble, ChatBubbleContainer, InfiniteList, MessageAttachment } from "amazon-chime-sdk-component-library-react";
import { messageListModal, selectChatChannelHistory, selectShowLoadingDot, selectUserChime, senderENUM, setShowLoadingDot } from "../../../../redux/assistantBot";
import LoadingDots from "../../../../common-components/loading-dots/Loading-dots";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../redux/store";
import Boschicon from "../../../../common-components/boschIcon/Boschicon";
import Attachment from "../../../../containers/InputChat/Attachment";
import { MemberPresence } from "./BotWidget";
import { OnlinePresenceStatus } from "../../../../helpers/AppConstants";

interface MessagesProps {
  messagesAssit: messageListModal[];
  messagesChanel?: messageListModal[] | null;
  memberPresenceList: MemberPresence[]
}

const Messages = ({
  messagesAssit,
  messagesChanel,
  memberPresenceList
}: MessagesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [onHideChatHistory, setOnHideChatHistory] = useState<boolean>(false);
  const showLoadingDot = useSelector(selectShowLoadingDot);
  const chatChannelHistoryData = useSelector(selectChatChannelHistory);
  const userChimeId = useSelector(selectUserChime);
  const dispatchReduxToolkit = useAppDispatch();
  const { t } = useTranslation();
  const scrollableDivRef = useRef<null | HTMLDivElement>(null);
  var isLoadImg = false;
  const resize = (size: string) => {
    let size_num = Number(size)
    return size_num < 1024 ? `${size} bytes` : size_num < 1024 * 1024 ? `${String((size_num / 1024).toFixed(2))} KB` : `${String((size_num / 1024 / 1024).toFixed(2))} MB`
  }

  const onloadimg = () => {
    isLoadImg = true;
  }

  const onClickMessageFunction = () => {
    setOnHideChatHistory(!onHideChatHistory)
  }

  const removeDuplicatesMessage = (messageList: messageListModal[]) => {
    const uniqueMessages: { [key: string]: boolean } = {};
    const result: messageListModal[] = [];

    for (const message of messageList) {
      const key = `${message.sender}_${message.message}`;

      if (!uniqueMessages[key]) {
        result.push(message);
        uniqueMessages[key] = true;
      }
    }

    return result;
  };


  const getHistoryMessageCheck = () => {
    const listHistoryMessageId: string[] = chatChannelHistoryData ? chatChannelHistoryData.map((item: { messageId: any; }) => item.messageId) : [];
    let assistantMessages = removeDuplicatesMessage(messagesAssit)
    let channelMessages = messagesChanel;
    let historyMessage = chatChannelHistoryData
    let listNewChannelMessageExcludeHistory = channelMessages ? channelMessages?.filter((m: messageListModal, i, seif) => {
      if (m.messageId && !listHistoryMessageId.includes(m.messageId)) {
        return true;
      }
      return false;
    }) : [];

    // return assitant message when no channel start
    if (!channelMessages || (channelMessages && channelMessages.length == 0)) {
      let flatMessages = flattenedMessages(assistantMessages);
      let resultMessages = messageList(flatMessages);
      return resultMessages;
    }

    // return assitant message when channel has started
    let mappingListMessage = assistantMessages.concat(channelMessages)
    //if channel has history message
    if (chatChannelHistoryData && chatChannelHistoryData.length > 0 && chatChannelHistoryData[0] !== "empty") {
      if (!onHideChatHistory) {
        mappingListMessage = assistantMessages.concat({
          sender: senderENUM.channel,
          message: t("Show_Message_Label"),
          datetime: "",
          metaData: "",
          messageId: "",
          senderName: "",
          senderArn: ""
        }).concat(listNewChannelMessageExcludeHistory)
      } else {
        mappingListMessage = assistantMessages.concat(historyMessage).concat({
          sender: senderENUM.channel,
          message: t("Hide_Message_label"),
          datetime: "",
          metaData: "",
          messageId: "",
          senderName: "",
          senderArn: ""
        }).concat(listNewChannelMessageExcludeHistory)
      }
    }
    let flatMessages = flattenedMessages(mappingListMessage);
    let resultMessages = messageList(flatMessages);

    return resultMessages;
  }

  const flattenedMessages = (messages: messageListModal[]) => {
    return messages.filter((m: messageListModal, index: number) => {
      if (m.metaData) {
        const messageWithDeleted = JSON.parse(m.metaData).deleted ? true : false;
        return !messageWithDeleted
      }
      return true
    }).map((m: messageListModal, index: number) => {
      const content = !m.message ? '(Deleted)' : m.message;
      return {
        sender: m.sender,
        message: content,
        datetime: m.datetime,
        metaData: m.metaData,
        messageId: m.messageId || "",
        senderName: m.senderName,
        senderArn: m.senderArn
      };
    });
  }

  let iconPresenceStatus = (status: string) => {
    switch (status) {
      case OnlinePresenceStatus.busy:
        return (<div className={Style.busy_icon}><Boschicon name="bosch-ic-dot" /></div>)
      case OnlinePresenceStatus.offline:
        return (<div className={Style.offline_icon}><Boschicon name="bosch-ic-abort-frame" /></div>)
      case OnlinePresenceStatus.onhold:
        return (<div className={Style.onhold_icon}><Boschicon name="bosch-ic-dot" /></div>)
      case OnlinePresenceStatus.online:
        return (<div className={Style.online_icon}><Boschicon name="bosch-ic-dot" /></div>)
      default:
        break;
    }
  }

  let iconAvatarMember = (m: messageListModal) => {
    let memmberArn = m.senderArn || "";
    let memberPresence = OnlinePresenceStatus.offline
    let exitMemberStatus = memberPresenceList.find(
      (x: any) =>
        memmberArn.includes(x.userArn)
    );
    if (exitMemberStatus) {
      memberPresence = exitMemberStatus.status
    }
    return (
      <>
        {
          m.sender === senderENUM.boschExpert && (
            <div className={Style.avatarIcon}>
              <Boschicon name={"bosch-ic-service-agent"}></Boschicon>
              {
                iconPresenceStatus(memberPresence)
              }
            </div>

          )
        }
        {
          m.sender === senderENUM.assistant && (
            <div className={Style.avatarIcon}>
              <Boschicon name={"bosch-ic-robothead"}></Boschicon>
              {
                iconPresenceStatus(OnlinePresenceStatus.online)
              }
            </div>
          )
        }
        {
          m.sender === senderENUM.member && (
            <div className={Style.avatarIcon}>
              <Boschicon name={"bosch-ic-user"}></Boschicon>
              {
                iconPresenceStatus(memberPresence)
              }
            </div>
          )
        }
      </>
    )
  }

  const messageList = (flattenedMessages: messageListModal[]) => {
    return flattenedMessages.map((m, i, self) => {
      if (!m.message) {
        return m;
      }

      let hasAttachment = -1;

      let metadata: {
        isMeetingInfo: boolean,
        attachment: {
          fileKey: string,
          type: string,
          size: string,
          name: string
        },
        status: string,
        meeting: {
          meetingId: string,
          pathRoute: string
        },
        onStartVoiceCall: boolean
      } = {
        isMeetingInfo: false,
        attachment: {
          fileKey: '',
          type: '',
          size: '',
          name: ''
        },
        status: '',
        meeting: {
          meetingId: '',
          pathRoute: ''
        },
        onStartVoiceCall: false
      };

      let preSignURL;

      if (m.metaData) {
        metadata = JSON.parse(m.metaData);
        if (metadata.isMeetingInfo) {
          return m;
        };

        if (metadata?.attachment?.fileKey) {
          // preSignURL = attachmentService.getPresignURL(metadata.attachment.fileKey);
          if (metadata.attachment?.type.includes('image')) {
            hasAttachment = 0;
          } else {
            hasAttachment = 1;
          }
        }
      }

      const variant = m.sender === senderENUM.user ? "outgoing" : "incoming";

      //check sender name
      let senderNameDetect = "";
      switch (m.sender) {
        case senderENUM.boschExpert:
          senderNameDetect = t("BoschExpert_Label_Sender");
          break;
        case senderENUM.assistant:
          senderNameDetect = t("Assistant_Label_Bot");
          break;
        case senderENUM.member:
          senderNameDetect = m.senderName || "";
          break;
        default:
          break;
      }

      return (
        <div className={`${Style.messages} ${Style.sc_message} `}>
          {variant === "outgoing" ? (
            <>
              <ChatBubbleContainer
                timestamp={m.datetime}
                key={`message${i.toString()}`}
                css="
              background-color: #fff;
              margin: 0;
              display: flex;
              align-items: end;
              flex-direction: column;
              "
              >
                <ChatBubble
                  variant={variant}
                  css="border-radius: 0px;
                background-color: var(--boschBlue50);
                color: #fff;
                "
                >
                  <div>
                    {m.message}
                  </div>
                </ChatBubble>
              </ChatBubbleContainer>
            </>
          ) : (
            <Fragment>
              {m.sender === senderENUM.system && (
                <div className={Style.joined_session}>
                  <p>{m.message}</p>
                </div>
              )
              }
              {m.sender === senderENUM.channel && (
                <div className={Style.joined_session}>
                  <a onClick={onClickMessageFunction}>{m.message}</a>
                </div>
              )
              }
              {
                (m.sender === senderENUM.boschExpert || m.sender === senderENUM.assistant || m.sender === senderENUM.member) && (
                  <div className={Style.message_bubble}>
                    <div className={Style.icon_sender}>
                      {iconAvatarMember(m)}
                    </div>
                    <ChatBubbleContainer
                      key={`message${i.toString()}`}
                      css="
                margin: 0;
                background-color: #fff;
                display: flex;
                align-items: start;
                flex-direction: column;
                "
                    >
                      <ChatBubble
                        senderName={senderNameDetect}
                        variant={variant}
                        css="border-radius: 0px;
                background-color: #e0e2e5;
                color: rgba(0,0,0,.87);
                ">
                        {hasAttachment !== -1 &&
                          <div style={{ display: 'flex' }}>
                            {hasAttachment === 0 &&
                              <MessageAttachment imgOnLoad={onloadimg} renderImg={true} name={metadata.attachment.name} size={resize(metadata.attachment.size)} imgUrl={preSignURL !== undefined ? preSignURL : ""} downloadUrl={`${process.env.REACT_APP_API_KEY_PCS}/api/Document/DownloadFile/` + metadata.attachment.fileKey + `/${metadata.attachment.name}`} />
                            }
                            {hasAttachment === 1 &&
                              <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={false} />
                            }
                          </div>
                        }
                        <div>
                          {m.message}
                          {metadata?.meeting?.meetingId && <a style={{ cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} href={metadata?.meeting.pathRoute + metadata?.meeting.meetingId + `/?user_id=${userChimeId?.userId}` + `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}` + `${"&isWebVer=true"}`} target={"_blank"}>Link</a>}
                        </div>
                      </ChatBubble>
                      <div className={Style.message_time}>{m.datetime}</div>
                    </ChatBubbleContainer>
                  </div>
                )
              }
            </Fragment>
          )}
        </div>
      );
    });
  }



  useEffect(() => {
    if (messagesAssit?.length > 0 || (messagesChanel && messagesChanel?.length > 0)) {
      setTimeout(() => {
        dispatchReduxToolkit(setShowLoadingDot(false));
      }, 2000);
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
      }
    }
  }, [messagesAssit, messagesChanel])


  useEffect(() => {
  }, [memberPresenceList])

  return (
    <div className={Style.sc_message_list} ref={scrollableDivRef}>
      <div className={Style.sc_message_today}>
        <p>{t("Assistant_today_label")}</p>
      </div>
      {
        showLoadingDot ? (
          <LoadingDots></LoadingDots>
        ) : (
          <>
            {messagesAssit?.length > 0 && (
              <InfiniteList
                style={{ overflow: "hidden", backgroundColor: "#fff", margin: "0px" }}
                items={getHistoryMessageCheck()}
                onLoad={() => { }}
                isLoading={isLoading}
              />
            )}
          </>
        )
      }


    </div>
  );
};

export default Messages;
