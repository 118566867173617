import React from "react";
import { Button } from "semantic-ui-react";
import Style from "./button-action.module.css";
import { useTranslation } from "react-i18next";
import { actionTypeENUM, funtionalTypeENUM, stepENUM } from "../../../../../redux/assistantBot";

interface UserInputProps {
    onSubmit: any;
    type: number;
}

const ButtonAction = (props: UserInputProps) => {
    const { t } = useTranslation();
    const handleClickBtn = (action: number) => {
        props.onSubmit({
            type: actionTypeENUM.chooseOption,
            data: action
        })
    };
    return (
        <div className={Style.startFeature}>
            {
                (props.type === stepENUM.chooseAction || props.type === stepENUM.goBackToFirstStep) && (
                    <>
                        <Button type="button" className={Style.startFeature__button} content={t("Visual_Connect_Pro_btn")} onClick={() => handleClickBtn(funtionalTypeENUM.vsc)}/>
                        <Button type="button" className={Style.startFeature__button} content={t("Join_chat_btn")} onClick={() => handleClickBtn(funtionalTypeENUM.chatting)}/>
                    </>
                )
            }

            {
                props.type === stepENUM.typeStart && (
                    <>
                        <Button type="button" className={Style.startFeature__button} content={t("Start_btn_label")} onClick={() => handleClickBtn(funtionalTypeENUM.start)}/>
                        <Button type="button" className={`${Style.startFeature__button} ${Style.gray}`} content={t("Change_info_label")} onClick={() => handleClickBtn(funtionalTypeENUM.change)}/>
                    </>
                )
            }

            {
                props.type === stepENUM.typeEdit && (
                    <>
                        <Button type="button" className={Style.startFeature__button} content={t("Change_name_label")} onClick={() => handleClickBtn(funtionalTypeENUM.changeName)}/>
                        <Button type="button" className={Style.startFeature__button} content={t("Change_code_label")} onClick={() => handleClickBtn(funtionalTypeENUM.changeCode)}/>
                        <Button type="button" className={Style.startFeature__button} content={t("Change_action_label")} onClick={() => handleClickBtn(funtionalTypeENUM.changeAction)}/>
                    </>
                )
            }



        </div>
    );
};

export default ButtonAction;
