import React from 'react';
import Style from './Loading-dots.module.css';

const LoadingDots: React.FC = () => {
  return (
    <div className={Style.loading}>
      <div className={Style.dot_container}>
        <div className={Style.dot}></div>
        <div className={Style.dot}></div>
        <div className={Style.dot}></div>
      </div>
    </div>
  );
};

export default LoadingDots;
