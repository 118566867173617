import React from 'react';
import {
    MicSelection,
} from 'amazon-chime-sdk-component-library-react';

import MicrophoneActivityPreview from './MicrophoneActivityPreview';

const MicrophoneDevices = () => {

    return (
        <div>
            <MicSelection />
            <MicrophoneActivityPreview />
        </div>
    );
};

export default MicrophoneDevices;
