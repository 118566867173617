import axios, { AxiosError } from 'axios'
import { AuthService } from './AuthService'
const qs = require('qs')

let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error: AxiosError | null, token: string) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

const httpPCSPublic = axios.create({
  baseURL: process.env.REACT_APP_API_KEY_PCS,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    })
  },
})

const httpPCS = axios.create({
  baseURL: process.env.REACT_APP_API_KEY_PCS,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    })
  },
})

httpPCS.interceptors.request.use(
  (config) => {
    const authService = AuthService.getInstance()
    return authService.getUser().then((user) => {
      if (user && user.access_token) {
        config.headers.common['Authorization'] = 'Bearer ' + user.access_token
      } else {
        authService.loginAsync({ redirectUrl: window.location.href })
      }
      return config
    })
  },
  (error) => {
    return Promise.reject(error)
  },
)

httpPCS.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const authService = AuthService.getInstance()
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return httpPCS(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise((resolve, reject) => {
        let isLoginAgain = false
        authService
          .renewToken()
          .then((newUser) => {
            originalRequest.headers['Authorization'] =
              'Bearer ' + newUser?.access_token
            processQueue(null, newUser?.access_token as string)
            resolve(httpPCS(originalRequest))
          })
          .catch((err) => {
            processQueue(err, '')
            reject(err)
            isLoginAgain = true
          })
          .finally(() => {
            isRefreshing = false
            if (isLoginAgain) {
              authService.loginAsync({ redirectUrl: window.location.href })
            }
          })
      })
    }
    if (error.response?.status === 403) {
      authService.loginAsync({ redirectUrl: window.location.href })
    }
    return Promise.reject(error)
  },
)

httpPCSPublic.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

httpPCSPublic.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)
export default httpPCS
