import { FC } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import Styles from "./RDDropdown.module.css";
import React from "react";
import Boschicon from "../boschIcon/Boschicon";

interface RDDropdownProps extends DropdownProps {
  title: string;
  errorMessage?: string;
}

const RDDropdown: FC<RDDropdownProps> = (props) => {
  return (
    <>
      <div className={Styles.rd_input_group}>
        <Dropdown
          {...props}
          selectOnBlur={false}
          header={
            props.header && <div className={Styles.header}>{props.header}</div>
          }
          className={`${Styles.dropdown} ${props.className ?? ""}`}
          icon={
            <div className={Styles.rd_icon}>
              <Boschicon name="down" />
            </div>
          }
        />
      </div>
      {!!props.errorMessage && (
        <span className={Styles.error}>
          {props.errorMessage}
        </span>
      )}
    </>
  );
};

export default RDDropdown;
