import { FC, Fragment, useState } from "react";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { ReactComponent as Language } from "../../../../assets/svg/languagePopup.svg";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import React from "react";
import "./css/TranslateButton.css";
import { languageOptions } from "../../../../translations/languageOptions";
import { useAppDispatch } from "../../../../redux/store";
import { SetIsTranslate, selectIsTranslate } from "../../../../redux/chimeSlide";
import { useSelector } from "react-redux";

const TranslateButton: FC<any> = () => {
  const [language, setLanguage] = useState<string>(i18n.language);
  const { t } = useTranslation();
  const isTranslate = useSelector(selectIsTranslate);
  const dispatchReduxToolkit = useAppDispatch();
  const switchLanguage = (event: any, data: any) => {
    i18n.changeLanguage(data.value);
    setLanguage(data.value)
    dispatchReduxToolkit(SetIsTranslate({
      ...isTranslate,
      langTarget: language,
    }));
  };

  return (
    <Fragment>
      <div className="header--icon">
        <Button className="header--icon--language">
          <Dropdown
            floating
            inline
            header={t("Available_Languages_Label")}
            options={languageOptions}
            value={i18n.language}
            onChange={switchLanguage}
            icon={
              <Icon>
                <Language />
                <span className="header--icon--language--key">{i18n.language}</span>
              </Icon>
            }
          ></Dropdown>
        </Button>
      </div>
    </Fragment>
  );
};

export default TranslateButton;
