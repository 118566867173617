import { Fragment, useEffect, FC } from "react";
import boschSupergraphic from "../../assets/Bosch-Supergraphic_.png";
import logo from "../../assets/Bosch_DigitalLogo_BlackAnchor_144x96px.png";
import "./css/Header.css";
import { Image } from "semantic-ui-react";
import { AuthService } from '../../services/AuthService';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import { loginAsync, logoutAsync } from "../../redux/account";
import { authSetCountryCode, AuthState, selectCountryCode, selectIsSignIn } from '../../redux/auth';
import { useTranslation } from "react-i18next";
import React from "react";
import store from '../../redux/store';
import AccountButton from "./AccountButton";
import routes from "../../routes";

const checkSelectedRegion = () => {
  const domain = window.location.origin;
  const newAuthState = {
    domain: domain,
    language: '',
    countryCode: '',
    clientId: '',
    timeZoneId: '',
    languages: null
  } as AuthState;

  AuthService.setAuthState(newAuthState);

  store.dispatch(authSetCountryCode(newAuthState));
}


const Header: FC = () => {
  checkSelectedRegion();
  const isSignIn = useSelector(selectIsSignIn);
  const countryCode = useSelector(selectCountryCode);

  useEffect(() => { }, [isSignIn])
  useEffect(() => { }, [countryCode])

  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const login = () => {
    dispatchReduxToolkit(loginAsync());
  };

  const logout = () => {
    dispatchReduxToolkit(logoutAsync());
  };

  const { t } = useTranslation();
  return (
    <div className="header-private-pcs">
      <div className="supergraphic">
        <Image src={boschSupergraphic} fluid></Image>
      </div>
      <div className="grid-responsive-width page-header-top-private">
        <div className="header-large-box--logo">
          <Image className="logo-image" src={logo}></Image>
          <div className="header-large-box--logo--vertical-line">
          </div>
          <div className="header-large-box--logo--title">
            {t("Logo_Title_Label")}
          </div>
        </div>
        <AccountButton
          className="header-icon-button"
          isMobile={false}
          login={login}
          logout={logout}
        />
      </div>
    </div>
  );
};

export default Header;
