import React, { Fragment } from 'react';
import { Heading } from 'amazon-chime-sdk-component-library-react';
import DeviceSelection from '../../DeviceSelection';
import { useHistory } from 'react-router-dom';

const DeviceSetup: React.FC = () => {

    return (
        <Fragment>
            {/* <Heading tag="h1" level={3} css="align-self: flex-start">
                Device settings
            </Heading> */}
            <DeviceSelection />
        </Fragment>
    )
};

export default DeviceSetup;
