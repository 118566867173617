import React, { SyntheticEvent, useState } from "react";
import Style from "./input-action.module.css";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "semantic-ui-react";
import { actionTypeENUM } from "../../../../../redux/assistantBot";

interface UserInputProps {
    onSubmit: any;
    title: string
    type: string
}

const InputAction = (props: UserInputProps) => {
    const { t } = useTranslation();
    const [inputActive, setInputActive] = useState(false);
    const [userInput, setUserInput] = useState<string>("");
    const userInputChangeHandler = (event: any, data: any) => {
        setUserInput(data.value);
    };

    const onSubmitInput = async (event?: SyntheticEvent) => {
        event?.preventDefault();
        if (userInput && userInput.length > 0) {
            props.onSubmit({
                type: actionTypeENUM.inputText,
                data: userInput
            });
            setUserInput("");
        }
    };
    return (
        <Form
            className={inputActive ? `${Style.sc_user_input} ${Style.active}` : Style.sc_user_input}
            style={{ display: "flex" }}
            onSubmit={onSubmitInput}
        >
            <div className={Style.rd_input_group}>
                <span>{props.title}</span>
                <Input
                    onFocus={() => {
                        setInputActive(true);
                    }}
                    onBlur={() => {
                        setInputActive(false);
                    }}
                    onChange={userInputChangeHandler}
                    value={userInput}
                    className={Style.sc_user_input__text}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                            onSubmitInput(e)
                        }
                    }}
                    type={props.type === "name" ? "text" : "number"}
                />
            </div>
            <Button type="submit" icon="send" className={Style.userInput__button} onClick={onSubmitInput} />
        </Form>
    );
};

export default InputAction;
